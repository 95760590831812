import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { Search, ShoppingCart, Plus, Minus, Trash2, CreditCard, Banknote, X, QrCode, ArrowLeft, DollarSign, Wallet, Check, ArrowRight, Coins, Receipt, ShoppingBag } from 'lucide-react';
import SearchBar from '../SearchBar';

interface Product {
  id: string;
  name: string;
  base_price: number;
  main_image: string;
  stock: number;
  code?: string;
  barcode?: string;
}

interface CartItem {
  product: Product;
  quantity: number;
}

interface CashSession {
  id: string;
  initial_amount: number;
  final_amount?: number;
  status: 'open' | 'closed';
  opened_at: string;
  closed_at?: string;
  user_id: string;
}

type PaymentMethod = 'cash' | 'card' | 'qr';

const QUICK_AMOUNTS = [10, 20, 50, 100];

interface DenominationCount {
  amount: number;
  count: number;
}

interface CashCount {
  bills: {
    [key: number]: number; // amount: count
  };
  coins: {
    [key: number]: number; // amount: count
  };
}

export default function POSTab() {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [cart, setCart] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('cash');
  const [cashReceived, setCashReceived] = useState('');
  const [change, setChange] = useState(0);
  const [currentSession, setCurrentSession] = useState<CashSession | null>(null);
  const [showOpenSessionModal, setShowOpenSessionModal] = useState(false);
  const [showCloseSessionModal, setShowCloseSessionModal] = useState(false);
  const [initialAmount, setInitialAmount] = useState('');
  const [finalAmount, setFinalAmount] = useState('');
  const [loadingSession, setLoadingSession] = useState(true);
  const [cashCount, setCashCount] = useState<CashCount>({
    bills: {
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0
    },
    coins: {
      1: 0,
      0.5: 0,
      0.25: 0
    }
  });
  const [closingCashCount, setClosingCashCount] = useState<CashCount>({
    bills: {
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0
    },
    coins: {
      1: 0,
      0.5: 0,
      0.25: 0
    }
  });
  const [dailySales, setDailySales] = useState(0);

  useEffect(() => {
    checkCurrentSession();
  }, []);

  const checkCurrentSession = async () => {
    try {
      setLoadingSession(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) return;

      const { data: session, error } = await supabase
        .from('cash_sessions')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'open')
        .maybeSingle();

      if (error) throw error;

      if (session) {
        setCurrentSession(session);
        await fetchProducts();
      }
    } catch (err) {
      console.error('Error al verificar la sesión:', err);
      setError('Error al verificar la sesión de caja');
    } finally {
      setLoadingSession(false);
    }
  };

  const openSession = async () => {
    try {
      const amount = calculateTotalFromCount();
      if (isNaN(amount) || amount <= 0) {
        alert('Por favor ingrese un monto válido');
        return;
      }

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: session, error } = await supabase
        .from('cash_sessions')
        .insert({
          initial_amount: amount,
          status: 'open',
          opened_at: new Date().toISOString(),
          user_id: user.id
        })
        .select()
        .single();

      if (error) throw error;

      setCurrentSession(session);
      setShowOpenSessionModal(false);
      setCashCount({
        bills: {
          100: 0,
          50: 0,
          20: 0,
          10: 0,
          5: 0
        },
        coins: {
          1: 0,
          0.5: 0,
          0.25: 0
        }
      });
      await fetchProducts();
    } catch (err) {
      console.error('Error al abrir la sesión:', err);
      setError('Error al abrir la sesión de caja');
    }
  };

  const closeSession = async () => {
    if (!currentSession) return;

    try {
      const amount = calculateTotalFromClosingCount();
      if (isNaN(amount) || amount < 0) {
        alert('Por favor ingrese un monto válido');
        return;
      }

      // Obtener el total de ventas del día
      const { data: orders, error: ordersError } = await supabase
        .from('orders')
        .select('total_amount')
        .eq('source', 'pos')
        .gte('created_at', currentSession.opened_at)
        .lte('created_at', new Date().toISOString());

      if (ordersError) throw ordersError;

      const totalSales = orders?.reduce((sum, order) => sum + order.total_amount, 0) || 0;
      setDailySales(totalSales);

      const { error } = await supabase
        .from('cash_sessions')
        .update({
          status: 'closed',
          closed_at: new Date().toISOString(),
          final_amount: amount
        })
        .eq('id', currentSession.id);

      if (error) throw error;

      setCurrentSession(null);
      setCart([]);
      setProducts([]);
      setFilteredProducts([]);
      setShowCloseSessionModal(false);
      setFinalAmount('');
      setClosingCashCount({
        bills: {
          100: 0,
          50: 0,
          20: 0,
          10: 0,
          5: 0
        },
        coins: {
          1: 0,
          0.5: 0,
          0.25: 0
        }
      });
    } catch (err) {
      console.error('Error al cerrar la sesión:', err);
      setError('Error al cerrar la sesión de caja');
    }
  };

  const fetchDailySales = async () => {
    if (!currentSession) return;
    
    try {
      const { data: orders, error } = await supabase
        .from('orders')
        .select('total_amount')
        .eq('source', 'pos')
        .gte('created_at', currentSession.opened_at)
        .lte('created_at', new Date().toISOString());

      if (error) throw error;

      const totalSales = orders?.reduce((sum, order) => sum + order.total_amount, 0) || 0;
      setDailySales(totalSales);
    } catch (err) {
      console.error('Error al obtener las ventas del día:', err);
    }
  };

  // Llamar a fetchDailySales cuando se abre el modal de cierre
  const handleShowCloseSessionModal = () => {
    fetchDailySales();
    setShowCloseSessionModal(true);
  };

  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [searchTerm, products]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('products')
        .select('id, name, base_price, main_image, stock')
        .eq('status', 'active')
        .order('name');

      if (error) throw error;

      setProducts(data || []);
      setFilteredProducts(data || []);
    } catch (err) {
      console.error('Error al cargar productos:', err);
      setError('Error al cargar los productos');
    } finally {
      setLoading(false);
    }
  };

  const addToCart = (product: Product) => {
    setCart(currentCart => {
      const existingItem = currentCart.find(item => item.product.id === product.id);
      
      if (existingItem) {
        if (existingItem.quantity >= product.stock) {
          alert('No hay suficiente stock disponible');
          return currentCart;
        }
        return currentCart.map(item =>
          item.product.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      return [...currentCart, { product, quantity: 1 }];
    });
  };

  const removeFromCart = (productId: string) => {
    setCart(currentCart => {
      const existingItem = currentCart.find(item => item.product.id === productId);
      
      if (existingItem && existingItem.quantity > 1) {
        return currentCart.map(item =>
          item.product.id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }

      return currentCart.filter(item => item.product.id !== productId);
    });
  };

  const deleteFromCart = (productId: string) => {
    setCart(currentCart => currentCart.filter(item => item.product.id !== productId));
  };

  const getTotal = () => {
    return cart.reduce((total, item) => total + (item.product.base_price * item.quantity), 0);
  };

  const handlePaymentMethodSelect = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
    setCashReceived('');
    setChange(0);
  };

  const handleNumberInput = (value: string) => {
    if (value === 'backspace') {
      setCashReceived(prev => prev.slice(0, -1));
      calculateChange(cashReceived.slice(0, -1));
      return;
    }

    if (value === '.') {
      if (cashReceived.includes('.')) return;
      const newValue = cashReceived + value;
      setCashReceived(newValue);
      return;
    }

    const newValue = cashReceived + value;
    setCashReceived(newValue);
    calculateChange(newValue);
  };

  const handleQuickAmount = (amount: number) => {
    const newValue = amount.toString();
    setCashReceived(newValue);
    calculateChange(newValue);
  };

  const calculateChange = (amount: string) => {
    const received = parseFloat(amount) || 0;
    const total = getTotal();
    setChange(Math.max(0, received - total));
  };

  const confirmPayment = async () => {
    if (selectedPaymentMethod === 'cash') {
      const received = parseFloat(cashReceived);
      const total = getTotal();

      if (received < total) {
        alert('La cantidad recibida es menor que el total');
        return;
      }
    }

    await processPayment(selectedPaymentMethod);
    setShowPaymentScreen(false);
  };

  const processPayment = async (paymentMethod: PaymentMethod) => {
    try {
      setProcessingPayment(true);
      setError(null);

      // Verificar stock antes de procesar
      for (const item of cart) {
        if (item.quantity > item.product.stock) {
          throw new Error(`Stock insuficiente para ${item.product.name}`);
        }
      }

      // Crear el pedido
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No hay usuario autenticado');

      // Preparar los items del pedido
      const orderItems = cart.map(item => ({
        product_id: item.product.id,
        quantity: item.quantity,
        total_price: item.product.base_price * item.quantity
      }));

      // Crear el pedido primero
      const { data: order, error: orderError } = await supabase
        .from('orders')
        .insert({
          total_amount: getTotal(),
          status: 'completed',
          payment_status: 'paid',
          payment_method: paymentMethod,
          delivery_method: 'pickup',
          user_id: user.id,
          source: 'pos',
          created_at: new Date().toISOString()
        })
        .select()
        .single();

      if (orderError) {
        console.error('Error al crear el pedido:', orderError);
        throw new Error('Error al crear el pedido');
      }

      if (!order) {
        throw new Error('No se pudo crear el pedido');
      }

      // Crear los items del pedido
      const { error: itemsError } = await supabase
        .from('order_items')
        .insert(
          orderItems.map(item => ({
            ...item,
            order_id: order.id
          }))
        );

      if (itemsError) {
        console.error('Error al crear los items del pedido:', itemsError);
        // Eliminar el pedido si falla la creación de items
        await supabase.from('orders').delete().eq('id', order.id);
        throw new Error('Error al crear los items del pedido');
      }

      // Actualizar el stock
      for (const item of cart) {
        const { error: stockError } = await supabase
          .from('products')
          .update({ stock: item.product.stock - item.quantity })
          .eq('id', item.product.id);

        if (stockError) {
          console.error('Error al actualizar el stock:', stockError);
          throw new Error(`Error al actualizar el stock de ${item.product.name}`);
        }
      }

      // Limpiar el carrito y recargar productos
      setCart([]);
      await fetchProducts();
      alert('¡Venta completada con éxito!');
    } catch (err) {
      console.error('Error al procesar el pago:', err);
      setError(err instanceof Error ? err.message : 'Error al procesar el pago');
    } finally {
      setProcessingPayment(false);
    }
  };

  const calculateTotalFromCount = () => {
    const billsTotal = Object.entries(cashCount.bills).reduce(
      (total, [amount, count]) => total + (Number(amount) * count), 
      0
    );
    const coinsTotal = Object.entries(cashCount.coins).reduce(
      (total, [amount, count]) => total + (Number(amount) * count), 
      0
    );
    return billsTotal + coinsTotal;
  };

  const handleDenominationChange = (type: 'bills' | 'coins', amount: number, count: string) => {
    // Permitir string vacío o convertir a número
    const numCount = count === '' ? 0 : Number(count);
    
    // Validar que sea un número válido y no negativo
    if (isNaN(numCount) || numCount < 0) return;

    setCashCount(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [amount]: numCount
      }
    }));
    const newTotal = calculateTotalFromCount();
    setFinalAmount(newTotal.toFixed(2));
  };

  const handleClosingDenominationChange = (type: 'bills' | 'coins', amount: number, count: string) => {
    // Si el input está vacío, establecer el valor como 0
    const numCount = count === '' ? 0 : Number(count);
    
    // Validar que sea un número válido y no negativo
    if (isNaN(numCount) || numCount < 0) return;

    // Actualizar el estado con el nuevo conteo
    setClosingCashCount(prev => {
      const newState = {
        ...prev,
        [type]: {
          ...prev[type],
          [amount]: numCount
        }
      };

      // Calcular el nuevo total
      let total = 0;
      Object.entries(newState.bills).forEach(([amt, cnt]) => {
        total += Number(amt) * Number(cnt);
      });
      Object.entries(newState.coins).forEach(([amt, cnt]) => {
        total += Number(amt) * Number(cnt);
      });

      // Actualizar el total final
      setFinalAmount(total === 0 ? '' : total.toFixed(2));

      return newState;
    });
  };

  const calculateTotalFromClosingCount = () => {
    let billsTotal = 0;
    let coinsTotal = 0;

    // Calcular total de billetes
    Object.entries(closingCashCount.bills).forEach(([amount, count]) => {
      billsTotal += Number(amount) * Number(count);
    });

    // Calcular total de monedas
    Object.entries(closingCashCount.coins).forEach(([amount, count]) => {
      coinsTotal += Number(amount) * Number(count);
    });

    return billsTotal + coinsTotal;
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
  };

  if (loadingSession) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!currentSession) {
    return (
      <div className="h-full flex items-center justify-center bg-gray-50 p-6">
        <div className="bg-white rounded-xl shadow-sm p-8 max-w-md w-full">
          <div className="text-center mb-8">
            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <DollarSign className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Punto de Venta
            </h2>
            <p className="text-gray-500">
              Para comenzar a vender, necesitas abrir una sesión de caja
            </p>
          </div>

          <button
            onClick={() => setShowOpenSessionModal(true)}
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg text-lg font-medium hover:bg-blue-700"
          >
            Abrir Sesión de Caja
          </button>
        </div>

        {/* Modal para Abrir Sesión */}
        {showOpenSessionModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-2xl">
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-lg font-semibold">Abrir Sesión de Caja</h3>
                  <button
                    onClick={() => setShowOpenSessionModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-medium mb-4">Billetes</h4>
                    <div className="space-y-3">
                      {[100, 50, 20, 10, 5].map(amount => (
                        <div key={amount} className="flex items-center gap-3">
                          <span className="w-16">Q{amount}</span>
                          <input
                            type="number"
                            min="0"
                            value={cashCount.bills[amount]}
                            onChange={(e) => handleDenominationChange('bills', amount, e.target.value)}
                            className="w-20 px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                          <span className="text-gray-500">
                            = Q{(amount * cashCount.bills[amount]).toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium mb-4">Monedas</h4>
                    <div className="space-y-3">
                      {[1, 0.5, 0.25].map(amount => (
                        <div key={amount} className="flex items-center gap-3">
                          <span className="w-16">Q{amount.toFixed(2)}</span>
                          <input
                            type="number"
                            min="0"
                            value={cashCount.coins[amount]}
                            onChange={(e) => handleDenominationChange('coins', amount, e.target.value)}
                            className="w-20 px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                          <span className="text-gray-500">
                            = Q{(amount * cashCount.coins[amount]).toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-600 mb-1">Total Inicial</div>
                    <div className="text-2xl font-bold">Q{calculateTotalFromCount().toFixed(2)}</div>
                  </div>
                </div>

                <div className="mt-6 flex gap-4">
                  <button
                    onClick={() => setShowOpenSessionModal(false)}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={openSession}
                    disabled={calculateTotalFromCount() <= 0}
                    className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                  >
                    Abrir Sesión
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-full flex">
      {/* Contenido existente del POS */}
      <div className="flex-1 p-6 overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-1">Punto de Venta</h2>
            <p className="text-sm text-gray-500">
              Sesión iniciada el {new Date(currentSession.opened_at).toLocaleString()}
            </p>
          </div>
          <button
            onClick={() => handleShowCloseSessionModal()}
            className="px-4 py-2 text-red-600 border border-red-200 rounded-lg hover:bg-red-50"
          >
            Cerrar Sesión
          </button>
        </div>

        <div className="relative mb-6">
          <SearchBar 
            variant="pos"
            initialValue={searchTerm}
            onSearch={handleSearchChange}
            showSuggestions={false}
            placeholder="Buscar productos..."
            className="w-full"
          />
        </div>

        {loading ? (
          <div className="flex-1 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-red-600 mb-3">{error}</p>
            <button
              onClick={() => {
                setError(null);
                setProcessingPayment(false);
              }}
              className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 text-sm"
            >
              Intentar nuevamente
            </button>
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {filteredProducts.map((product) => (
              <button
                key={product.id}
                onClick={() => addToCart(product)}
                disabled={product.stock === 0}
                className={`bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow text-left ${
                  product.stock === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <img
                  src={product.main_image}
                  alt={product.name}
                  className="w-full aspect-square object-cover rounded-lg mb-3"
                />
                <h3 className="font-medium text-gray-900 truncate">{product.name}</h3>
                <p className="text-sm text-gray-500">Stock: {product.stock}</p>
                <p className="text-lg font-semibold text-blue-600">Q{product.base_price.toFixed(2)}</p>
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Carrito */}
      <div className="w-96 bg-gray-50 border-l flex flex-col">
        <div className="p-6 border-b">
          <div className="flex items-center gap-2 text-lg font-medium text-gray-900">
            <ShoppingCart className="w-5 h-5" />
            <span>Carrito</span>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          {cart.length === 0 ? (
            <p className="text-center text-gray-500">El carrito está vacío</p>
          ) : (
            <div className="space-y-4">
              {cart.map((item) => (
                <div key={item.product.id} className="flex items-start gap-3">
                  <img
                    src={item.product.main_image}
                    alt={item.product.name}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <div className="flex-1">
                    <h4 className="font-medium text-gray-900">{item.product.name}</h4>
                    <p className="text-sm text-gray-500">Q{item.product.base_price.toFixed(2)}</p>
                    <div className="flex items-center gap-2 mt-2">
                      <button
                        onClick={() => removeFromCart(item.product.id)}
                        className="p-1 rounded-full hover:bg-gray-100"
                      >
                        <Minus className="w-4 h-4" />
                      </button>
                      <span className="text-sm font-medium">{item.quantity}</span>
                      <button
                        onClick={() => addToCart(item.product)}
                        className="p-1 rounded-full hover:bg-gray-100"
                        disabled={item.quantity >= item.product.stock}
                      >
                        <Plus className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => deleteFromCart(item.product.id)}
                        className="p-1 rounded-full hover:bg-gray-100 ml-2"
                      >
                        <Trash2 className="w-4 h-4 text-red-500" />
                      </button>
                    </div>
                  </div>
                  <p className="font-medium">
                    Q{(item.product.base_price * item.quantity).toFixed(2)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="p-6 border-t bg-white">
          <div className="flex justify-between items-center mb-6">
            <span className="text-lg font-medium">Total</span>
            <span className="text-2xl font-semibold">Q{getTotal().toFixed(2)}</span>
          </div>

          <button
            onClick={() => setShowPaymentScreen(true)}
            disabled={cart.length === 0 || processingPayment}
            className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 disabled:opacity-50 text-lg font-medium"
          >
            <CreditCard className="w-6 h-6" />
            <span>Proceder al Pago</span>
          </button>
        </div>
      </div>

      {/* Pantalla de Pago */}
      {showPaymentScreen && (
        <div className="fixed inset-0 bg-white z-50">
          <div className="h-full flex flex-col">
            {/* Header */}
            <div className="bg-white border-b p-4 flex items-center justify-between">
              <button
                onClick={() => setShowPaymentScreen(false)}
                className="text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="w-6 h-6" />
              </button>
              <h2 className="text-xl font-semibold">Pago</h2>
              <div className="w-6"></div>
            </div>

            {/* Contenido */}
            <div className="flex-1 p-6 max-w-lg mx-auto w-full">
              {/* Métodos de Pago */}
              <div className="grid grid-cols-3 gap-4 mb-8">
                <button
                  onClick={() => handlePaymentMethodSelect('cash')}
                  className={`p-4 rounded-lg border flex flex-col items-center gap-2 ${
                    selectedPaymentMethod === 'cash' ? 'bg-blue-50 border-blue-500' : 'hover:bg-gray-50'
                  }`}
                >
                  <Banknote className="w-6 h-6" />
                  <span>Efectivo</span>
                </button>
                <button
                  onClick={() => handlePaymentMethodSelect('card')}
                  className={`p-4 rounded-lg border flex flex-col items-center gap-2 ${
                    selectedPaymentMethod === 'card' ? 'bg-blue-50 border-blue-500' : 'hover:bg-gray-50'
                  }`}
                >
                  <CreditCard className="w-6 h-6" />
                  <span>Tarjeta</span>
                </button>
                <button
                  onClick={() => handlePaymentMethodSelect('qr')}
                  className={`p-4 rounded-lg border flex flex-col items-center gap-2 ${
                    selectedPaymentMethod === 'qr' ? 'bg-blue-50 border-blue-500' : 'hover:bg-gray-50'
                  }`}
                >
                  <QrCode className="w-6 h-6" />
                  <span>QR</span>
                </button>
              </div>

              {selectedPaymentMethod === 'cash' && (
                <>
                  {/* Monto y Cambio */}
                  <div className="mb-8">
                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                      <div className="text-sm text-gray-600 mb-1">Total a Pagar</div>
                      <div className="text-2xl font-bold">Q{getTotal().toFixed(2)}</div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="text-sm text-gray-600 mb-1">Cantidad Recibida</div>
                      <div className="text-2xl font-bold">Q{cashReceived || '0.00'}</div>
                    </div>
                    {change > 0 && (
                      <div className="bg-green-50 p-4 rounded-lg mt-4">
                        <div className="text-sm text-green-600 mb-1">Cambio</div>
                        <div className="text-2xl font-bold text-green-600">Q{change.toFixed(2)}</div>
                      </div>
                    )}
                  </div>

                  {/* Cantidades Rápidas */}
                  <div className="grid grid-cols-4 gap-2 mb-6">
                    {QUICK_AMOUNTS.map(amount => (
                      <button
                        key={amount}
                        onClick={() => handleQuickAmount(amount)}
                        className="py-2 px-4 border rounded hover:bg-gray-50"
                      >
                        Q{amount}
                      </button>
                    ))}
                  </div>

                  {/* Teclado Numérico */}
                  <div className="grid grid-cols-3 gap-2">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0].map((num) => (
                      <button
                        key={num}
                        onClick={() => handleNumberInput(num.toString())}
                        className="py-4 text-xl font-medium bg-gray-50 rounded-lg hover:bg-gray-100"
                      >
                        {num}
                      </button>
                    ))}
                    <button
                      onClick={() => handleNumberInput('backspace')}
                      className="py-4 text-xl font-medium bg-gray-50 rounded-lg hover:bg-gray-100"
                    >
                      ←
                    </button>
                  </div>
                </>
              )}

              {selectedPaymentMethod === 'card' && (
                <div className="text-center py-12">
                  <p className="text-gray-600">Por favor, pase la tarjeta por el lector</p>
                </div>
              )}

              {selectedPaymentMethod === 'qr' && (
                <div className="text-center py-12">
                  <p className="text-gray-600">Escanee el código QR para pagar</p>
                </div>
              )}
            </div>

            {/* Footer */}
            <div className="bg-white border-t p-4">
              <div className="max-w-lg mx-auto">
                <button
                  onClick={confirmPayment}
                  disabled={
                    (selectedPaymentMethod === 'cash' && parseFloat(cashReceived) < getTotal()) ||
                    processingPayment
                  }
                  className="w-full bg-blue-600 text-white py-3 rounded-lg text-lg font-medium hover:bg-blue-700 disabled:opacity-50"
                >
                  {processingPayment ? 'Procesando...' : 'Confirmar Pago'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Cerrar Sesión */}
      {showCloseSessionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg w-full max-w-2xl">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-semibold">Cerrar Sesión de Caja</h3>
                <button
                  onClick={() => setShowCloseSessionModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <h4 className="font-medium mb-4">Billetes</h4>
                  <div className="space-y-3">
                    {[100, 50, 20, 10, 5].map(amount => (
                      <div key={amount} className="flex items-center gap-3">
                        <span className="w-16">Q{amount}</span>
                        <input
                          type="number"
                          min="0"
                          value={closingCashCount.bills[amount]}
                          onChange={(e) => handleClosingDenominationChange('bills', amount, e.target.value)}
                          className="w-20 px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <span className="text-gray-500">
                          = Q{(amount * closingCashCount.bills[amount]).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="font-medium mb-4">Monedas</h4>
                  <div className="space-y-3">
                    {[1, 0.5, 0.25].map(amount => (
                      <div key={amount} className="flex items-center gap-3">
                        <span className="w-16">Q{amount.toFixed(2)}</span>
                        <input
                          type="number"
                          min="0"
                          value={closingCashCount.coins[amount]}
                          onChange={(e) => handleClosingDenominationChange('coins', amount, e.target.value)}
                          className="w-20 px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <span className="text-gray-500">
                          = Q{(amount * closingCashCount.coins[amount]).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-6 space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-600 mb-1">Monto Inicial</div>
                      <div className="text-lg font-medium">
                        Q{currentSession?.initial_amount.toFixed(2)}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600 mb-1">Ventas del Día</div>
                      <div className="text-lg font-medium text-green-600">
                        +Q{dailySales.toFixed(2)}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600 mb-1">Total Esperado</div>
                      <div className="text-lg font-medium">
                        Q{(currentSession?.initial_amount + dailySales).toFixed(2)}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600 mb-1">Total Contado</div>
                      <div className="text-lg font-medium">
                        Q{finalAmount}
                      </div>
                    </div>
                  </div>
                </div>

                {parseFloat(finalAmount) > 0 && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-sm text-gray-600 mb-1">Diferencia</div>
                    <div className={`text-lg font-medium ${
                      parseFloat(finalAmount) === (currentSession?.initial_amount + dailySales)
                        ? 'text-gray-900'
                        : parseFloat(finalAmount) > (currentSession?.initial_amount + dailySales)
                        ? 'text-green-600'
                        : 'text-red-600'
                    }`}>
                      Q{(parseFloat(finalAmount) - (currentSession?.initial_amount + dailySales)).toFixed(2)}
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-6 flex gap-4">
                <button
                  onClick={() => setShowCloseSessionModal(false)}
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  onClick={closeSession}
                  disabled={!finalAmount || parseFloat(finalAmount) < 0}
                  className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
                >
                  Cerrar Sesión
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 