import { Building2, Menu, LogIn, LogOut, UserPlus, User, Package, ShoppingBag, Settings, Bell, Search, X, Home, Tag, ShoppingCart, ChevronDown, Heart, LayoutDashboard, Zap } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import type { User as SupabaseUser } from '@supabase/supabase-js';
import ShoppingCartComponent from './ShoppingCart';
import SearchBar from './SearchBar';
import SearchButton from './SearchButton';

interface UserProfile {
  id: string;
  name: string;
  company: string;
  phone: string;
  is_admin: boolean;
  avatar_url?: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  parent_id: string | null;
  order: number;
  subcategories?: Category[];
}

interface Collection {
  id: string;
  name: string;
  description: string | null;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  image: string | null;
  order: number;
  is_active: boolean;
}

interface NavLink {
  path: string;
  label: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState<SupabaseUser | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [showSearch, setShowSearch] = useState(false);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [collections, setCollections] = useState<Collection[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const categoryMenuRef = useRef<HTMLDivElement>(null);
  const categoryButtonRef = useRef<HTMLButtonElement>(null);
  const { signOut } = useAuth();

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserProfile(session.user.id);
      }
      setIsLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserProfile(session.user.id);
      } else {
        setIsAdmin(false);
        setProfile(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    // Cerrar el menú móvil cuando cambia la ruta
    setIsMenuOpen(false);
    setShowSearch(false);
    setShowCategoryMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profileMenuRef.current && 
        !profileMenuRef.current.contains(event.target as Node) &&
        profileButtonRef.current && 
        !profileButtonRef.current.contains(event.target as Node)
      ) {
        setShowProfileMenu(false);
      }

      if (
        categoryMenuRef.current && 
        !categoryMenuRef.current.contains(event.target as Node) &&
        categoryButtonRef.current && 
        !categoryButtonRef.current.contains(event.target as Node)
      ) {
        setShowCategoryMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .order('order', { ascending: true });

      if (error) throw error;

      // Organize categories into hierarchy
      const mainCategories = data.filter(cat => !cat.parent_id);
      const subCategories = data.filter(cat => cat.parent_id);

      const categoriesWithSubs = mainCategories.map(cat => ({
        ...cat,
        subcategories: subCategories.filter(sub => sub.parent_id === cat.id)
      }));

      setCategories(categoriesWithSubs);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchUserProfile = async (userId: string) => {
    try {
      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();

      if (profile === null) {
        setIsAdmin(false);
        return;
      }

      setProfile({...profile, id: userId});
      setIsAdmin(profile.is_admin);
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
    }
  };

  const fetchCollections = async () => {
    try {
      const { data, error } = await supabase
        .from('product_collections')
        .select('*')
        .eq('is_active', true)
        .order('order', { ascending: true });

      if (error) throw error;
      setCollections(data || []);
    } catch (err) {
      console.error('Error fetching collections:', err);
    }
  };

  const handleLogout = async () => {
    setShowProfileMenu(false);
    await signOut();
  };

  const handleSearch = (query: string) => {
    navigate(`/catalog?q=${encodeURIComponent(query)}`);
  };

  const navLinks = [
    { path: '/', label: 'Inicio', icon: <Home className="h-5 w-5" /> },
    { path: '/catalog', label: 'Catálogo', icon: <Package className="h-5 w-5" /> },
    ...collections.map(collection => ({
      path: `/see-more/${collection.id}`,
      label: collection.name,
      icon: collection.type === 'featured' ? <Zap className="h-5 w-5" /> :
            collection.type === 'new' ? <Tag className="h-5 w-5" /> :
            <ShoppingBag className="h-5 w-5" />
    }))
  ];

  const isActive = (path: string) => location.pathname === path;

  // Función para obtener las iniciales del usuario
  const getUserInitials = () => {
    if (profile?.name) {
      const nameParts = profile.name.split(' ');
      if (nameParts.length > 1) {
        return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
      }
      return profile.name.charAt(0).toUpperCase();
    }
    return user?.email?.charAt(0).toUpperCase() || 'U';
  };

  // Función para determinar el color de fondo del avatar basado en el ID de usuario
  const getAvatarBgColor = () => {
    if (!user?.id) return 'bg-blue-100';
    
    const colors = [
      'bg-blue-100 text-blue-600',
      'bg-green-100 text-green-600',
      'bg-purple-100 text-purple-600',
      'bg-amber-100 text-amber-600',
      'bg-rose-100 text-rose-600',
      'bg-teal-100 text-teal-600',
    ];
    
    const index = Math.abs(
      user.id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
    ) % colors.length;
    
    return colors[index];
  };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50">
        <nav className="bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center gap-4">
                <Link to="/" className="flex items-center group">
                  <img 
                    src="/assets/DISOLGUA.png"
                    alt="Disolgua"  
                    className="h-12 w-auto transform group-hover:scale-110 transition-transform"
                  />
                </Link>
              </div>

              {/* Desktop Search Bar */}
              <div className="hidden md:flex items-center flex-1 max-w-xl mx-4">
                <SearchBar 
                  variant="navbar" 
                  onSearch={handleSearch}
                  showSuggestions={true}
                  className="w-full"
                />
              </div>

              {/* Desktop Navigation */}
            

              <div className="hidden md:flex items-center space-x-4">
                {user ? (
                  <div className="flex items-center gap-4">
                    {isLoading ? (
                      <div className="animate-pulse h-6 w-6 bg-gray-200 rounded-full"></div>
                    ) : (
                      <ShoppingCartComponent />
                    )}
                    <div className="relative">
                      <button
                        ref={profileButtonRef}
                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                        className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors focus:outline-none"
                        aria-expanded={showProfileMenu}
                        aria-haspopup="true"
                      >
                        <div className="relative">
                          {profile?.avatar_url ? (
                            <div className="h-9 w-9 rounded-full overflow-hidden border-2 border-white shadow-sm">
                              <img 
                                src={profile.avatar_url} 
                                alt={profile.name || 'Usuario'} 
                                className="h-full w-full object-cover"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).style.display = 'none';
                                  e.currentTarget.parentElement!.classList.add(...getAvatarBgColor().split(' '));
                                  e.currentTarget.parentElement!.innerHTML = `<span class="text-lg font-medium">${getUserInitials()}</span>`;
                                }}
                              />
                            </div>
                          ) : (
                            <div className={`h-9 w-9 rounded-full flex items-center justify-center ${getAvatarBgColor()}`}>
                              <span className="text-lg font-medium">{getUserInitials()}</span>
                            </div>
                          )}
                        </div>
                      </button>
                      {showProfileMenu && (
                        <div 
                          ref={profileMenuRef}
                          className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div className="p-4 border-b border-gray-100">
                            <div className="flex items-center gap-3">
                              {profile?.avatar_url ? (
                                <div className="h-12 w-12 rounded-full overflow-hidden border-2 border-white shadow-sm">
                                  <img 
                                    src={profile.avatar_url} 
                                    alt={profile.name || 'Usuario'} 
                                    className="h-full w-full object-cover"
                                    onError={(e) => {
                                      (e.target as HTMLImageElement).style.display = 'none';
                                      e.currentTarget.parentElement!.classList.add(...getAvatarBgColor().split(' '));
                                      e.currentTarget.parentElement!.innerHTML = `<span class="text-xl font-medium">${getUserInitials()}</span>`;
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className={`h-12 w-12 rounded-full flex items-center justify-center ${getAvatarBgColor()}`}>
                                  <span className="text-xl font-medium">{getUserInitials()}</span>
                                </div>
                              )}
                              <div>
                                <p className="text-sm font-medium text-gray-900">{profile?.name || 'Usuario'}</p>
                                <p className="text-xs text-gray-500 truncate">{user.email}</p>
                                {profile?.company && (
                                  <p className="text-xs text-blue-600 mt-1 flex items-center gap-1">
                                    <Building2 className="h-3 w-3" /> 
                                    {profile.company}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <Link
                            to="/profile"
                            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            <User className="h-4 w-4" />
                            Mi Perfil
                          </Link>
                          <Link
                            to="/profile"
                            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            <Package className="h-4 w-4" />
                            Mis Pedidos
                          </Link>
                          {isAdmin && (
                            <Link
                              to="/admin"
                              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              <Settings className="h-4 w-4" />
                              Panel Admin
                            </Link>
                          )}
                          <button
                            onClick={handleLogout}
                            className="flex items-center gap-2 w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                          >
                            <LogOut className="h-4 w-4" />
                            Cerrar Sesión
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center gap-3">
                    <Link
                      to="/login"
                      className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors"
                    >
                      <LogIn className="h-5 w-5" />
                      <span>Iniciar sesión</span>
                    </Link>
                    <Link
                      to="/register"
                      className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors transform hover:scale-105"
                    >
                      <UserPlus className="h-5 w-5" />
                      <span>Registrarse</span>
                    </Link>
                  </div>
                )}
              </div>

              {/* Mobile menu button */}
              <div className="flex md:hidden items-center">
                <SearchButton 
                  onClick={() => setShowSearch(!showSearch)} 
                  variant="icon-only" 
                  className="mr-2"
                />
                {user && !isLoading && <ShoppingCartComponent />}
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="p-2 rounded-md text-gray-600 hover:text-blue-600 hover:bg-gray-100 transition-colors"
                  aria-expanded={isMenuOpen}
                >
                  <span className="sr-only">Abrir menú</span>
                  <Menu className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Search Bar */}
          {showSearch && (
            <div className="md:hidden border-t border-gray-100 py-3 px-4 bg-gray-50">
              <SearchBar 
                variant="navbar"
                onSearch={(query) => {
                  handleSearch(query);
                  setShowSearch(false);
                }}
                onClose={() => setShowSearch(false)}
                autoFocus={true}
                showSuggestions={true}
              />
            </div>
          )}
        </nav>

        {/* Categories Section - Desktop */}
        <div className="hidden md:block bg-blue-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-12">
              
              <div className="hidden md:flex items-center space-x-1">
              <div className="relative group">
                <button
                  ref={categoryButtonRef}
                  onClick={() => setShowCategoryMenu(!showCategoryMenu)}
                  className={`flex items-center gap-2 px-4 py-2 text-sm font-medium transition-all duration-200 ${
                    showCategoryMenu 
                      ? 'text-blue-600 bg-white rounded-t-md shadow-sm' 
                      : 'text-white hover:bg-blue-500 rounded-md'
                  }`}
                >
                  <LayoutDashboard className="h-5 w-5" />
                  Categorías
                  <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${showCategoryMenu ? 'rotate-180' : ''}`} />
                </button>
                {showCategoryMenu && (
                  <div 
                    ref={categoryMenuRef}
                    className="absolute left-0 top-full w-72 bg-white rounded-b-md rounded-tr-md shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50 transform opacity-100 scale-100 transition-all duration-200"
                  >
                    <div className="py-2">
                      {categories.map((category) => (
                        <div key={category.id} className="group relative">
                          <Link
                            to={`/category/${category.slug}`}
                            className="flex items-center justify-between px-4 py-3 text-sm text-gray-700 hover:bg-blue-50 transition-colors"
                            onClick={() => setShowCategoryMenu(false)}
                          >
                            <div className="flex items-center gap-3">
                              <div className="w-8 h-8 rounded-lg bg-blue-50 p-1.5 flex items-center justify-center">
                                <img 
                                  src={category.image} 
                                  alt={category.name}
                                  className="w-full h-full object-contain"
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src = '/assets/placeholder.png';
                                  }}
                                />
                              </div>
                              <div>
                                <span className="font-medium">{category.name}</span>
                                {category.description && (
                                  <p className="text-xs text-gray-500 mt-0.5 line-clamp-1">{category.description}</p>
                                )}
                              </div>
                            </div>
                            {category.subcategories?.length ? (
                              <ChevronDown className="h-4 w-4 text-gray-400" />
                            ) : null}
                          </Link>
                          {category.subcategories?.length ? (
                            <div className="absolute left-full top-0 hidden w-64 ml-0.5 group-hover:block">
                              <div className="bg-white rounded-md shadow-xl ring-1 ring-black ring-opacity-5 py-2">
                                {category.subcategories.map((sub) => (
                                  <Link
                                    key={sub.id}
                                    to={`/category/${category.slug}/${sub.slug}`}
                                    className="flex items-center gap-3 px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 transition-colors"
                                    onClick={() => setShowCategoryMenu(false)}
                                  >
                                    <div className="w-7 h-7 rounded-lg bg-blue-50 p-1.5 flex items-center justify-center">
                                      <img 
                                        src={sub.image} 
                                        alt={sub.name}
                                        className="w-full h-full object-contain"
                                        onError={(e) => {
                                          (e.target as HTMLImageElement).src = '/assets/placeholder.png';
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span className="font-medium">{sub.name}</span>
                                      {sub.description && (
                                        <p className="text-xs text-gray-500 mt-0.5 line-clamp-1">{sub.description}</p>
                                      )}
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
                {navLinks.map((link) => (
                  <Link 
                    key={link.path}
                    to={link.path} 
                    className={`px-4 py-2 text-sm font-medium transition-colors ${
                      isActive(link.path) 
                        ? 'text-blue-600 bg-white rounded-md shadow-sm' 
                        : 'text-white hover:bg-blue-500 hover:text-white rounded-md'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {link.icon}
                      {link.label}
                    </div>
                  </Link>
                ))}
              </div>
              
             
            </div>
          </div>
        </div>
      </div>
      
      {/* Spacer to compensate for fixed header */}
      <div className="h-28 md:h-28"></div>

      {/* Mobile Navigation Menu */}
      <div 
        className={`md:hidden fixed inset-0 bg-gray-900/50 backdrop-blur-sm transition-opacity duration-200 z-50 ${
          isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMenuOpen(false)}
      >
        <div 
          className={`fixed inset-y-0 left-0 w-72 bg-white shadow-xl transform transition-transform duration-200 ease-out overflow-y-auto ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="font-semibold text-lg">Menú</h2>
            <button 
              onClick={() => setIsMenuOpen(false)}
              className="p-2 rounded-full text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          
          {user ? (
            <div className="p-4 border-b">
              <div className="flex items-center gap-3 mb-2">
                {profile?.avatar_url ? (
                  <div className="h-12 w-12 rounded-full overflow-hidden border-2 border-white shadow-sm">
                    <img 
                      src={profile.avatar_url} 
                      alt={profile.name || 'Usuario'} 
                      className="h-full w-full object-cover"
                      onError={(e) => {
                        (e.target as HTMLImageElement).style.display = 'none';
                        e.currentTarget.parentElement!.classList.add(...getAvatarBgColor().split(' '));
                        e.currentTarget.parentElement!.innerHTML = `<span class="text-xl font-medium">${getUserInitials()}</span>`;
                      }}
                    />
                  </div>
                ) : (
                  <div className={`h-12 w-12 rounded-full flex items-center justify-center ${getAvatarBgColor()}`}>
                    <span className="text-xl font-medium">{getUserInitials()}</span>
                  </div>
                )}
                <div>
                  <p className="font-medium text-gray-900">{profile?.name || 'Usuario'}</p>
                  <p className="text-sm text-gray-500 truncate">{user.email}</p>
                  {profile?.company && (
                    <p className="text-xs text-blue-600 mt-1 flex items-center gap-1">
                      <Building2 className="h-3 w-3" /> 
                      {profile.company}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2 p-4 border-b">
              <Link 
                to="/login"
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <LogIn className="h-5 w-5" />
                <span>Iniciar sesión</span>
              </Link>
              <Link
                to="/register"
                className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <UserPlus className="h-5 w-5" />
                <span>Registrarse</span>
              </Link>
            </div>
          )}
          
          <div className="py-2">
            {navLinks.map((link) => (
              <Link 
                key={link.path}
                to={link.path}
                onClick={() => setIsMenuOpen(false)}
                className={`flex items-center gap-3 px-4 py-3 transition-colors ${
                  isActive(link.path) 
                    ? 'text-blue-600 bg-blue-50 font-medium' 
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                {link.icon}
                {link.label}
              </Link>
            ))}

            <div className="my-2 border-t border-gray-100"></div>

            {/* Categorías en menú móvil */}
            <div className="px-4 py-2">
              <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">Categorías</h3>
              <div className="mt-2 space-y-1">
                {categories.map((category) => (
                  <div key={category.id}>
                    <Link
                      to={`/category/${category.slug}`}
                      className="flex items-center justify-between py-2 text-gray-700 hover:text-blue-600"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <div className="flex items-center gap-2">
                        <img 
                          src={category.image} 
                          alt={category.name}
                          className="w-6 h-6 object-contain rounded"
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = '/assets/placeholder.png';
                          }}
                        />
                        <span className="text-sm font-medium">{category.name}</span>
                      </div>
                      {category.subcategories?.length ? (
                        <ChevronDown className="h-4 w-4" />
                      ) : null}
                    </Link>
                    {category.subcategories?.length ? (
                      <div className="ml-8 space-y-1">
                        {category.subcategories.map((sub) => (
                          <Link
                            key={sub.id}
                            to={`/category/${category.slug}/${sub.slug}`}
                            className="flex items-center gap-2 py-2 text-sm text-gray-600 hover:text-blue-600"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <img 
                              src={sub.image} 
                              alt={sub.name}
                              className="w-5 h-5 object-contain rounded"
                              onError={(e) => {
                                (e.target as HTMLImageElement).src = '/assets/placeholder.png';
                              }}
                            />
                            {sub.name}
                          </Link>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>

            {/* Colecciones en menú móvil */}
            <div className="px-4 py-2">
              <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">Colecciones</h3>
              <div className="mt-2 space-y-1">
                {collections.map((collection) => (
                  <Link
                    key={collection.id}
                    to={`/see-more/${collection.id}`}
                    className="flex items-center gap-2 py-2 text-gray-700 hover:text-blue-600"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center">
                      {collection.type === 'featured' ? (
                        <Zap className="w-4 h-4 text-blue-600" />
                      ) : collection.type === 'new' ? (
                        <Tag className="w-4 h-4 text-blue-600" />
                      ) : (
                        <ShoppingBag className="w-4 h-4 text-blue-600" />
                      )}
                    </div>
                    <span className="text-sm font-medium">{collection.name}</span>
                  </Link>
                ))}
              </div>
            </div>

            <div className="my-2 border-t border-gray-100"></div>
            
            {user && (
              <>
                <Link
                  to="/profile"
                  className="flex items-center gap-3 px-4 py-3 text-gray-700 hover:bg-gray-100 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <User className="h-5 w-5" />
                  Mi perfil
                </Link>
               
                <Link
                  to="/profile"
                  className="flex items-center gap-3 px-4 py-3 text-gray-700 hover:bg-gray-100 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Package className="h-5 w-5" />
                  Mis pedidos
                </Link>
                {isAdmin && (
                  <Link
                    to="/admin"
                    className="flex items-center gap-3 px-4 py-3 text-gray-700 hover:bg-gray-100 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Settings className="h-5 w-5" />
                    Panel de Admin
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="w-full flex items-center gap-3 px-4 py-3 text-red-600 hover:bg-gray-100 transition-colors"
                >
                  <LogOut className="h-5 w-5" />
                  Cerrar sesión
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}