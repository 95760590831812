import { ArrowRight, ShoppingCart, Heart, Eye, Clock, Truck, Package, Tag } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection';
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';
import { supabase } from '../lib/supabase';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// Import required modules
import { Navigation } from 'swiper/modules';
// Import custom styles
import '../styles/featured-products.css';

// Definir la interfaz Product para evitar errores de tipo
interface Product {
  id: string;
  name: string;
  main_image: string;
  price: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

// Definir la interfaz para los productos que vienen de la colección
interface CollectionProduct {
  id: string;
  name: string;
  main_image: string;
  price?: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

export default function FeaturedProducts() {
  const { collection, loading, error } = useCollection('featured');
  const [hoveredProduct, setHoveredProduct] = useState<string | null>(null);
  const { user } = useAuth();
  const { priceType } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  useEffect(() => {
    const fetchProductPrices = async () => {
      if (!user || !priceType || !collection || !collection.products.length) return;

      try {
        const productIds = collection.products.map(p => p.id);
        
        // Obtener los precios específicos para los productos
        const { data: prices, error: pricesError } = await supabase
          .from('product_prices')
          .select('product_id, price_type, min_quantity, price')
          .in('product_id', productIds)
          .eq('price_type', priceType);
        
        if (pricesError) throw pricesError;
        
        // Crear un objeto con los precios específicos por producto
        const pricesMap: Record<string, number> = {};
        
        if (prices && prices.length > 0) {
          // Agrupar los precios por producto_id
          const pricesByProduct: Record<string, any[]> = {};
          
          prices.forEach(price => {
            if (!pricesByProduct[price.product_id]) {
              pricesByProduct[price.product_id] = [];
            }
            pricesByProduct[price.product_id].push(price);
          });
          
          // Para cada producto, obtener el precio con la menor cantidad mínima
          Object.keys(pricesByProduct).forEach(productId => {
            const productPrices = pricesByProduct[productId];
            const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
            pricesMap[productId] = minPriceForType.price;
          });
        }
        
        setProductPrices(pricesMap);
      } catch (err) {
        console.error('Error fetching product prices:', err);
      }
    };

    fetchProductPrices();
  }, [user, priceType, collection]);

  if (loading) {
    return (
      <section>
        <div className="animate-pulse space-y-6">
          <div className="flex justify-between items-center">
            <div className="h-10 bg-gray-200 rounded w-1/3"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="bg-gray-100 rounded-xl overflow-hidden">
                <div className="h-64 bg-gray-200"></div>
                <div className="p-4 space-y-3">
                  <div className="h-5 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  <div className="flex justify-between">
                    <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                    <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section>
        <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg">
          Error: {error}
        </div>
      </section>
    );
  }

  if (!collection || !collection.products?.length) {
    return null;
  }

  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{collection.name}</h2>
          <p className="text-gray-600">{collection.description}</p>
        </div>
      </div>

      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
        }}
        className="featured-products-slider"
      >
        {collection.products.slice(0, 8).map((product: CollectionProduct) => {
          const typedProduct: Product = {
            id: product.id,
            name: product.name,
            main_image: product.main_image,
            price: product.price || 0,
            sales: product.sales,
            stock: product.stock,
            freeShipping: product.freeShipping || (product.price ? product.price > 500 : false),
            minOrder: product.minOrder || 12,
            code: product.code
          };
          
          return (
            <SwiperSlide key={typedProduct.id}>
              <div 
                className="product-card group relative bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100"
                onMouseEnter={() => setHoveredProduct(typedProduct.id)}
                onMouseLeave={() => setHoveredProduct(null)}
              >
                {typedProduct.freeShipping && (
                  <div className="absolute top-3 right-3 z-10 bg-green-500 text-white text-xs font-bold px-2.5 py-1.5 rounded-full flex items-center gap-1">
                    <Truck className="w-3 h-3" />
                    Envío Gratis
                  </div>
                )}
                
                <Link to={`/product/${typedProduct.id}`} className="block relative overflow-hidden">
                  <div className="aspect-[4/3] bg-white">
                    <img
                      src={typedProduct.main_image}
                      alt={typedProduct.name}
                      className="w-full h-full object-contain group-hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                  
                  <div className="absolute inset-0 bg-black/40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2.5 rounded-lg font-medium flex items-center gap-2 transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                      <ShoppingCart className="w-4 h-4" />
                      Añadir al carrito
                    </button>
                  </div>
                </Link>
                
                <div className="product-card-content p-4 space-y-3">
                  <Link to={`/product/${typedProduct.id}`} className="block">
                    <h3 className="text-sm text-gray-900 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors">
                      {typedProduct.name}
                    </h3>
                    {typedProduct.code && (
                      <p className="text-xs text-gray-500 mt-1">
                        Código: {typedProduct.code}
                      </p>
                    )}
                  </Link>
                  
                  
                  
                  {user && priceType ? (
                    <div className="bg-blue-50 p-3 rounded-lg">
                      <div className="flex items-baseline justify-between mb-1">
                        <span className="text-xl font-bold text-blue-600">Q {(productPrices[typedProduct.id] || typedProduct.price).toFixed(2)}</span>
                        <span className="text-sm font-medium text-gray-700">C/U</span>
                      </div>
                      
                    
                    </div>
                  ) : (
                    <div className="bg-gray-50 p-3 rounded-lg text-center">
                      <Link to="/login" className="text-sm text-blue-600 hover:text-blue-800 font-medium">
                        Regístrate o inicia sesión para ver precios
                      </Link>
                    </div>
                  )}
                  
                  <div className="grid grid-cols-2 gap-2 mt-auto">
                    <button className="bg-blue-600 hover:bg-blue-700 text-white py-2.5 rounded-lg font-medium transition-colors flex items-center justify-center gap-2">
                      <ShoppingCart className="w-4 h-4" />
                      Añadir
                    </button>
                    <Link to={`/product/${typedProduct.id}`} className="bg-gray-100 hover:bg-gray-200 text-gray-800 py-2.5 rounded-lg font-medium transition-colors flex items-center justify-center gap-2">
                      Ver detalles
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        
        {collection.products.length > 8 && (
          <SwiperSlide>
            <div className="product-card flex flex-col items-center justify-center h-full bg-gray-50 rounded-xl border-2 border-dashed border-gray-200 hover:border-blue-400 hover:bg-blue-50 transition-all group">
              <Link 
                to="/catalog"
                className="flex flex-col items-center justify-center p-8 h-full w-full"
              >
                <ArrowRight className="w-12 h-12 text-gray-400 group-hover:text-blue-500 mb-4" />
                <span className="text-lg font-medium text-gray-600 group-hover:text-blue-600">Ver catálogo completo</span>
                <span className="text-sm text-gray-500">
                  Explora todos nuestros productos
                </span>
              </Link>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
}