import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

export function usePriceType() {
  const { user } = useAuth();
  const [priceType, setPriceType] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPriceType = async () => {
      try {
        setLoading(true);
        
        if (!user) {
          setPriceType(null);
          return;
        }

        const { data: profile, error } = await supabase
          .from('profiles')
          .select('price_type')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        
        setPriceType(profile?.price_type || null);
      } catch (err) {
        console.error('Error fetching user price type:', err);
        setError(err instanceof Error ? err.message : 'Error al obtener el tipo de precio');
      } finally {
        setLoading(false);
      }
    };

    fetchPriceType();
  }, [user]);

  // Función para obtener el precio correcto según el tipo de precio del usuario
  const getProductPrice = async (productId: string) => {
    if (!user || !priceType) return null;

    try {
      // Obtener los precios del producto
      const { data: prices, error } = await supabase
        .from('product_prices')
        .select('price_type, min_quantity, price')
        .eq('product_id', productId);

      if (error) throw error;

      if (!prices || prices.length === 0) {
        // Si no hay precios específicos, obtener el precio base
        const { data: product, error: productError } = await supabase
          .from('products')
          .select('base_price')
          .eq('id', productId)
          .single();

        if (productError) throw productError;
        
        return product?.base_price || null;
      }

      // Filtrar por el tipo de precio del usuario
      const userPrices = prices.filter(p => p.price_type === priceType);
      
      if (userPrices.length === 0) {
        // Si no hay precios para el tipo de usuario, obtener el precio base
        const { data: product, error: productError } = await supabase
          .from('products')
          .select('base_price')
          .eq('id', productId)
          .single();

        if (productError) throw productError;
        
        return product?.base_price || null;
      }

      // Obtener el precio con la menor cantidad mínima
      const minPriceForType = userPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
      return minPriceForType.price;
    } catch (err) {
      console.error('Error getting product price:', err);
      return null;
    }
  };

  return { priceType, loading, error, getProductPrice };
} 