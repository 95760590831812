import { useState, useEffect, useRef } from 'react';
import {
  Search,
  Plus,
  Edit,
  Trash,
  Filter,
  ArrowUp,
  ArrowDown,
  MoreVertical,
  X,
  Image as ImageIcon,
  FolderTree,
  Upload
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { StorageError } from '@supabase/storage-js';

interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  parent_id: string | null;
  order: number;
  parent?: Category;
  subcategories?: Category[];
  created_at: string;
}

export default function CategoriesTab() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedParent, setSelectedParent] = useState<string>('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [bucketChecked, setBucketChecked] = useState(false);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);

  useEffect(() => {
    fetchCategories();
    checkBucketExists();
    checkSupabaseUrl();
  }, []);

  const checkBucketExists = async () => {
    try {
      console.log('Verificando si el bucket "public" existe...');
      
      // Intentar listar archivos en el bucket para verificar si existe y tenemos acceso
      const { data, error } = await supabase.storage
        .from('public')
        .list('categories', {
          limit: 1,
        });
      
      if (error) {
        console.error('Error al verificar el bucket:', error);
        setError(`Error al verificar el bucket de almacenamiento: ${error.message}`);
        return false;
      }
      
      console.log('Bucket "public" verificado correctamente');
      setBucketChecked(true);
      return true;
    } catch (err) {
      console.error('Error al verificar el bucket:', err);
      setError('No se pudo verificar el bucket de almacenamiento. Verifica la configuración de Supabase.');
      return false;
    }
  };

  const checkSupabaseUrl = () => {
    try {
      // Extraer el dominio de Supabase de la URL pública
      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl('test.txt');
      
      const supabaseUrlDomain = new URL(publicUrl).hostname;
      console.log('Dominio de Supabase Storage:', supabaseUrlDomain);
      
      // Verificar si el dominio está en la CSP
      const cspImgSrc = "'self' data: https://taheqwabfahvzniabmhh.supabase.co";
      if (!cspImgSrc.includes(supabaseUrlDomain)) {
        console.warn(`⚠️ ADVERTENCIA: El dominio de Supabase Storage (${supabaseUrlDomain}) no parece estar incluido en la directiva CSP img-src: ${cspImgSrc}`);
        console.warn('Esto podría causar problemas al cargar imágenes desde Supabase Storage.');
      } else {
        console.log('✅ El dominio de Supabase Storage está incluido en la CSP img-src');
      }
    } catch (error) {
      console.error('Error al verificar la URL de Supabase:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      // Primero obtenemos todas las categorías
      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .order('order', { ascending: true });

      if (error) throw error;

      if (!data) {
        setCategories([]);
        return;
      }

      // Organizamos las categorías en una jerarquía
      const mainCategories = data.filter(cat => !cat.parent_id);
      const subCategories = data.filter(cat => cat.parent_id);

      // Mapeamos las categorías principales con sus subcategorías
      const categoriesWithSubs = mainCategories.map(cat => ({
        ...cat,
        subcategories: subCategories
          .filter(sub => sub.parent_id === cat.id)
          .sort((a, b) => a.order - b.order)
      }));

      setCategories(categoriesWithSubs);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar las categorías');
    } finally {
      setLoading(false);
    }
  };

  const generateSlug = (name: string) => {
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const handleImageUpload = async (file: File) => {
    try {
      setUploadingImage(true);
      setError(null); // Limpiar errores anteriores
      
      // Verificar si el bucket existe si aún no se ha verificado
      if (!bucketChecked) {
        const bucketExists = await checkBucketExists();
        if (!bucketExists) {
          throw new Error('No se pudo verificar el bucket de almacenamiento. Verifica la configuración de Supabase.');
        }
      }
      
      // Validar el tipo de archivo
      if (!file.type.startsWith('image/')) {
        throw new Error('El archivo debe ser una imagen');
      }

      console.log('Iniciando proceso de subida de imagen:', file.name, file.type, file.size);

      // Mostrar una vista previa temporal mientras se sube la imagen
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          // No establecemos previewImage aquí para evitar confusiones
          // Solo mostramos la vista previa temporal en el componente
          console.log('Vista previa temporal generada');
        };
        reader.readAsDataURL(file);
      } catch (previewError) {
        console.error('Error al generar vista previa temporal:', previewError);
        // Continuamos con la subida aunque la vista previa falle
      }

      try {
        // Primero intentamos con la conversión a WebP
        return await uploadWithWebPConversion(file);
      } catch (webpError) {
        console.error('Error al convertir a WebP, intentando subir directamente:', webpError);
        // Si falla la conversión a WebP, intentamos subir directamente
        return await uploadDirectly(file);
      }
    } catch (err) {
      console.error('Error completo al subir la imagen:', err);
      const errorMessage = err instanceof Error ? err.message : 'Error desconocido al subir la imagen';
      setError(errorMessage);
      return null;
    } finally {
      setUploadingImage(false);
    }
  };

  const uploadWithWebPConversion = async (file: File) => {
    try {
      // Convertir a WebP usando FileReader y Canvas
      const img = new Image();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new Error('No se pudo crear el contexto del canvas para procesar la imagen');
      }

      // Usar FileReader para cargar la imagen como data URL en lugar de blob URL
      const dataUrl = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = (e) => {
          console.error('Error al cargar la imagen en el canvas:', e);
          reject(new Error('No se pudo cargar la imagen para procesarla'));
        };
        // Usar data URL en lugar de blob URL
        img.src = dataUrl;
      });

      console.log('Imagen cargada en canvas, dimensiones:', img.width, 'x', img.height);

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Convertir a WebP y obtener como data URL
      const webpDataUrl = canvas.toDataURL('image/webp', 0.8);
      
      // Convertir data URL a Blob para subir a Supabase
      const webpBlob = await fetch(webpDataUrl).then(res => res.blob());

      console.log('Imagen convertida a WebP, tamaño:', webpBlob.size);

      // Generar nombre único para el archivo
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(7)}.webp`;
      const filePath = `categories/${fileName}`;

      console.log('Subiendo imagen WebP a Supabase Storage, ruta:', filePath);

      // Subir a Supabase Storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('public')
        .upload(filePath, webpBlob, {
          contentType: 'image/webp',
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        console.error('Error de Supabase al subir la imagen WebP:', uploadError);
        
        // Mensajes de error más específicos según el código de error
        if (uploadError.message.includes('400')) {
          throw new Error(`Error 400: Solicitud incorrecta - ${uploadError.message}`);
        } else if (uploadError.message.includes('403')) {
          throw new Error('Error 403: No tienes permisos para subir archivos a este bucket');
        } else if (uploadError.message.includes('404')) {
          throw new Error('Error 404: El bucket de almacenamiento "public" no existe');
        } else if (uploadError.message.includes('409')) {
          throw new Error('Error 409: Ya existe un archivo con el mismo nombre');
        } else {
          throw new Error(`Error al subir la imagen: ${uploadError.message}`);
        }
      }

      console.log('Imagen WebP subida correctamente, obteniendo URL pública');

      // Obtener la URL pública
      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl(filePath);

      console.log('URL pública obtenida:', publicUrl);

      setPreviewImage(publicUrl);
      return publicUrl;
    } catch (error) {
      console.error('Error en la conversión a WebP:', error);
      throw error;
    }
  };

  const uploadDirectly = async (file: File) => {
    try {
      // Generar nombre único para el archivo
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(7)}.${fileExt}`;
      const filePath = `categories/${fileName}`;

      console.log('Subiendo imagen directamente a Supabase Storage, ruta:', filePath);

      // Subir a Supabase Storage directamente sin conversión
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('public')
        .upload(filePath, file, {
          contentType: file.type,
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        console.error('Error de Supabase al subir la imagen directamente:', uploadError);
        throw new Error(`Error al subir la imagen directamente: ${uploadError.message}`);
      }

      console.log('Imagen subida correctamente, obteniendo URL pública');

      // Obtener la URL pública
      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl(filePath);

      console.log('URL pública obtenida:', publicUrl);

      setPreviewImage(publicUrl);
      return publicUrl;
    } catch (error) {
      console.error('Error al subir la imagen directamente:', error);
      throw error;
    }
  };

  const handleAddCategory = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    try {
      const categoryData = {
        name: formData.get('name') as string,
        description: formData.get('description') as string,
        image: previewImage,
        parent_id: (formData.get('parent_id') as string) || null,
        order: parseInt(formData.get('order') as string) || 0,
        slug: generateSlug(formData.get('name') as string)
      };

      if (!categoryData.image) {
        throw new Error('Debes subir una imagen para la categoría');
      }

      if (editingCategory) {
        // Actualizar categoría existente
        const { error } = await supabase
          .from('categories')
          .update(categoryData)
          .eq('id', editingCategory.id);

        if (error) throw error;
      } else {
        // Crear nueva categoría
        const { error } = await supabase
          .from('categories')
          .insert([categoryData]);

        if (error) throw error;
      }

      setShowAddModal(false);
      setPreviewImage(null);
      setEditingCategory(null);
      setSelectedParent('');
      fetchCategories();
    } catch (err) {
      console.error('Error adding/updating category:', err);
      setError(err instanceof Error ? err.message : 'Error al añadir/actualizar la categoría');
    }
  };

  const handleEditCategory = (category: Category) => {
    setEditingCategory(category);
    setPreviewImage(category.image);
    setSelectedParent(category.parent_id || '');
    setShowAddModal(true);
  };

  const handleDeleteCategory = async (categoryId: string) => {
    if (!confirm('¿Estás seguro de que quieres eliminar esta categoría?')) return;

    try {
      const { error } = await supabase
        .from('categories')
        .delete()
        .eq('id', categoryId);

      if (error) throw error;
      fetchCategories();
    } catch (err) {
      console.error('Error deleting category:', err);
      setError(err instanceof Error ? err.message : 'Error al eliminar la categoría');
    }
  };

  return (
    <div className="space-y-6">
      {/* Actions */}
      <div className="flex items-center justify-between gap-4">
        <div className="flex-1 max-w-md relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Buscar categorías..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center gap-3">
          <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
            <Filter className="w-5 h-5" />
            Filtros
          </button>
          <button
            onClick={() => {
              setEditingCategory(null);
              setPreviewImage(null);
              setSelectedParent('');
              setShowAddModal(true);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <Plus className="w-5 h-5" />
            Nueva Categoría
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600 mb-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-1 text-sm text-red-700">
                {error}
              </div>
              {error.includes('bucket') && (
                <div className="mt-2 text-sm text-red-700">
                  <p>Posibles soluciones:</p>
                  <ul className="list-disc pl-5 mt-1">
                    <li>Verifica que el bucket 'public' existe en tu proyecto de Supabase</li>
                    <li>Asegúrate de que las políticas de seguridad permiten subir archivos</li>
                    <li>Comprueba que las variables de entorno de Supabase son correctas</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Add/Edit Category Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingCategory ? 'Editar Categoría' : 'Añadir Nueva Categoría'}
              </h2>
              <button
                onClick={() => {
                  setShowAddModal(false);
                  setPreviewImage(null);
                  setEditingCategory(null);
                  setSelectedParent('');
                }}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleAddCategory} className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nombre de la Categoría
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    defaultValue={editingCategory?.name || ''}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Categoría Padre
                  </label>
                  <select
                    name="parent_id"
                    value={selectedParent}
                    onChange={(e) => setSelectedParent(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Ninguna (Categoría principal)</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Imagen
                  </label>
                  <div className="flex flex-col gap-4">
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file);
                      }}
                    />
                    <div 
                      onClick={() => fileInputRef.current?.click()}
                      className={`
                        relative h-40 border-2 border-dashed rounded-lg
                        ${previewImage ? 'border-transparent' : 'border-gray-300'}
                        hover:border-blue-500 transition-colors cursor-pointer
                        flex items-center justify-center
                      `}
                    >
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="Preview"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      ) : (
                        <div className="text-center">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <span className="mt-2 block text-sm font-medium text-gray-600">
                            {uploadingImage ? 'Subiendo...' : 'Haz clic para subir una imagen'}
                          </span>
                          {error && error.includes('imagen') && (
                            <span className="mt-2 block text-xs font-medium text-red-600">
                              Error: {error}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {previewImage && (
                      <button
                        type="button"
                        onClick={() => setPreviewImage(null)}
                        className="text-sm text-red-600 hover:text-red-700"
                      >
                        Eliminar imagen
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Orden
                  </label>
                  <input
                    type="number"
                    name="order"
                    min="0"
                    defaultValue={editingCategory?.order || 0}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Descripción
                  </label>
                  <textarea
                    name="description"
                    required
                    defaultValue={editingCategory?.description || ''}
                    rows={3}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  ></textarea>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddModal(false);
                    setPreviewImage(null);
                    setEditingCategory(null);
                    setSelectedParent('');
                  }}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={uploadingImage || (!previewImage && !editingCategory?.image)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {uploadingImage ? 'Subiendo...' : editingCategory ? 'Guardar Cambios' : 'Añadir Categoría'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Categories List */}
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Categoría
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Descripción
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Orden
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tipo
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Acciones</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {categories.map((category) => (
                  <>
                    <tr key={category.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <img
                            src={category.image}
                            alt={category.name}
                            className="h-10 w-10 rounded-lg object-cover"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src = 'https://via.placeholder.com/100';
                            }}
                          />
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {category.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              /{category.slug}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {category.description}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{category.order}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          Principal
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex items-center justify-end gap-3">
                          <button 
                            onClick={() => handleEditCategory(category)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <Edit className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteCategory(category.id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <Trash className="w-4 h-4" />
                          </button>
                          <button className="text-gray-400 hover:text-gray-600">
                            <MoreVertical className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    {category.subcategories?.map(sub => (
                      <tr key={sub.id} className="hover:bg-gray-50 bg-gray-50/50">
                        <td className="px-6 py-4 whitespace-nowrap pl-16">
                          <div className="flex items-center">
                            <FolderTree className="w-5 h-5 text-gray-400 mr-4" />
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {sub.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                /{category.slug}/{sub.slug}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-900">
                            {sub.description}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{sub.order}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            Subcategoría
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end gap-3">
                            <button
                              onClick={() => handleEditCategory(sub)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Edit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleDeleteCategory(sub.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash className="w-4 h-4" />
                            </button>
                            <button className="text-gray-400 hover:text-gray-600">
                              <MoreVertical className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}