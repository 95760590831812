import { Search } from 'lucide-react';

interface SearchButtonProps {
  onClick: () => void;
  className?: string;
  variant?: 'default' | 'icon-only' | 'text';
  label?: string;
}

export default function SearchButton({
  onClick,
  className = '',
  variant = 'default',
  label = 'Buscar'
}: SearchButtonProps) {
  const getButtonStyles = () => {
    switch (variant) {
      case 'icon-only':
        return 'p-2 rounded-full text-gray-600 hover:text-blue-600 hover:bg-gray-100 transition-colors';
      case 'text':
        return 'px-4 py-2 text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2';
      default:
        return 'px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2';
    }
  };

  return (
    <button
      onClick={onClick}
      className={`${getButtonStyles()} ${className}`}
      aria-label={label}
    >
      <Search className="h-5 w-5" />
      {variant !== 'icon-only' && <span>{label}</span>}
    </button>
  );
} 