import { useState, useEffect, useRef, useCallback, CSSProperties } from 'react';
import { supabase } from '../lib/supabase';

// Estilos en línea
const styles: Record<string, any> = {
  outerContainer: {
    position: 'relative' as const,
    width: '100%',
    maxWidth: '100%',
    marginBottom: '2rem',
    overflow: 'hidden',
    borderRadius: '12px',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.08)',
    pointerEvents: 'none',
    zIndex: 1,
    isolation: 'isolate',
    transform: 'translateZ(0)'
  },
  container: {
    position: 'relative' as const,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    height: '400px', // Altura aumentada para mejor visualización
    backgroundColor: '#FFEB3B',
    borderRadius: '12px',
    pointerEvents: 'none',
    zIndex: 1
  },
  slidesTrack: {
    display: 'flex' as const,
    width: '100%',
    height: '100%',
    transition: 'transform 0.5s ease-in-out',
    pointerEvents: 'auto'
  },
  slide: {
    flex: '0 0 100%',
    position: 'relative' as const,
    height: '100%',
    overflow: 'hidden',
  },
  imageWrapper: {
    position: 'relative' as const,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '12px',
    pointerEvents: 'auto'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
    objectPosition: 'center',
    display: 'block',
    transition: 'transform 0.3s ease',
    pointerEvents: 'auto'
  },
  arrowContainer: {
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    opacity: 0.7,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    pointerEvents: 'auto'
  },
  arrowContainerHover: {
    opacity: 1
  },
  arrowContainerLeft: {
    left: '20px'
  },
  arrowContainerRight: {
    right: '20px'
  },
  arrow: {
    width: '44px',
    height: '44px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    border: 'none',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
    pointerEvents: 'auto'
  },
  arrowHover: {
    backgroundColor: 'white',
    transform: 'scale(1.05)',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)'
  },
  arrowSvg: {
    width: '20px',
    height: '20px',
    color: '#333'
  },
  progressContainer: {
    position: 'absolute' as const,
    bottom: '0',
    left: '0',
    width: '100%',
    height: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    zIndex: 4
  },
  progressBar: (width: string) => ({
    height: '100%',
    backgroundColor: 'white',
    transition: 'width 0.3s linear',
    width,
    borderRadius: '0 2px 2px 0'
  }),
  navigationDots: {
    position: 'absolute' as const,
    bottom: '20px',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    padding: '10px 0',
    zIndex: 4,
    pointerEvents: 'auto'
  },
  navDot: (isActive: boolean) => ({
    width: isActive ? '30px' : '8px',
    height: '8px',
    borderRadius: isActive ? '4px' : '50%',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    border: 'none',
    padding: 0,
    opacity: isActive ? 1 : 0.6,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  }),
  loading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f8f8',
    gap: '15px',
    borderRadius: '12px',
    fontSize: '1rem',
    color: '#666'
  },
  loadingSpinner: {
    width: '40px',
    height: '40px',
    border: '3px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    borderTopColor: '#3498db',
    animation: 'spin 1s linear infinite'
  },
  empty: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f8f8',
    fontSize: '1rem',
    color: '#666',
    borderRadius: '12px'
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
};

// Definir la interfaz para las imágenes del slider
interface SliderImage {
  id: string;
  image: string;
  link: string;
  order: number;
}

export default function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderImages, setSliderImages] = useState<SliderImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState<Record<string, boolean>>({});
  const [isPaused, setIsPaused] = useState(false);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [hoverStates, setHoverStates] = useState({
    leftArrow: false,
    rightArrow: false
  });
  
  const autoplayIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Configuración del slider
  const autoplaySpeed = 5000; // 5 segundos
  const minSwipeDistance = 50; // Distancia mínima para considerar un swipe

  // Añadir al inicio del componente para cargar el estilo una sola vez
  useEffect(() => {
    // Añadir estilos de animación y contención
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      /* Estilo para contener los elementos del slider */
      .slider-container {
        contain: layout style;
      }
      
      /* Estilos para prevenir que las flechas sobrepasen otros elementos */
      .slider-arrow-container {
        z-index: 5 !important;
        isolation: isolate;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Cargar imágenes del slider desde Supabase
  useEffect(() => {
    const fetchSliderImages = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('slider_images')
          .select('*')
          .order('"order"', { ascending: true });

        if (error) throw error;
        
        if (data && data.length > 0) {
          console.log("Imágenes cargadas desde Supabase:", data);
          setSliderImages(data);
        } else {
          console.log("No hay imágenes en Supabase, usando imágenes por defecto");
          // Imágenes por defecto si no hay datos
          const defaultImages = [
            {
              id: '1',
              image: 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&w=1920&h=640&q=80',
              link: '#',
              order: 1
            },
            {
              id: '2',
              image: 'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?auto=format&fit=crop&w=1920&h=640&q=80',
              link: '#',
              order: 2
            },
            {
              id: '3',
              image: 'https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?auto=format&fit=crop&w=1920&h=640&q=80',
              link: '#',
              order: 3
            }
          ];
          setSliderImages(defaultImages);
        }
      } catch (error) {
        console.error('Error al cargar imágenes del slider:', error);
        // Imágenes por defecto en caso de error
        const fallbackImages = [
          {
            id: '1',
            image: 'https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&w=1920&h=640&q=80',
            link: '#',
            order: 1
          },
          {
            id: '2',
            image: 'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?auto=format&fit=crop&w=1920&h=640&q=80',
            link: '#',
            order: 2
          },
          {
            id: '3',
            image: 'https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?auto=format&fit=crop&w=1920&h=640&q=80',
            link: '#',
            order: 3
          }
        ];
        setSliderImages(fallbackImages);
      } finally {
        setLoading(false);
      }
    };

    fetchSliderImages();
  }, []);

  // Inicializar refs para cada slide
  useEffect(() => {
    slideRefs.current = Array(sliderImages.length).fill(null).map((_, i) => slideRefs.current[i] || null);
  }, [sliderImages.length]);

  // Configurar autoplay
  useEffect(() => {
    startAutoplay();
    
    return () => {
      if (autoplayIntervalRef.current) {
        clearInterval(autoplayIntervalRef.current);
      }
    };
  }, [sliderImages, isPaused]);

  const startAutoplay = useCallback(() => {
    if (autoplayIntervalRef.current) {
      clearInterval(autoplayIntervalRef.current);
    }
    
    if (!isPaused && sliderImages.length > 1) {
      autoplayIntervalRef.current = setInterval(() => {
        nextSlide();
      }, autoplaySpeed);
    }
  }, [isPaused, sliderImages.length]);

  // Función para avanzar a la siguiente diapositiva
  const nextSlide = useCallback(() => {
    setCurrentSlide(prevSlide => 
      prevSlide === sliderImages.length - 1 ? 0 : prevSlide + 1
    );
  }, [sliderImages.length]);

  // Función para retroceder a la diapositiva anterior
  const prevSlide = useCallback(() => {
    setCurrentSlide(prevSlide => 
      prevSlide === 0 ? sliderImages.length - 1 : prevSlide - 1
    );
  }, [sliderImages.length]);

  // Función para ir a una diapositiva específica
  const goToSlide = useCallback((index: number) => {
    setCurrentSlide(index);
  }, []);

  // Manejar la carga de imágenes
  const handleImageLoad = (id: string) => {
    setImagesLoaded(prev => ({
      ...prev,
      [id]: true
    }));
  };

  // Manejadores de eventos táctiles para swipe
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      nextSlide();
    } else if (isRightSwipe) {
      prevSlide();
    }
    
    // Reiniciar valores
    setTouchStart(null);
    setTouchEnd(null);
  };

  // Pausar autoplay al pasar el mouse por encima
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  // Manejadores de hover para los elementos
  const handleHover = (element: 'leftArrow' | 'rightArrow', isHovering: boolean) => {
    setHoverStates(prev => ({
      ...prev,
      [element]: isHovering
    }));
  };

  // Manejar clics en los botones de navegación
  const handleArrowClick = (e: React.MouseEvent, direction: 'prev' | 'next') => {
    e.stopPropagation(); // Detener la propagación del evento
    e.preventDefault();
    if (direction === 'prev') {
      prevSlide();
    } else {
      nextSlide();
    }
  };

  // Agregamos un evento para detener propagación en navegación por puntos
  const handleDotClick = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    goToSlide(index);
  };

  // Verificar si hay imágenes para mostrar
  if (loading) {
    return (
      <div style={styles.outerContainer as CSSProperties}>
        <div style={styles.container as CSSProperties}>
          <div style={styles.loading as CSSProperties}>
            <div style={{...styles.loadingSpinner, animation: 'spin 1s linear infinite'} as CSSProperties}></div>
            <p>Cargando slider...</p>
          </div>
        </div>
      </div>
    );
  }

  if (sliderImages.length === 0) {
    return (
      <div style={styles.outerContainer as CSSProperties}>
        <div style={styles.container as CSSProperties}>
          <div style={styles.empty as CSSProperties}>
            <p>No hay imágenes disponibles para mostrar</p>
          </div>
        </div>
      </div>
    );
  }

  // Calcular el progreso del autoplay
  const progressPercentage = (currentSlide / (sliderImages.length - 1)) * 100;

  // Estilos responsivos
  const getResponsiveStyles = () => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      if (width <= 480) {
        return {
          container: { height: '250px' },
          arrowContainer: { width: '40px', height: '40px' },
          arrowContainerLeft: { left: '10px' },
          arrowContainerRight: { right: '10px' },
          arrow: { width: '36px', height: '36px' },
          arrowSvg: { width: '16px', height: '16px' }
        };
      } else if (width <= 768) {
        return {
          container: { height: '320px' },
          arrowContainer: { width: '45px', height: '45px' },
          arrowContainerLeft: { left: '15px' },
          arrowContainerRight: { right: '15px' },
          arrow: { width: '40px', height: '40px' },
          arrowSvg: { width: '18px', height: '18px' }
        };
      }
    }
    return {};
  };

  const responsiveStyles = getResponsiveStyles();

  return (
    <div style={styles.outerContainer as CSSProperties} className="slider-container">
      <div 
        style={{...styles.container, ...responsiveStyles.container} as CSSProperties}
        ref={sliderRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="slider-container"
      >
        <div 
          style={{
            ...styles.slidesTrack,
            transform: `translateX(-${currentSlide * 100}%)`
          } as CSSProperties}
        >
          {sliderImages.map((slide, index) => (
            <div 
              key={slide.id}
              ref={el => slideRefs.current[index] = el}
              style={styles.slide as CSSProperties}
            >
              <div style={styles.imageWrapper as CSSProperties}>
                <img
                  src={slide.image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    ...styles.image,
                    cursor: 'pointer'
                  } as CSSProperties}
                  loading={index === 0 ? "eager" : "lazy"}
                  onLoad={() => handleImageLoad(slide.id)}
                  onClick={() => window.open(slide.link, '_blank')}
                  onError={(e) => {
                    console.error(`Error cargando imagen ${index}:`, slide.image);
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = 'https://via.placeholder.com/1920x640?text=Imagen+no+disponible';
                    handleImageLoad(slide.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        
        {/* Barra de progreso */}
        <div style={styles.progressContainer as CSSProperties}>
          <div 
            style={styles.progressBar(`${progressPercentage}%`) as CSSProperties}
          ></div>
        </div>
        
        {/* Botones de navegación */}
        <div 
          className="slider-arrow-container"
          style={{
            ...styles.arrowContainer,
            ...styles.arrowContainerLeft,
            ...(hoverStates.leftArrow ? styles.arrowContainerHover : {}),
            ...(responsiveStyles.arrowContainer || {}),
            ...(responsiveStyles.arrowContainerLeft || {})
          } as CSSProperties}
        >
          <button 
            style={{
              ...styles.arrow,
              ...(hoverStates.leftArrow ? styles.arrowHover : {}),
              ...(responsiveStyles.arrow || {})
            } as CSSProperties}
            onClick={(e) => handleArrowClick(e, 'prev')}
            onMouseEnter={() => handleHover('leftArrow', true)}
            onMouseLeave={() => handleHover('leftArrow', false)}
            aria-label="Anterior"
          >
            <svg 
              style={{...styles.arrowSvg, ...(responsiveStyles.arrowSvg || {})} as CSSProperties}
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
        </div>
        
        <div 
          className="slider-arrow-container"
          style={{
            ...styles.arrowContainer,
            ...styles.arrowContainerRight,
            ...(hoverStates.rightArrow ? styles.arrowContainerHover : {}),
            ...(responsiveStyles.arrowContainer || {}),
            ...(responsiveStyles.arrowContainerRight || {})
          } as CSSProperties}
        >
          <button 
            style={{
              ...styles.arrow,
              ...(hoverStates.rightArrow ? styles.arrowHover : {}),
              ...(responsiveStyles.arrow || {})
            } as CSSProperties}
            onClick={(e) => handleArrowClick(e, 'next')}
            onMouseEnter={() => handleHover('rightArrow', true)}
            onMouseLeave={() => handleHover('rightArrow', false)}
            aria-label="Siguiente"
          >
            <svg 
              style={{...styles.arrowSvg, ...(responsiveStyles.arrowSvg || {})} as CSSProperties}
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
        
        {/* Indicadores de diapositiva */}
        <div style={styles.navigationDots as CSSProperties}>
          {sliderImages.map((_, index) => (
            <button
              key={index}
              style={styles.navDot(index === currentSlide) as CSSProperties}
              onClick={(e) => handleDotClick(e, index)}
              aria-label={`Ir a diapositiva ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}