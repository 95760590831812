import { useState, useEffect, useRef, memo } from 'react';
import { 
  Package, 
  Truck, 
  Clock, 
  CheckCircle, 
  XCircle, 
  AlertCircle, 
  Search,
  Filter,
  ChevronDown,
  Eye
} from 'lucide-react';
import { supabase, withRetry, withConnectionCheck } from '../../lib/supabase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

interface OrderItem {
  product_id: string;
  quantity: number;
  price: number;
  product: {
    name: string;
    main_image: string;
    stock?: number;
  };
  dispatch_quantity?: number;
}

interface Order {
  id: string;
  created_at: string;
  total_amount: number;
  status: 'pending' | 'confirmed' | 'pending_payment' | 'paid' | 'processing' | 'shipped' | 'delivered' | 'cancelled';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  payment_method: 'card' | 'cash' | 'twoCards' | 'bankTransfer';
  delivery_method: 'delivery' | 'pickup';
  shipping_address?: {
    first_name: string;
    last_name: string;
    company: string;
    nit: string;
    nit_type: 'NIT' | 'CF';
    address: string;
    address_details?: string;
    city: string;
    region: string;
    postal_code?: string;
    phone: string;
    email: string;
  };
  transfer_receipt_url?: string;
  user: {
    email: string;
    profile: {
      name: string;
      company: string;
      phone: string;
    };
  };
  items: OrderItem[];
}

interface UserProfile {
  id: string;
  name: string;
  company: string;
  phone: string;
  email: string;
}

interface SupabaseProduct {
  name: string;
  main_image: string;
  stock?: number;
}

interface SupabaseOrderItemWithProduct {
  id?: string;
  order_id?: string;
  product_id: string;
  quantity: number;
  price: number;
  product: SupabaseProduct | SupabaseProduct[];
}

interface OrderItemWithStock {
  product_id: string;
  quantity: number;
  price: number;
  product: SupabaseProduct;
  available_stock?: number;
  is_available?: boolean;
}

interface SupabaseOrder {
  id: string;
  created_at: string;
  total_amount: number;
  status: Order['status'];
  payment_status: Order['payment_status'];
  payment_method: Order['payment_method'];
  delivery_method: Order['delivery_method'];
  shipping_address?: Order['shipping_address'];
  transfer_receipt_url?: string;
  user_id: string;
  items: SupabaseOrderItemWithProduct[];
}

interface FiltersSectionProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  statusFilter: Order['status'] | 'all';
  setStatusFilter: (value: Order['status'] | 'all') => void;
  paymentStatusFilter: Order['payment_status'] | 'all';
  setPaymentStatusFilter: (value: Order['payment_status'] | 'all') => void;
  statusFilterRef: React.RefObject<HTMLDivElement>;
  paymentFilterRef: React.RefObject<HTMLDivElement>;
  showStatusFilter: boolean;
  setShowStatusFilter: (value: boolean) => void;
  showPaymentFilter: boolean;
  setShowPaymentFilter: (value: boolean) => void;
}

interface OrderCardProps {
  order: Order;
}

const statusColors = {
  pending: 'bg-yellow-100 text-yellow-800',
  confirmed: 'bg-blue-100 text-blue-800',
  pending_payment: 'bg-orange-100 text-orange-800',
  paid: 'bg-green-100 text-green-800',
  processing: 'bg-blue-100 text-blue-800',
  shipped: 'bg-purple-100 text-purple-800',
  delivered: 'bg-green-100 text-green-800',
  cancelled: 'bg-red-100 text-red-800'
};

const paymentStatusColors = {
  pending: 'bg-yellow-100 text-yellow-800',
  paid: 'bg-green-100 text-green-800',
  failed: 'bg-red-100 text-red-800',
  refunded: 'bg-gray-100 text-gray-800'
};

const statusLabels = {
  pending: 'Pendiente Revisión',
  confirmed: 'Confirmado',
  pending_payment: 'Pendiente Pago',
  paid: 'Pagado',
  processing: 'En proceso',
  shipped: 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado'
};

const paymentStatusLabels = {
  pending: 'Pendiente',
  paid: 'Pagado',
  failed: 'Fallido',
  refunded: 'Reembolsado'
};

const KANBAN_COLUMNS = [
  {
    id: 'pending',
    label: 'Por Revisar',
    icon: Clock
  },
  {
    id: 'confirmed',
    label: 'Confirmado',
    icon: CheckCircle
  },
  {
    id: 'pending_payment',
    label: 'Pendiente Pago',
    icon: AlertCircle
  },
  {
    id: 'processing',
    label: 'En Proceso',
    icon: Package
  },
  {
    id: 'shipped',
    label: 'Enviado',
    icon: Truck
  },
  {
    id: 'delivered',
    label: 'Entregado',
    icon: CheckCircle
  },
  {
    id: 'cancelled',
    label: 'Cancelado',
    icon: XCircle
  }
] as const;

const FiltersSection = memo(({ 
  searchTerm, 
  setSearchTerm, 
  statusFilter, 
  setStatusFilter, 
  paymentStatusFilter, 
  setPaymentStatusFilter,
  statusFilterRef,
  paymentFilterRef,
  showStatusFilter,
  setShowStatusFilter,
  showPaymentFilter,
  setShowPaymentFilter
}: FiltersSectionProps) => (
  <div className="flex flex-col sm:flex-row gap-4 mb-6">
    <div className="relative flex-1">
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
      <input
        type="text"
        placeholder="Buscar por ID, cliente o empresa..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
      />
    </div>
    <div className="flex gap-2">
      <div className="relative" ref={statusFilterRef}>
        <button
          onClick={() => setShowStatusFilter(!showStatusFilter)}
          className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
        >
          <Filter className="w-4 h-4" />
          <span>Estado</span>
          <ChevronDown className="w-4 h-4" />
        </button>
        {showStatusFilter && (
          <div className="absolute z-10 mt-2 w-48 bg-white border rounded-lg shadow-lg p-2">
            {[['all', 'Todos'], ...Object.entries(statusLabels)].map(([value, label]) => (
              <button
                key={value}
                onClick={() => {
                  setStatusFilter(value as Order['status'] | 'all');
                  setShowStatusFilter(false);
                }}
                className={`w-full text-left px-3 py-2 hover:bg-gray-100 rounded ${
                  statusFilter === value ? 'bg-gray-100 font-medium' : ''
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="relative" ref={paymentFilterRef}>
        <button
          onClick={() => setShowPaymentFilter(!showPaymentFilter)}
          className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
        >
          <Filter className="w-4 h-4" />
          <span>Pago</span>
          <ChevronDown className="w-4 h-4" />
        </button>
        {showPaymentFilter && (
          <div className="absolute z-10 mt-2 w-48 bg-white border rounded-lg shadow-lg p-2">
            {[['all', 'Todos'], ...Object.entries(paymentStatusLabels)].map(([value, label]) => (
              <button
                key={value}
                onClick={() => {
                  setPaymentStatusFilter(value as Order['payment_status'] | 'all');
                  setShowPaymentFilter(false);
                }}
                className={`w-full text-left px-3 py-2 hover:bg-gray-100 rounded ${
                  paymentStatusFilter === value ? 'bg-gray-100 font-medium' : ''
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
));

const OrderCard = memo(({ order }: OrderCardProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <div className="flex items-center justify-between mb-2">
        <div>
          <h3 className="font-medium text-gray-900">
            Pedido #{order.id.slice(0, 8)}
          </h3>
          <p className="text-sm text-gray-500">
            {format(new Date(order.created_at), "d 'de' MMMM, yyyy - HH:mm", { locale: es })}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${paymentStatusColors[order.payment_status]}`}>
            {paymentStatusLabels[order.payment_status]}
          </span>
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColors[order.status]}`}>
            {statusLabels[order.status]}
          </span>
        </div>
      </div>
      <div className="mb-4">
        <p className="text-sm font-medium text-gray-900">{order.user.profile.name}</p>
        {order.user.profile.company && (
          <p className="text-sm text-gray-500">{order.user.profile.company}</p>
        )}
      </div>
      <div className="flex items-center justify-between">
        <p className="font-medium text-gray-900">
          Q{order.total_amount.toFixed(2)}
        </p>
        <button
          onClick={() => navigate(`/admin/order/${order.id}`)}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-800 transition-colors"
        >
          <Eye className="w-4 h-4" />
          Ver detalles
        </button>
      </div>
    </div>
  );
});

const addStockInfoToItems = async (items: SupabaseOrderItemWithProduct[]): Promise<OrderItem[]> => {
  try {
    const itemsWithStock = items.map(item => {
      try {
        // Verificar si el producto existe
        const product = Array.isArray(item.product) ? item.product[0] : item.product;
        
        if (!product) {
          console.warn(`Producto no encontrado para el item ${item.product_id}`);
          return {
            product_id: item.product_id,
            quantity: item.quantity,
            price: item.price,
            product: {
              name: 'Producto no disponible',
              main_image: '',
              stock: 0
            },
            available_stock: 0,
            is_available: false
          };
        }
        
        const stock = product.stock || 0;
        
        return {
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: product.name || 'Sin nombre',
            main_image: product.main_image || '',
            stock: stock
          },
          available_stock: stock,
          is_available: stock >= item.quantity
        };
      } catch (err) {
        console.error('Error al procesar item para stock:', item, err);
        return {
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: 'Error al cargar producto',
            main_image: '',
            stock: 0
          },
          available_stock: 0,
          is_available: false
        };
      }
    });

    return itemsWithStock;
  } catch (err) {
    console.error('Error al obtener stock de productos:', err);
    throw err;
  }
};

export default function OrdersTab() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<Order['status'] | 'all'>('all');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<Order['payment_status'] | 'all'>('all');
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showPaymentFilter, setShowPaymentFilter] = useState(false);
  const statusFilterRef = useRef<HTMLDivElement>(null);
  const paymentFilterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (statusFilterRef.current && !statusFilterRef.current.contains(event.target as Node)) {
        setShowStatusFilter(false);
      }
      if (paymentFilterRef.current && !paymentFilterRef.current.contains(event.target as Node)) {
        setShowPaymentFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select(`
          *,
          items:order_items (
            *,
            product:products (*)
          )
        `)
        .order('created_at', { ascending: false });

      if (ordersError) {
        console.error('Error al obtener pedidos:', ordersError);
        throw ordersError;
      }

      // Obtener todos los perfiles de usuario de una vez
      const userIds = ordersData.map((order: SupabaseOrder) => order.user_id);
      const { data: profilesData, error: profilesError } = await supabase
        .from('profiles')
        .select('*')
        .in('id', userIds);

      if (profilesError) {
        console.error('Error al obtener perfiles:', profilesError);
        throw profilesError;
      }

      // Crear un mapa de perfiles por ID para acceso rápido
      const profilesMap = new Map(profilesData.map((profile: UserProfile) => [profile.id, profile]));

      // Transformar los datos
      const transformedOrders: Order[] = ordersData.map((order: SupabaseOrder) => {
        const userProfile = profilesMap.get(order.user_id);
        let shippingAddress = order.shipping_address;
        
        // Si shipping_address es un string, intentar parsearlo como JSON
        if (typeof order.shipping_address === 'string') {
          try {
            shippingAddress = JSON.parse(order.shipping_address);
          } catch (err) {
            console.error('Error al parsear shipping_address:', err);
            shippingAddress = undefined;
          }
        }

        return {
          id: order.id,
          created_at: order.created_at,
          total_amount: order.total_amount,
          status: order.status,
          payment_status: order.payment_status,
          payment_method: order.payment_method || 'card',
          delivery_method: order.delivery_method || 'delivery',
          shipping_address: shippingAddress,
          transfer_receipt_url: order.transfer_receipt_url,
          user: {
            email: userProfile?.email || 'No disponible',
            profile: {
              name: userProfile?.name || 'No disponible',
              company: userProfile?.company || '',
              phone: userProfile?.phone || ''
            }
          },
          items: order.items.map((item: any) => ({
            product_id: item.product_id,
            quantity: item.quantity,
            price: item.price,
            product: {
              name: item.product?.name || 'Producto no disponible',
              main_image: item.product?.main_image || '',
              stock: item.product?.stock || 0
            }
          }))
        };
      });

      setOrders(transformedOrders);
    } catch (err) {
      console.error('Error al cargar pedidos:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar pedidos');
    } finally {
      setLoading(false);
    }
  };

  const filteredOrders = orders.filter(order => {
    const matchesSearch = 
      order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.user.profile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (order.user.profile.company && order.user.profile.company.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesStatus = statusFilter === 'all' || order.status === statusFilter;
    const matchesPaymentStatus = paymentStatusFilter === 'all' || order.payment_status === paymentStatusFilter;

    return matchesSearch && matchesStatus && matchesPaymentStatus;
  });

  const ordersByStatus = KANBAN_COLUMNS.reduce((acc, column) => {
    acc[column.id] = filteredOrders.filter(order => order.status === column.id);
    return acc;
  }, {} as Record<Order['status'], Order[]>);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
        <p className="text-red-700 font-medium">Error</p>
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6">
      <FiltersSection
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        paymentStatusFilter={paymentStatusFilter}
        setPaymentStatusFilter={setPaymentStatusFilter}
        statusFilterRef={statusFilterRef}
        paymentFilterRef={paymentFilterRef}
        showStatusFilter={showStatusFilter}
        setShowStatusFilter={setShowStatusFilter}
        showPaymentFilter={showPaymentFilter}
        setShowPaymentFilter={setShowPaymentFilter}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {KANBAN_COLUMNS.map(column => (
          <div key={column.id} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-4">
              <column.icon className="w-5 h-5 text-gray-500" />
              <h2 className="font-medium text-gray-900">{column.label}</h2>
              <span className="text-sm text-gray-500">({ordersByStatus[column.id]?.length || 0})</span>
            </div>
            <div className="space-y-4">
              {ordersByStatus[column.id]?.map(order => (
                <OrderCard
                  key={order.id}
                  order={order}
                />
              ))}
              {ordersByStatus[column.id]?.length === 0 && (
                <p className="text-sm text-gray-500 text-center py-4">
                  No hay pedidos en este estado
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}