import { useState, useEffect, useRef } from 'react';
import {
  Search,
  Plus,
  Edit,
  Trash,
  Upload,
  X,
  Tag,
  Layers,
  Grid,
  Star,
  Percent,
  Check,
  AlertCircle,
  Loader2
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

// Usar interfaces simplificadas con any para evitar problemas de tipo
interface Category {
  id: string;
  name: string;
}

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  stock: number;
  category?: Category;
}

interface Collection {
  id: string;
  name: string;
  description: string;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  image: string | null;
  order: number;
  is_active: boolean;
  category_ids: string[] | null;
  products?: Product[];
  created_at: string;
}

export default function CollectionsTab() {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [collectionType, setCollectionType] = useState<Collection['type']>('category_mix');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingCollection, setEditingCollection] = useState<Collection | null>(null);
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchCollections();
    fetchCategories();
    fetchProducts();
  }, []);

  // Función simplificada para depurar
  useEffect(() => {
    if (products.length > 0) {
      console.log('Productos cargados:', products.length);
    }
    if (categories.length > 0) {
      console.log('Categorías cargadas:', categories.length);
    }
    // Evitar logs excesivos que puedan causar problemas de rendimiento
  }, [products.length, categories.length]); // Solo ejecutar cuando cambie la cantidad de productos o categorías

  const fetchCollections = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('product_collections')
        .select(`
          id,
          name,
          description,
          type,
          image,
          order,
          is_active,
          category_ids,
          created_at,
          products:collection_products(
            product:products(
              id,
              name,
              description,
              base_price,
              main_image,
              stock,
              category:categories(id, name)
            )
          )
        `)
        .order('order', { ascending: true });

      if (error) throw error;

      // Usar any para evitar problemas de tipo
      const transformedCollections: Collection[] = [];
      
      if (data) {
        for (const collection of data) {
          const transformedProducts: Product[] = [];
          
          if (collection.products) {
            for (const item of collection.products) {
              if (item.product) {
                // Usar any para evitar errores de tipo
                const p: any = item.product;
                

                let cat: Category | undefined = undefined;
                if (p.category && p.category.length > 0) {
                  cat = {
                    id: p.category[0].id,
                    name: p.category[0].name
                  };
                }
                
                transformedProducts.push({
                  id: p.id,
                  name: p.name,
                  description: p.description,
                  base_price: p.base_price,
                  main_image: p.main_image,
                  stock: p.stock,
                  category: cat
                });
              }
            }
          }
          
          transformedCollections.push({
            id: collection.id,
            name: collection.name,
            description: collection.description,
            type: collection.type as Collection['type'],
            image: collection.image,
            order: collection.order,
            is_active: collection.is_active,
            category_ids: collection.category_ids,
            products: transformedProducts,
            created_at: collection.created_at
          });
        }
      }

      setCollections(transformedCollections);
    } catch (err) {
      console.error('Error fetching collections:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar las colecciones');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('id, name')
        .is('parent_id', null)
        .order('name');

      if (error) throw error;
      setCategories(data || []);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchProducts = async () => {
    try {
      // Primero, obtener todas las categorías para tener una referencia
      const { data: categoriesData, error: categoriesError } = await supabase
        .from('categories')
        .select('id, name')
        .is('parent_id', null);
      
      if (categoriesError) throw categoriesError;
      
      // Crear un mapa de categorías para búsqueda rápida
      const categoryMap: Record<string, Category> = {};
      categoriesData.forEach(cat => {
        categoryMap[cat.id] = { id: cat.id, name: cat.name };
      });
      
      // Ahora obtener los productos con su categoría
      const { data, error } = await supabase
        .from('products')
        .select(`
          id,
          name,
          description,
          base_price,
          main_image,
          stock,
          category_id
        `)
        .eq('status', 'active')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Transformar los datos para evitar problemas de tipo
      const transformedProducts: Product[] = [];
      
      if (data) {
        console.log('Datos crudos de productos:', data.length);
        
        for (const item of data) {
          // Asignar la categoría usando el mapa de categorías
          let category: Category | undefined = undefined;
          if (item.category_id && categoryMap[item.category_id]) {
            category = categoryMap[item.category_id];
            console.log(`Producto ${item.name} asignado a categoría: ${category.name}`);
          }
          
          transformedProducts.push({
            id: item.id,
            name: item.name,
            description: item.description,
            base_price: item.base_price,
            main_image: item.main_image,
            stock: item.stock,
            category: category
          });
        }
      }
      
      console.log('Productos transformados:', transformedProducts.length);
      setProducts(transformedProducts);
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      setUploadingImage(true);
      setError(null);
      
      if (!file.type.startsWith('image/')) {
        throw new Error('El archivo debe ser una imagen');
      }

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('La imagen no debe superar los 5MB');
      }

      const extension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const fileName = `collections/${Date.now()}-${Math.random().toString(36).substring(7)}.${extension}`;

      const { error: uploadError } = await supabase.storage
        .from('public')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl(fileName);

      setPreviewImage(publicUrl);
      return publicUrl;
    } catch (err) {
      console.error('Error al subir la imagen:', err);
      const errorMessage = err instanceof Error ? err.message : 'Error al subir la imagen';
      setError(errorMessage);
      return null;
    } finally {
      setUploadingImage(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    
    try {
      const formData = new FormData(e.currentTarget);
      
      const collectionData = {
        name: formData.get('name') as string,
        description: formData.get('description') as string,
        type: collectionType,
        image: previewImage || editingCollection?.image,
        order: parseInt(formData.get('order') as string) || 0,
        is_active: true,
        category_ids: collectionType === 'category_mix' ? selectedCategories : null
      };

      let collectionId: string;

      if (editingCollection) {
        // Actualizar colección existente
        const { data, error } = await supabase
          .from('product_collections')
          .update(collectionData)
          .eq('id', editingCollection.id)
          .select()
          .single();

        if (error) throw error;
        collectionId = editingCollection.id;

        // Eliminar productos existentes
        const { error: deleteError } = await supabase
          .from('collection_products')
          .delete()
          .eq('collection_id', editingCollection.id);

        if (deleteError) throw deleteError;
      } else {
        // Crear nueva colección
        const { data, error } = await supabase
          .from('product_collections')
          .insert([collectionData])
          .select()
          .single();

        if (error) throw error;
        collectionId = data.id;
      }

      // Insertar productos seleccionados
      if (selectedProducts.length > 0) {
        const productsToInsert = selectedProducts.map((productId, index) => ({
          collection_id: collectionId,
          product_id: productId,
          order: index
        }));

        const { error: productsError } = await supabase
          .from('collection_products')
          .insert(productsToInsert);

        if (productsError) throw productsError;
      }

      // Recargar las colecciones después de todas las operaciones
      await fetchCollections();
      
      setShowAddModal(false);
      resetForm();
    } catch (err) {
      console.error('Error saving collection:', err);
      setError(err instanceof Error ? err.message : 'Error al guardar la colección');
    } finally {
      setSaving(false);
    }
  };

  const handleEditCollection = (collection: Collection) => {
    setEditingCollection(collection);
    setCollectionType(collection.type);
    setPreviewImage(collection.image);
    setSelectedCategories(collection.category_ids || []);
    setSelectedProducts(collection.products?.map(p => p.id) || []);
    setShowAddModal(true);
  };

  const handleDeleteCollection = async (collectionId: string) => {
    if (!confirm('¿Estás seguro de que quieres eliminar esta colección?')) return;

    try {
      setError(null);
      const { error } = await supabase
        .from('product_collections')
        .delete()
        .eq('id', collectionId);

      if (error) throw error;
      await fetchCollections();
    } catch (err) {
      console.error('Error deleting collection:', err);
      setError(err instanceof Error ? err.message : 'Error al eliminar la colección');
    }
  };

  const resetForm = () => {
    setEditingCollection(null);
    setPreviewImage(null);
    setSelectedProducts([]);
    setSelectedCategories([]);
    setCollectionType('category_mix');
    setError(null);
  };

  const getCollectionTypeIcon = (type: Collection['type']) => {
    switch (type) {
      case 'category_mix':
        return <Grid className="w-5 h-5" />;
      case 'new':
        return <Tag className="w-5 h-5" />;
      case 'featured':
        return <Star className="w-5 h-5" />;
      case 'sale':
        return <Percent className="w-5 h-5" />;
    }
  };

  const getCollectionTypeLabel = (type: Collection['type']) => {
    switch (type) {
      case 'category_mix':
        return 'Mezcla de Categorías';
      case 'new':
        return 'Nuevos Productos';
      case 'featured':
        return 'Destacados';
      case 'sale':
        return 'Ofertas';
    }
  };

  const filteredCollections = collections.filter(collection =>
    collection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    collection.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      {/* Actions */}
      <div className="flex items-center justify-between gap-4">
        <div className="flex-1 max-w-md relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar colecciones..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={() => {
              resetForm();
              setShowAddModal(true);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <Plus className="w-5 h-5" />
            Nueva Colección
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600 flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {/* Modal de Añadir/Editar Colección */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">
                {editingCollection ? 'Editar' : 'Añadir Nueva'} Colección
              </h2>
              <button
                onClick={() => {
                  setShowAddModal(false);
                  resetForm();
                }}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nombre de la Colección
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    defaultValue={editingCollection?.name}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tipo de Colección
                  </label>
                  <select
                    value={collectionType}
                    onChange={(e) => setCollectionType(e.target.value as Collection['type'])}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="category_mix">Mezcla de Categorías</option>
                    <option value="new">Nuevos Productos</option>
                    <option value="featured">Destacados</option>
                    <option value="sale">Ofertas</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Orden
                  </label>
                  <input
                    type="number"
                    name="order"
                    min="0"
                    defaultValue={editingCollection?.order || 0}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Imagen de la Colección
                  </label>
                  <div className="flex items-center gap-4">
                    {(previewImage || editingCollection?.image) ? (
                      <div className="relative">
                        <img
                          src={previewImage || editingCollection?.image || ''}
                          alt="Preview"
                          className="w-20 h-20 object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => setPreviewImage(null)}
                          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
                        disabled={uploadingImage}
                      >
                        {uploadingImage ? (
                          <Loader2 className="w-5 h-5 animate-spin" />
                        ) : (
                          <Upload className="w-5 h-5" />
                        )}
                        Subir Imagen
                      </button>
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleImageUpload(file);
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Descripción
                  </label>
                  <textarea
                    name="description"
                    required
                    defaultValue={editingCollection?.description}
                    rows={3}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  ></textarea>
                </div>

                {collectionType === 'category_mix' && (
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Categorías
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      {categories.map(category => {
                        // Calcular cuántos productos hay en esta categoría
                        const productsInThisCategory = products.filter(p => 
                          p.category && p.category.id === category.id
                        );
                        
                        return (
                          <label
                            key={category.id}
                            className={`
                              flex items-center gap-2 p-2 rounded-lg border cursor-pointer
                              ${selectedCategories.includes(category.id)
                                ? 'border-blue-500 bg-blue-50'
                                : 'border-gray-200 hover:border-blue-500'}
                            `}
                          >
                            <input
                              type="checkbox"
                              checked={selectedCategories.includes(category.id)}
                              onChange={() => {
                                // Determinar si estamos seleccionando o deseleccionando
                                const isCurrentlySelected = selectedCategories.includes(category.id);
                                
                                if (!isCurrentlySelected) {
                                  // SELECCIONAR CATEGORÍA
                                  console.log(`Seleccionando categoría: ${category.name}`);
                                  
                                  // 1. Actualizar las categorías seleccionadas
                                  const newSelectedCategories = [...selectedCategories, category.id];
                                  setSelectedCategories(newSelectedCategories);
                                  
                                  // 2. Encontrar todos los productos de esta categoría
                                  const productsInCategory = products.filter(p => 
                                    p.category && p.category.id === category.id
                                  );
                                  
                                  console.log(`Encontrados ${productsInCategory.length} productos en categoría ${category.name}`);
                                  
                                  // 3. Añadir estos productos a los seleccionados
                                  if (productsInCategory.length > 0) {
                                    const productIdsToAdd = productsInCategory.map(p => p.id);
                                    const newSelectedProducts = [...selectedProducts, ...productIdsToAdd];
                                    
                                    // Eliminar duplicados
                                    const uniqueProductIds = [...new Set(newSelectedProducts)];
                                    
                                    console.log(`Añadiendo ${productIdsToAdd.length} productos a la selección`);
                                    setSelectedProducts(uniqueProductIds);
                                    
                                    // Mostrar alerta de éxito
                                    alert(`Se han añadido ${productIdsToAdd.length} productos de la categoría ${category.name}`);
                                  }
                                } else {
                                  // DESELECCIONAR CATEGORÍA
                                  console.log(`Deseleccionando categoría: ${category.name}`);
                                  
                                  // 1. Actualizar las categorías seleccionadas
                                  const newSelectedCategories = selectedCategories.filter(id => id !== category.id);
                                  setSelectedCategories(newSelectedCategories);
                                  
                                  // 2. Encontrar todos los productos de esta categoría
                                  const productsInCategory = products.filter(p => 
                                    p.category && p.category.id === category.id
                                  );
                                  
                                  // 3. Quitar estos productos de los seleccionados
                                  if (productsInCategory.length > 0) {
                                    const productIdsToRemove = productsInCategory.map(p => p.id);
                                    const newSelectedProducts = selectedProducts.filter(
                                      id => !productIdsToRemove.includes(id)
                                    );
                                    
                                    console.log(`Quitando ${productIdsToRemove.length} productos de la selección`);
                                    setSelectedProducts(newSelectedProducts);
                                  }
                                }
                              }}
                              className="hidden"
                            />
                            <span className="text-sm">{category.name}</span>
                            
                            {/* Mostrar contador de productos */}
                            <span className="ml-auto text-xs bg-gray-100 text-gray-800 px-2 py-1 rounded-full">
                              {productsInThisCategory.length} productos
                            </span>
                          </label>
                        );
                      })}
                    </div>
                    
                    {/* Mostrar resumen de productos seleccionados */}
                    {selectedProducts.length > 0 && (
                      <div className="mt-2 text-sm text-gray-600">
                        Total de productos seleccionados: {selectedProducts.length}
                      </div>
                    )}
                    
                    {/* Mostrar productos por categoría seleccionada */}
                    {selectedCategories.length > 0 && (
                      <div className="mt-4 border-t pt-4">
                        <h3 className="text-sm font-medium text-gray-700 mb-2">Productos seleccionados por categoría:</h3>
                        
                        {selectedCategories.map(catId => {
                          const category = categories.find(c => c.id === catId);
                          const productsInCategory = products.filter(p => 
                            p.category && p.category.id === catId && selectedProducts.includes(p.id)
                          );
                          
                          return (
                            <div key={catId} className="mb-3 pb-3 border-b last:border-b-0">
                              <div className="flex items-center justify-between mb-2">
                                <span className="font-medium text-blue-600">{category?.name}</span>
                                <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                                  {productsInCategory.length} productos seleccionados
                                </span>
                              </div>
                              
                              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                                {productsInCategory.map(product => (
                                  <div key={product.id} className="text-xs text-gray-600 truncate bg-gray-50 p-1 rounded">
                                    {product.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Productos
                  </label>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 max-h-96 overflow-y-auto p-2">
                    {products.map(product => (
                      <label
                        key={product.id}
                        className={`
                          relative cursor-pointer rounded-lg overflow-hidden border
                          ${selectedProducts.includes(product.id)
                            ? 'border-blue-500 ring-2 ring-blue-500'
                            : 'border-gray-200 hover:border-blue-500'}
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={selectedProducts.includes(product.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedProducts([...selectedProducts, product.id]);
                            } else {
                              setSelectedProducts(selectedProducts.filter(id => id !== product.id));
                            }
                          }}
                          className="hidden"
                        />
                        <div className="aspect-w-1 aspect-h-1">
                          <img
                            src={product.main_image}
                            alt={product.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="p-2">
                          <h3 className="text-sm font-medium text-gray-900 truncate">
                            {product.name}
                          </h3>
                          <p className="text-sm text-gray-500">
                            Q{product.base_price.toFixed(2)}
                          </p>
                        </div>
                        {selectedProducts.includes(product.id) && (
                          <div className="absolute inset-0 bg-blue-500 bg-opacity-10 flex items-center justify-center">
                            <div className="bg-white rounded-full p-1">
                              <Check className="w-4 h-4 text-blue-500" />
                            </div>
                          </div>
                        )}
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddModal(false);
                    resetForm();
                  }}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                  disabled={saving}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
                  disabled={saving}
                >
                  {saving && <Loader2 className="w-4 h-4 animate-spin" />}
                  {editingCollection ? 'Guardar Cambios' : 'Crear Colección'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Lista de Colecciones */}
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <Loader2 className="w-12 h-12 animate-spin text-blue-600" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCollections.map((collection) => (
            <div
              key={collection.id}
              className="bg-white rounded-xl shadow-sm overflow-hidden"
            >
              <div className="aspect-w-16 aspect-h-9">
                {collection.image ? (
                  <img
                    src={collection.image}
                    alt={collection.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                    <Layers className="w-12 h-12 text-gray-400" />
                  </div>
                )}
              </div>
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold">{collection.name}</h3>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEditCollection(collection)}
                      className="p-1 text-gray-500 hover:text-blue-600"
                    >
                      <Edit className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteCollection(collection.id)}
                      className="p-1 text-gray-500 hover:text-red-600"
                    >
                      <Trash className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-gray-600 mb-3">{collection.description}</p>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center gap-2">
                    {getCollectionTypeIcon(collection.type)}
                    <span>{getCollectionTypeLabel(collection.type)}</span>
                  </div>
                  <span className="text-gray-500">
                    {collection.products?.length || 0} productos
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 