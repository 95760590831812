import { Search, X, Loader2, ArrowRight } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductSearch } from '../hooks/useProductSearch';

interface Product {
  id: string;
  name: string;
  main_image: string;
  base_price: number;
  category: {
    name: string;
  };
}

interface SearchBarProps {
  variant?: 'header' | 'navbar' | 'catalog' | 'pos';
  initialValue?: string;
  onSearch?: (query: string) => void;
  onClose?: () => void;
  showSuggestions?: boolean;
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
}

export default function SearchBar({
  variant = 'header',
  initialValue = '',
  onSearch,
  onClose,
  showSuggestions = true,
  className = '',
  autoFocus = false,
  placeholder = '¿Qué productos necesitas para tu negocio?'
}: SearchBarProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  
  const { searchTerm, setSearchTerm, suggestions, loading } = useProductSearch(initialValue);

  // Enfocar el input automáticamente cuando se muestra
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
          inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Manejar tecla Escape para cerrar
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClose) {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    
    setShowDropdown(false);
    
    if (onSearch) {
      onSearch(searchTerm.trim());
    } else {
      navigate(`/catalog?q=${encodeURIComponent(searchTerm.trim())}`);
    }
    
    if (onClose) {
      onClose();
    }
  };

  const handleProductClick = (productId: string) => {
    setShowDropdown(false);
    navigate(`/product/${productId}`);
    if (onClose) {
      onClose();
    }
  };

  // Estilos específicos según la variante
  const getContainerStyles = () => {
    switch (variant) {
      case 'header':
        return 'flex gap-2 w-full';
      case 'navbar':
        return 'max-w-3xl mx-auto flex gap-2';
      case 'catalog':
        return 'flex flex-col md:flex-row md:items-center gap-4';
      case 'pos':
        return 'w-full';
      default:
        return 'flex gap-2';
    }
  };

  const getInputContainerStyles = () => {
    switch (variant) {
      case 'header':
        return 'flex-1 flex bg-white rounded-lg overflow-hidden shadow-lg';
      case 'navbar':
        return 'flex-1 flex bg-white rounded-lg overflow-hidden border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 hover:border-gray-300 transition-colors';
      case 'catalog':
        return 'flex-1 relative';
      case 'pos':
        return 'relative w-full';
      default:
        return 'flex-1 flex bg-white rounded-lg overflow-hidden border border-gray-300';
    }
  };

  const getInputStyles = () => {
    switch (variant) {
      case 'header':
        return 'flex-1 px-4 py-3 outline-none text-gray-700 placeholder-gray-500';
      case 'navbar':
        return 'flex-1 px-4 py-2 outline-none text-gray-700 placeholder-gray-500 text-sm';
      case 'catalog':
        return 'w-full pl-10 pr-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500';
      case 'pos':
        return 'pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500';
      default:
        return 'flex-1 px-4 py-2 outline-none text-gray-700 placeholder-gray-500';
    }
  };

  const getButtonStyles = () => {
    switch (variant) {
      case 'header':
        return 'px-6 py-3 bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center justify-center rounded-lg shadow-lg';
      case 'navbar':
        return 'px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed';
      default:
        return 'px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed';
    }
  };

  return (
    <div className={`${className} relative`}>
      <form onSubmit={handleSearch} className={getContainerStyles()}>
        <div className={getInputContainerStyles()}>
          {(variant === 'catalog' || variant === 'pos') && (
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          )}
          {variant === 'navbar' && (
            <div className="flex items-center pl-3 text-gray-400">
              <Search className="h-4 w-4" />
            </div>
          )}
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (showSuggestions) setShowDropdown(true);
            }}
            onFocus={() => showSuggestions && setShowDropdown(true)}
            placeholder={placeholder}
            className={getInputStyles()}
            autoFocus={autoFocus}
          />
          {searchTerm && (
            <button 
              type="button"
              onClick={() => setSearchTerm('')}
              className="px-2 text-gray-400 hover:text-gray-600"
            >
              <X className="h-4 w-4" />
            </button>
          )}
          {variant === 'navbar' && onClose && (
            <button 
              type="button"
              onClick={onClose}
              className="px-3 text-gray-400 hover:text-gray-600"
              aria-label="Cerrar búsqueda"
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>
        
        {(variant === 'header' || (variant === 'navbar' && onClose)) && (
          <button 
            type="submit"
            className={getButtonStyles()}
            disabled={!searchTerm.trim()}
          >
            {loading ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <>
                <Search className="w-4 h-4" />
                {variant === 'header' && <span className="ml-1">Buscar</span>}
              </>
            )}
          </button>
        )}
      </form>

      {/* Dropdown de sugerencias */}
      {showSuggestions && showDropdown && (searchTerm.trim() || loading) && (
        <div 
          ref={dropdownRef}
          className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-xl overflow-hidden z-50 max-h-[60vh] overflow-y-auto"
        >
          {loading ? (
            <div className="p-4 text-center text-gray-500">
              <Loader2 className="w-6 h-6 animate-spin mx-auto" />
              <p className="mt-2">Buscando productos...</p>
            </div>
          ) : suggestions.length > 0 ? (
            <div className="divide-y">
              {suggestions.map((product) => (
                <button
                  key={product.id}
                  onClick={() => handleProductClick(product.id)}
                  className="w-full p-3 flex items-center gap-3 hover:bg-gray-50 text-left transition-colors"
                >
                  <div className="w-16 h-16 flex-shrink-0 bg-gray-100 rounded overflow-hidden">
                    <img
                      src={product.main_image}
                      alt={product.name}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = '/assets/placeholder.png';
                      }}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {product.name}
                    </p>
                    <p className="text-sm text-gray-500">
                      {product.category.name}
                    </p>
                  </div>
                  <div className="text-sm font-medium text-blue-600">
                    Q{product.base_price.toFixed(2)}
                  </div>
                </button>
              ))}
              <button
                onClick={handleSearch}
                className="w-full p-3 text-center text-blue-600 hover:bg-gray-50 font-medium flex items-center justify-center gap-2"
              >
                <span>Ver todos los resultados</span>
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          ) : searchTerm.trim() ? (
            <div className="p-6 text-center text-gray-500">
              <div className="mb-2">😕</div>
              <p className="font-medium">No se encontraron productos</p>
              <p className="text-sm mt-1">Intenta con otra búsqueda</p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
} 