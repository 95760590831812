import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useCartContext } from '../contexts/CartContext';

interface UseCartOptions {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export function useCart(options: UseCartOptions = {}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { refreshCart } = useCartContext();

  const addToCart = async (productId: string, quantity: number) => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      // Obtener el tipo de precio del usuario
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('price_type')
        .eq('id', user.id)
        .single();

      if (profileError) {
        console.error('Error fetching user price type:', profileError);
      }

      const userPriceType = profile?.price_type || null;

      // Obtener información del producto para verificar cantidades mínimas
      const { data: product, error: productError } = await supabase
        .from('products')
        .select(`
          id,
          base_price,
          min_order,
          prices:product_prices(price_type, min_quantity, price)
        `)
        .eq('id', productId)
        .single();

      if (productError) {
        throw productError;
      }

      // Determinar la cantidad mínima según el tipo de precio del usuario
      let minQuantity = product.min_order || 1;
      
      // Calcular el precio específico para el usuario
      let unitPrice = product.base_price;
      let savedPriceType = userPriceType || 'contado';
      
      // Si el usuario tiene asignado el tipo de precio "mayorista"
      if (userPriceType === 'mayorista' && product.prices) {
        // Buscar precios de mayorista
        const mayoristaPrice = product.prices.find((p: any) => p.price_type === 'mayorista');
        
        if (mayoristaPrice) {
          minQuantity = Math.max(minQuantity, mayoristaPrice.min_quantity);
          unitPrice = mayoristaPrice.price;
        }
      } else if (product.prices && product.prices.length > 0 && userPriceType) {
        // Filtrar por tipo de precio del usuario
        const userPrices = product.prices.filter((p: any) => p.price_type === userPriceType);
        
        if (userPrices.length > 0) {
          // Obtener la cantidad mínima más baja para el tipo de precio del usuario
          const minPriceForType = userPrices.sort((a: any, b: any) => a.min_quantity - b.min_quantity)[0];
          minQuantity = Math.max(minQuantity, minPriceForType.min_quantity);
          unitPrice = minPriceForType.price;
        }
      }

      // Asegurar que la cantidad sea al menos la mínima requerida
      const finalQuantity = Math.max(quantity, minQuantity);

      // Obtener o crear el carrito del usuario
      let { data: cart } = await supabase
        .from('cart')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (!cart) {
        const { data: newCart, error: createError } = await supabase
          .from('cart')
          .insert([{ user_id: user.id }])
          .select('id')
          .single();

        if (createError) throw createError;
        cart = newCart;
      }

      // Verificar si el producto ya está en el carrito
      const { data: existingItem } = await supabase
        .from('cart_items')
        .select('id, quantity')
        .eq('cart_id', cart.id)
        .eq('product_id', productId)
        .single();

      if (existingItem) {
        // Actualizar cantidad si ya existe, asegurando que sea al menos la mínima
        const newQuantity = Math.max(existingItem.quantity + quantity, minQuantity);
        
        const { error: updateError } = await supabase
          .from('cart_items')
          .update({ 
            quantity: newQuantity,
            price_type: savedPriceType,
            updated_at: new Date().toISOString()
          })
          .eq('id', existingItem.id);

        if (updateError) throw updateError;
      } else {
        // Insertar nuevo item si no existe
        try {
          const insertPayload = {
            cart_id: cart.id,
            product_id: productId,
            quantity: finalQuantity,
            price_type: savedPriceType,
            created_at: new Date().toISOString()
          };
          
          console.log('Insertando nuevo ítem al carrito:', insertPayload);
          
          const { error: insertError } = await supabase
            .from('cart_items')
            .insert([insertPayload]);

          if (insertError) {
            console.error('Error al insertar ítem en el carrito:', insertError);
            throw insertError;
          }
          
          console.log('Ítem insertado exitosamente, actualizando carrito...');
          
          await refreshCart();
          options.onSuccess?.();
        } catch (err) {
          console.error('Error adding to cart:', err);
          options.onError?.(err instanceof Error ? err.message : 'Error al agregar al carrito');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    addToCart
  };
} 