import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface Product {
  id: string;
  name: string;
  main_image: string;
  base_price: number;
  category: {
    name: string;
  };
}

export function useProductSearch(initialQuery: string = '', limit: number = 5) {
  const [searchTerm, setSearchTerm] = useState(initialQuery);
  const [suggestions, setSuggestions] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchProducts = async () => {
      if (!searchTerm.trim()) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('products')
          .select(`
            id,
            name,
            main_image,
            base_price,
            category:categories(name)
          `)
          .eq('status', 'active')
          .ilike('name', `%${searchTerm}%`)
          .limit(limit);

        if (error) throw error;
        setSuggestions(data || []);
      } catch (err) {
        console.error('Error searching products:', err);
      } finally {
        setLoading(false);
      }
    };

    const debounceTimer = setTimeout(searchProducts, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, limit]);

  return {
    searchTerm,
    setSearchTerm,
    suggestions,
    loading,
  };
} 