import { ArrowRight, ShoppingCart, Heart, Eye, Clock, Truck, Package, Tag, Layers, Star, Percent, Grid, Timer, TrendingUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';

// Definir la interfaz Product para evitar errores de tipo
interface Product {
  id: string;
  name: string;
  main_image: string;
  price: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

// Definir la interfaz para los productos que vienen de la colección
interface CollectionProduct {
  id: string;
  name: string;
  main_image: string;
  price?: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

interface Collection {
  id: string;
  name: string;
  description: string;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  products: CollectionProduct[];
}

interface CollectionSectionProps {
  collectionType: 'category_mix' | 'new' | 'featured' | 'sale';
  collectionId?: string;
}

export default function CollectionSection({ collectionType, collectionId }: CollectionSectionProps) {
  const [collection, setCollection] = useState<Collection | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredProduct, setHoveredProduct] = useState<string | null>(null);
  const { user } = useAuth();
  const { priceType } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true);
        
        let collectionData;
        
        if (collectionId) {
          // Si se proporciona un ID específico, obtener esa colección
          const { data, error: collectionError } = await supabase
            .from('product_collections')
            .select(`
              id,
              name,
              description,
              type
            `)
            .eq('id', collectionId)
            .eq('is_active', true)
            .single();
            
          if (collectionError) throw collectionError;
          collectionData = data;
        } else {
          // Si no se proporciona un ID, obtener la colección por tipo
          // Modificación: obtener todas las colecciones activas y filtrar por tipo manualmente
          const { data, error: collectionError } = await supabase
            .from('product_collections')
            .select(`
              id,
              name,
              description,
              type
            `)
            .eq('is_active', true)
            .order('order', { ascending: true });
            
          if (collectionError) throw collectionError;
          
          // Filtrar por tipo manualmente
          collectionData = data?.find(c => c.type === collectionType);
        }
        
        if (!collectionData && collectionType !== 'new') {
          console.log(`No collection found for type: ${collectionType} or id: ${collectionId}`);
          setCollection(null);
          return;
        }
        
        let products: CollectionProduct[] = [];
        
        if (collectionType === 'new' && !collectionId) {
          // Para productos nuevos, obtenemos directamente los últimos productos agregados
          const { data: newProducts, error: productsError } = await supabase
            .from('products')
            .select(`
              id,
              name,
              description,
              base_price,
              main_image,
              stock,
              min_order,
              code
            `)
            .eq('status', 'active')
            .order('created_at', { ascending: false })
            .limit(10);

          if (productsError) throw productsError;

          // Transformar los productos
          products = newProducts.map(product => ({
            id: product.id,
            name: product.name,
            main_image: product.main_image,
            price: product.base_price,
            stock: product.stock,
            sales: Math.floor(Math.random() * 5000) + 500,
            freeShipping: product.base_price > 500,
            minOrder: product.min_order || 6,
            code: product.code
          }));
        } else if (collectionData) {
          // Para otros tipos, primero obtenemos los IDs de los productos en la colección
          const { data: collectionProductIds, error: idsError } = await supabase
            .from('collection_products')
            .select('product_id')
            .eq('collection_id', collectionData.id);

          if (idsError) throw idsError;

          if (collectionProductIds && collectionProductIds.length > 0) {
            // Extraer los IDs de productos
            const productIds = collectionProductIds.map(item => item.product_id);
            
            // Obtener los detalles de los productos
            const { data: productDetails, error: detailsError } = await supabase
              .from('products')
              .select(`
                id,
                name,
                description,
                base_price,
                main_image,
                stock,
                min_order,
                code
              `)
              .in('id', productIds)
              .eq('status', 'active');

            if (detailsError) throw detailsError;

            // Transformar los productos
            products = productDetails.map(product => ({
              id: product.id,
              name: product.name,
              main_image: product.main_image,
              price: product.base_price,
              stock: product.stock,
              sales: Math.floor(Math.random() * 5000) + 500,
              freeShipping: product.base_price > 500,
              minOrder: product.min_order || 6,
              code: product.code
            }));
          }
        }

        // Crear el objeto de colección
        setCollection({
          id: collectionData?.id || `${collectionType}-default`,
          name: collectionData?.name || (collectionType === 'new' ? 'Productos Nuevos' : 
                                        collectionType === 'featured' ? 'Productos Destacados' : 
                                        collectionType === 'sale' ? 'Ofertas Especiales' : 
                                        'Colección por Categoría'),
          description: collectionData?.description || 'Descubre nuestra selección especial de productos',
          type: collectionType,
          products: products,
        });

        // Si el usuario está logueado y tiene un tipo de precio asignado, obtener los precios específicos
        if (user && priceType && products.length > 0) {
          const productIds = products.map(p => p.id);
          
          // Obtener los precios específicos para los productos
          const { data: prices, error: pricesError } = await supabase
            .from('product_prices')
            .select('product_id, price_type, min_quantity, price')
            .in('product_id', productIds)
            .eq('price_type', priceType);
          
          if (pricesError) throw pricesError;
          
          // Crear un objeto con los precios específicos por producto
          const pricesMap: Record<string, number> = {};
          
          if (prices && prices.length > 0) {
            // Agrupar los precios por producto_id
            const pricesByProduct: Record<string, any[]> = {};
            
            prices.forEach(price => {
              if (!pricesByProduct[price.product_id]) {
                pricesByProduct[price.product_id] = [];
              }
              pricesByProduct[price.product_id].push(price);
            });
            
            // Para cada producto, obtener el precio con la menor cantidad mínima
            Object.keys(pricesByProduct).forEach(productId => {
              const productPrices = pricesByProduct[productId];
              const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
              pricesMap[productId] = minPriceForType.price;
            });
          }
          
          setProductPrices(pricesMap);
        }
      } catch (err) {
        console.error('Error fetching collection:', err);
        setError(err instanceof Error ? err.message : 'Error al cargar la colección');
      } finally {
        setLoading(false);
      }
    };

    // Configurar la suscripción en tiempo real para collection_products
    const collectionProductsSubscription = supabase
      .channel('collection_products_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'collection_products'
        }, 
        () => {
          fetchCollection();
        }
      )
      .subscribe();

    // Configurar la suscripción en tiempo real para product_collections
    const productCollectionsSubscription = supabase
      .channel('product_collections_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'product_collections'
        }, 
        () => {
          fetchCollection();
        }
      )
      .subscribe();

    fetchCollection();

    // Limpiar las suscripciones cuando el componente se desmonte
    return () => {
      collectionProductsSubscription.unsubscribe();
      productCollectionsSubscription.unsubscribe();
    };
  }, [collectionType, collectionId, user, priceType]);

  // Función para obtener el icono según el tipo de colección
  const getCollectionIcon = () => {
    switch (collectionType) {
      case 'category_mix':
        return <Grid className="w-5 h-5 text-purple-600" />;
      case 'new':
        return <Clock className="w-5 h-5 text-green-600" />;
      case 'featured':
        return <Star className="w-5 h-5 text-amber-600" />;
      case 'sale':
        return <Percent className="w-5 h-5 text-red-600" />;
      default:
        return <Layers className="w-5 h-5 text-blue-600" />;
    }
  };

  // Función para obtener el color de fondo según el tipo de colección
  const getCollectionBgColor = () => {
    switch (collectionType) {
      case 'category_mix':
        return 'from-gray-50 to-purple-50';
      case 'new':
        return 'from-gray-50 to-green-50';
      case 'featured':
        return 'from-gray-50 to-amber-50';
      case 'sale':
        return 'from-gray-50 to-red-50';
      default:
        return 'from-gray-50 to-blue-50';
    }
  };

  // Función para obtener el color del icono según el tipo de colección
  const getIconBgColor = () => {
    switch (collectionType) {
      case 'category_mix':
        return 'bg-purple-100';
      case 'new':
        return 'bg-green-100';
      case 'featured':
        return 'bg-amber-100';
      case 'sale':
        return 'bg-red-100';
      default:
        return 'bg-blue-100';
    }
  };

  if (loading) {
    return (
      <section>
        <div className="animate-pulse space-y-6">
          <div className="flex justify-between items-center">
            <div className="h-10 bg-gray-200 rounded w-1/3"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {[1, 2, 3, 4, 5].map((i) => (
              <div key={i} className="bg-gray-100 rounded-xl overflow-hidden">
                <div className="h-40 bg-gray-200"></div>
                <div className="p-3 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                  <div className="flex justify-between">
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section>
        <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg">
          Error: {error}
        </div>
      </section>
    );
  }

  if (!collection || !collection.products?.length) {
    return null;
  }

  return (
    <section className={`bg-gradient-to-br ${getCollectionBgColor()} rounded-2xl p-6 shadow-sm border border-gray-100 mb-6`}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-3">
          <div className={`${getIconBgColor()} p-2 rounded-full`}>
            {getCollectionIcon()}
          </div>
          <h2 className="text-2xl font-bold text-gray-900">{collection.name}</h2>
        </div>
        <Link
          to={`/see-more/${collection.id}`}
          className="flex items-center gap-2 bg-white hover:bg-gray-50 text-gray-700 px-4 py-2 rounded-lg transition-colors shadow-sm"
        >
          Ver más <ArrowRight className="w-4 h-4" />
        </Link>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
        {collection.products.slice(0, 5).map((product) => (
          <Link
            key={product.id}
            to={`/product/${product.id}`}
            className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group"
            onMouseEnter={() => setHoveredProduct(product.id)}
            onMouseLeave={() => setHoveredProduct(null)}
          >
            <div className="relative">
              <img
                src={product.main_image}
                alt={product.name}
                className="w-full h-40 object-cover group-hover:scale-105 transition-transform duration-300"
              />
             
             
            </div>
            <div className="p-3">
              <h3 className="text-sm font-medium text-gray-900 line-clamp-2 group-hover:text-blue-600 transition-colors">
                {product.name}
              </h3>
              {product.code && (
                <p className="text-xs text-gray-500 mt-1">
                  Código: {product.code}
                </p>
              )}
              <div className="mt-2 flex items-center justify-between">
                <div className="space-y-1">
                  {user && priceType ? (
                    <div className="flex items-baseline gap-2">
                      <span className="text-lg font-semibold text-blue-600">
                        Q{(productPrices[product.id] || product.price)?.toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <Link to="/login" className="text-xs text-blue-600 hover:text-blue-800 font-medium">
                        Inicia sesión para ver precios
                      </Link>
                    </div>
                  )}
                
                </div>
              
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
} 