import { ArrowRight, ShoppingCart, Timer, Heart, Eye, TrendingUp, Truck, Package } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection';
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';
import { supabase } from '../lib/supabase';

// Definir la interfaz para los productos que vienen de la colección
interface CollectionProduct {
  id: string;
  name: string;
  main_image: string;
  price?: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

// Definir la interfaz Product para evitar errores de tipo
interface Product {
  id: string;
  name: string;
  main_image: string;
  price: number;
  sales?: number;
  stock?: number;
  freeShipping?: boolean;
  minOrder?: number;
  code?: string;
}

export default function PopularProducts() {
  const { collection, loading, error } = useCollection('new');
  const [hoveredProduct, setHoveredProduct] = useState<string | null>(null);
  const { user } = useAuth();
  const { priceType } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  useEffect(() => {
    const fetchProductPrices = async () => {
      if (!user || !priceType || !collection || !collection.products.length) return;

      try {
        const productIds = collection.products.map(p => p.id);
        
        // Obtener los precios específicos para los productos
        const { data: prices, error: pricesError } = await supabase
          .from('product_prices')
          .select('product_id, price_type, min_quantity, price')
          .in('product_id', productIds)
          .eq('price_type', priceType);
        
        if (pricesError) throw pricesError;
        
        // Crear un objeto con los precios específicos por producto
        const pricesMap: Record<string, number> = {};
        
        if (prices && prices.length > 0) {
          // Agrupar los precios por producto_id
          const pricesByProduct: Record<string, any[]> = {};
          
          prices.forEach(price => {
            if (!pricesByProduct[price.product_id]) {
              pricesByProduct[price.product_id] = [];
            }
            pricesByProduct[price.product_id].push(price);
          });
          
          // Para cada producto, obtener el precio con la menor cantidad mínima
          Object.keys(pricesByProduct).forEach(productId => {
            const productPrices = pricesByProduct[productId];
            const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
            pricesMap[productId] = minPriceForType.price;
          });
        }
        
        setProductPrices(pricesMap);
      } catch (err) {
        console.error('Error fetching product prices:', err);
      }
    };

    fetchProductPrices();
  }, [user, priceType, collection]);

  if (loading) {
    return (
      <section>
        <div className="animate-pulse space-y-6">
          <div className="flex justify-between items-center">
            <div className="h-10 bg-gray-200 rounded w-1/3"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {[1, 2, 3, 4, 5].map((i) => (
              <div key={i} className="bg-gray-100 rounded-xl overflow-hidden">
                <div className="h-40 bg-gray-200"></div>
                <div className="p-3 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                  <div className="flex justify-between">
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section>
        <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg">
          Error: {error}
        </div>
      </section>
    );
  }

  if (!collection || !collection.products?.length) {
    return null;
  }

  return (
    <section className="bg-gradient-to-br from-gray-50 to-blue-50 rounded-2xl p-6 shadow-sm border border-gray-100">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-3">
          <div className="bg-blue-100 p-2 rounded-full">
            <TrendingUp className="w-5 h-5 text-blue-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900">{collection.name}</h2>
        </div>
        <Link
          to="/catalog"
          className="flex items-center gap-2 bg-white hover:bg-gray-50 text-gray-700 px-4 py-2 rounded-lg transition-colors shadow-sm"
        >
          Ver más <ArrowRight className="w-4 h-4" />
        </Link>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
        {collection.products.map((product: CollectionProduct) => {
          // Asegurar que el producto tenga la estructura correcta
          const typedProduct: Product = {
            id: product.id,
            name: product.name,
            main_image: product.main_image,
            price: product.price || 0,
            sales: product.sales,
            stock: product.stock,
            freeShipping: product.freeShipping || (product.price ? product.price > 500 : false), // Envío gratis para productos > Q500
            minOrder: product.minOrder || 6, // Pedido mínimo por defecto de 6 unidades
            code: product.code
          };
          
          return (
            <div 
              key={typedProduct.id}
              className="group relative bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100"
              onMouseEnter={() => setHoveredProduct(typedProduct.id)}
              onMouseLeave={() => setHoveredProduct(null)}
            >
              {/* Imagen del producto */}
              <Link to={`/product/${typedProduct.id}`} className="block relative">
                <div className="aspect-square bg-gray-50">
                  <img
                    src={typedProduct.main_image}
                    alt={typedProduct.name}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                  />
                </div>
                
              
               
              </Link>
              
              {/* Información del producto */}
              <div className="p-3 space-y-2">
                <Link to={`/product/${typedProduct.id}`} className="block">
                  <h3 className="text-sm text-gray-900 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors">
                    {typedProduct.name}
                  </h3>
                  {typedProduct.code && (
                    <p className="text-xs text-gray-500 mt-1">
                      Código: {typedProduct.code}
                    </p>
                  )}
                </Link>
                
                {/* Precios - Solo mostrar si el usuario está logueado y tiene un tipo de precio asignado */}
                {user && priceType ? (
                  <div className="bg-blue-50 p-2 rounded-lg">
                    <div className="flex items-baseline justify-between">
                      <span className="font-semibold text-blue-600">Q{(productPrices[typedProduct.id] || typedProduct.price).toFixed(2)}</span>
                      <span className="text-xs font-medium text-gray-700">
                        C/U
                      </span>
                    </div>
                    
        
                  </div>
                ) : (
                  <div className="bg-gray-50 p-2 rounded-lg text-center">
                    <Link to="/login" className="text-xs text-blue-600 hover:text-blue-800 font-medium">
                      Regístrate para ver precios
                    </Link>
                  </div>
                )}
                
            
                
              </div>
              
              {/* Botón de compra rápida (aparece al hacer hover) */}
              <div className={`absolute inset-x-0 bottom-0 bg-gradient-to-t from-blue-600 to-blue-600/90 text-white text-xs font-medium py-2 text-center transform transition-transform duration-300 ${hoveredProduct === typedProduct.id ? 'translate-y-0' : 'translate-y-full'}`}>
                Comprar ahora
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}