import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string[];
  image?: string;
  url?: string;
  type?: 'website' | 'product' | 'article';
  productData?: {
    name: string;
    description: string;
    price: number;
    currency?: string;
    image: string;
    sku?: string;
    availability?: 'InStock' | 'OutOfStock' | 'PreOrder';
    brand?: string;
    category?: string;
  };
  articleData?: {
    publishedTime: string;
    modifiedTime?: string;
    author?: string;
    section?: string;
    tags?: string[];
  };
  language?: string;
  noindex?: boolean;
}

export default function SEO({
  title,
  description = 'Productos al por mayor en Guatemala. Encuentra los mejores precios para tu negocio.',
  keywords = ['mayorista', 'Guatemala', 'productos', 'venta al por mayor', 'peluches', 'tarjetas', 'globos', 'regalos'],
  image = '/assets/DISOLGUA.png',
  url,
  type = 'website',
  productData,
  articleData,
  language = 'es-GT',
  noindex = false
}: SEOProps) {
  const siteTitle = 'Disolgua - Distribuidora del Sol Guatemala S.A';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const siteUrl = 'https://disolgua.com';
  const canonicalUrl = url ? `${siteUrl}${url}` : siteUrl;
  const imageUrl = image.startsWith('http') ? image : `${siteUrl}${image}`;

  // Generar JSON-LD según el tipo de página
  const generateJsonLd = () => {
    // Datos base de la organización
    const organizationData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Disolgua",
      "alternateName": "Distribuidora del Sol Guatemala S.A",
      "description": "Distribuidora del Sol Guatemala S.A - Los mejores productos para tu negocio",
      "url": siteUrl,
      "logo": `${siteUrl}/assets/DISOLGUA.png`,
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+50247391242",
        "contactType": "customer service",
        "availableLanguage": "Spanish"
      },
      "sameAs": [
        "https://facebook.com/disolgua"
      ],
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "GT",
        "addressLocality": "Guatemala City",
        "addressRegion": "Guatemala"
      }
    };

    // Datos específicos según el tipo de página
    if (type === 'product' && productData) {
      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Product",
        "name": productData.name,
        "description": productData.description,
        "image": productData.image.startsWith('http') ? productData.image : `${siteUrl}${productData.image}`,
        "sku": productData.sku || "",
        "brand": {
          "@type": "Brand",
          "name": productData.brand || "Disolgua"
        },
        "offers": {
          "@type": "Offer",
          "url": canonicalUrl,
          "priceCurrency": productData.currency || "GTQ",
          "price": productData.price,
          "availability": `https://schema.org/${productData.availability || "InStock"}`,
          "priceValidUntil": new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          "seller": {
            "@type": "Organization",
            "name": "Disolgua"
          }
        },
        "category": productData.category || "",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "25"
        }
      });
    } else if (type === 'article' && articleData) {
      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": title,
        "image": imageUrl,
        "datePublished": articleData.publishedTime,
        "dateModified": articleData.modifiedTime || articleData.publishedTime,
        "author": {
          "@type": "Person",
          "name": articleData.author || "Disolgua"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Disolgua",
          "logo": {
            "@type": "ImageObject",
            "url": `${siteUrl}/assets/DISOLGUA.png`
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": canonicalUrl
        },
        "articleSection": articleData.section || "",
        "keywords": keywords.join(", ")
      });
    } else {
      // Para páginas normales, devolver datos de la organización y breadcrumbs
      const breadcrumbData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Inicio",
            "item": siteUrl
          },
          ...(url && url !== '/' ? [
            {
              "@type": "ListItem",
              "position": 2,
              "name": title || "Página",
              "item": canonicalUrl
            }
          ] : [])
        ]
      };

      return JSON.stringify([organizationData, breadcrumbData]);
    }
  };

  return (
    <Helmet>
      {/* Básico */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="robots" content={noindex ? "noindex, nofollow" : "index, follow"} />
      <meta name="author" content="Disolgua" />
      <meta name="language" content={language} />

      {/* Open Graph */}
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:locale" content="es_GT" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content="@disolgua" />

      {/* Adicional para productos */}
      {type === 'product' && productData && (
        <>
          <meta property="og:price:amount" content={productData.price.toString()} />
          <meta property="og:price:currency" content={productData.currency || "GTQ"} />
          <meta property="product:price:amount" content={productData.price.toString()} />
          <meta property="product:price:currency" content={productData.currency || "GTQ"} />
          <meta property="product:availability" content={productData.availability === 'InStock' ? 'En stock' : 'Agotado'} />
          <meta property="product:brand" content={productData.brand || "Disolgua"} />
          <meta property="product:category" content={productData.category || ""} />
        </>
      )}

      {/* Datos estructurados JSON-LD */}
      <script type="application/ld+json">{generateJsonLd()}</script>
      
      {/* Verificación de propiedad del sitio para Google Search Console */}
      <meta name="google-site-verification" content="AGREGAR_CÓDIGO_DE_VERIFICACIÓN" />
      
      {/* Favicon y Apple Touch Icons */}
      <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon.png" />
      <meta name="theme-color" content="#ffffff" />
      
      {/* Preconectar a dominios externos importantes */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
    </Helmet>
  );
} 