import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Crear cliente de Supabase con sesiones configuradas para durar 2 horas (7200 segundos)
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storageKey: 'disolgua-auth',
    // Configurar la duración de la sesión a 2 horas
    flowType: 'pkce',
    sessionExpiryTime: 7200 // 2 horas en segundos
  }
});

/**
 * Ejecuta una operación de Supabase con reintentos automáticos en caso de errores de conexión
 * @param operation Función que realiza la operación de Supabase
 * @param maxRetries Número máximo de reintentos (por defecto 3)
 * @param delay Retraso inicial entre reintentos en ms (por defecto 1000)
 * @returns El resultado de la operación
 */
export async function withRetry<T>(
  operation: () => Promise<T>,
  maxRetries = 3,
  delay = 1000
): Promise<T> {
  let lastError: any;
  
  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      // Intentar la operación
      return await operation();
    } catch (error) {
      console.warn(`Intento ${attempt + 1}/${maxRetries + 1} falló:`, error);
      lastError = error;
      
      // Si es el último intento, no esperar
      if (attempt === maxRetries) break;
      
      // Esperar antes del siguiente intento (con backoff exponencial)
      const backoffDelay = delay * Math.pow(2, attempt);
      await new Promise(resolve => setTimeout(resolve, backoffDelay));
    }
  }
  
  // Si llegamos aquí, todos los intentos fallaron
  console.error(`Todos los intentos fallaron después de ${maxRetries + 1} intentos`);
  throw lastError;
}

/**
 * Verifica si hay conexión a internet
 * @returns Promise que resuelve a true si hay conexión, false si no
 */
export async function checkConnection(): Promise<boolean> {
  try {
    // Método 1: Intentar hacer una petición simple al endpoint de Supabase
    try {
      const response = await fetch(`${supabaseUrl}/rest/v1/`, {
        method: 'HEAD',
        headers: {
          'apikey': supabaseAnonKey
        }
      });
      
      if (response.ok) {
        console.log('Conexión verificada mediante endpoint REST');
        return true;
      }
    } catch (e) {
      console.warn('Error al verificar conexión mediante endpoint REST:', e);
    }
    
    // Método 2: Intentar consultar una tabla que sabemos que existe
    try {
      console.log('Intentando verificar conexión mediante consulta a tabla...');
      const { error } = await supabase
        .from('profiles')
        .select('id')
        .limit(1);
      
      if (!error) {
        console.log('Conexión verificada mediante consulta a tabla profiles');
        return true;
      }
    } catch (e) {
      console.warn('Error al verificar conexión mediante consulta a tabla:', e);
    }
    
    // Si llegamos aquí, ambos métodos fallaron
    console.error('No se pudo establecer conexión con Supabase');
    return false;
  } catch (error) {
    console.error('Error general al verificar conexión:', error);
    return false;
  }
}

/**
 * Ejecuta una operación solo si hay conexión a internet
 * @param operation Función que realiza la operación
 * @param onNoConnection Función a ejecutar si no hay conexión
 * @returns El resultado de la operación o undefined si no hay conexión
 */
export async function withConnectionCheck<T>(
  operation: () => Promise<T>,
  onNoConnection?: () => void
): Promise<T | undefined> {
  try {
    console.log('Verificando conexión a internet...');
    const isConnected = await checkConnection();
    
    if (!isConnected) {
      console.warn('No hay conexión a internet');
      if (onNoConnection) {
        onNoConnection();
      } else {
        console.warn('No se proporcionó una función para manejar la falta de conexión');
      }
      return undefined;
    }
    
    console.log('Conexión verificada, ejecutando operación...');
    return await operation();
  } catch (error) {
    console.error('Error durante la verificación de conexión o la operación:', error);
    throw error;
  }
}