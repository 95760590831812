import { useState, useEffect } from 'react';
import { ShoppingCart as CartIcon, Trash, X, Loader2, RefreshCw, Plus, Minus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase, withRetry, withConnectionCheck } from '../lib/supabase';
import { useCartContext } from '../contexts/CartContext';

export default function ShoppingCart() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { cartItems, loading, error, refreshCart } = useCartContext();
  const [userPriceType, setUserPriceType] = useState<'contado' | 'credito' | 'mayorista' | 'publico' | null>(null);

  useEffect(() => {
    const fetchUserPriceType = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data: profile, error } = await supabase
          .from('profiles')
          .select('price_type')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        console.log('Tipo de precio del usuario cargado:', profile?.price_type);
        setUserPriceType(profile?.price_type || null);
      } catch (err) {
        console.error('Error fetching user price type:', err);
      }
    };
    
    fetchUserPriceType();
  }, []);

  // Efecto para refrescar el carrito cuando se abre el modal
  useEffect(() => {
    if (isOpen) {
      console.log('Modal de carrito abierto, refrescando datos...');
      refreshCart();
    }
  }, [isOpen, refreshCart]);

  const removeFromCart = async (itemId: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: cart } = await supabase
        .from('cart')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (!cart) return;

      const { error } = await supabase
        .from('cart_items')
        .delete()
        .eq('id', itemId)
        .eq('cart_id', cart.id);

      if (error) throw error;
      await refreshCart();
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  const getProductPrice = (item: typeof cartItems[0]) => {
    console.log(`=== CÁLCULO DE PRECIO PARA ${item.product.name} ===`);
    console.log(`Cantidad en carrito: ${item.quantity}`);
    
    console.log(`Tipo de precio del usuario: ${userPriceType}`);
    console.log(`Precio base del producto: ${item.product.base_price}`);
    
    // Verificar si los precios están disponibles y son un array
    if (!item.product.prices || !Array.isArray(item.product.prices) || item.product.prices.length === 0) {
      console.log(`No hay precios definidos, usando precio base: ${item.product.base_price}`);
      return item.product.base_price;
    }
    
    console.log(`Precios disponibles:`, JSON.stringify(item.product.prices, null, 2));
    
    // Si el usuario no tiene tipo de precio asignado, usar el precio base
    if (!userPriceType) {
      console.log(`No hay tipo de precio asignado, usando precio base: ${item.product.base_price}`);
      return item.product.base_price;
    }
    
    try {
      // Si el usuario tiene asignado el tipo de precio "mayorista"
      if (userPriceType === 'mayorista') {
        // Buscar precios de mayorista
        const mayoristaPrice = item.product.prices.find(p => p.price_type === 'mayorista');
        console.log(`Precio mayorista encontrado:`, mayoristaPrice);
        
        if (mayoristaPrice && item.quantity >= mayoristaPrice.min_quantity) {
          console.log(`Usando precio mayorista (${mayoristaPrice.min_quantity}+): ${mayoristaPrice.price}`);
          return mayoristaPrice.price;
        }
      }
      
      // Filtrar precios por tipo de usuario
      const userPrices = item.product.prices.filter(p => p.price_type === userPriceType);
      console.log(`Precios filtrados por tipo ${userPriceType}:`, JSON.stringify(userPrices, null, 2));
      
      if (userPrices.length === 0) {
        console.log(`No se encontraron precios para tipo ${userPriceType}, usando precio base: ${item.product.base_price}`);
        return item.product.base_price;
      }
      
      // Encontrar el precio aplicable según la cantidad
      const validPrices = userPrices
        .filter(price => item.quantity >= price.min_quantity)
        .sort((a, b) => b.min_quantity - a.min_quantity); // Ordenar de mayor a menor cantidad mínima
      
      console.log(`Precios válidos según cantidad (${item.quantity}):`, JSON.stringify(validPrices, null, 2));
      
      if (validPrices.length > 0) {
        console.log(`Usando precio para cantidad ${validPrices[0].min_quantity}+: ${validPrices[0].price}`);
        return validPrices[0].price;
      }
      
      // Si no hay precios válidos según la cantidad, usar el precio base
      console.log(`No hay precios válidos según la cantidad, usando precio base: ${item.product.base_price}`);
      return item.product.base_price;
    } catch (error) {
      console.error('Error al calcular el precio:', error);
      return item.product.base_price;
    }
  };

  const handleCheckout = async () => {
    try {
      setIsSubmitting(true);
      
      // Verificar si el usuario está autenticado
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      // Cerrar el modal y redirigir a la página de checkout
      setIsOpen(false);
      navigate('/checkout');
    } catch (err) {
      console.error('Error al procesar el checkout:', err);
      
      // Mostrar mensaje de error
      if (err instanceof Error) {
        alert(`Error al procesar el checkout: ${err.message}`);
      } else {
        alert('Error al procesar el checkout. Por favor, intenta de nuevo.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCartTotal = () => {
    console.log('Calculando total del carrito...');
    let total = 0;
    
    for (const item of cartItems) {
      const pricePerUnit = getProductPrice(item);
      const itemTotal = pricePerUnit * item.quantity;
      console.log(`${item.product.name}: ${item.quantity} x Q${pricePerUnit.toFixed(2)} = Q${itemTotal.toFixed(2)}`);
      total += itemTotal;
    }
    
    console.log(`Total del carrito: Q${total.toFixed(2)}`);
    return total;
  };

  const forceUpdatePrices = () => {
    console.log('Forzando actualización de precios...');
    // Refrescar el carrito para obtener los datos actualizados
    refreshCart();
  };

  const getMinQuantity = (item: typeof cartItems[0]) => {
    // Si el usuario tiene tipo de precio mayorista, verificar si hay un mínimo específico
    if (userPriceType === 'mayorista' && item.product.prices && Array.isArray(item.product.prices)) {
      const mayoristaPrice = item.product.prices.find(p => p.price_type === 'mayorista');
      if (mayoristaPrice && mayoristaPrice.min_quantity) {
        return mayoristaPrice.min_quantity;
      }
    }
    
    // Si hay precios definidos para el tipo de usuario actual
    if (userPriceType && item.product.prices && Array.isArray(item.product.prices)) {
      const userPrices = item.product.prices.filter(p => p.price_type === userPriceType);
      if (userPrices.length > 0) {
        // Encontrar el precio mínimo aplicable (el de menor cantidad mínima)
        const minQuantityPrice = userPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
        if (minQuantityPrice && minQuantityPrice.min_quantity) {
          return minQuantityPrice.min_quantity;
        }
      }
    }
    
    // Si no hay específicos, retornar 1 como cantidad mínima predeterminada
    return 1;
  };

  const updateItemQuantity = async (itemId: string, newQuantity: number, minQuantity: number) => {
    // Verificar que la nueva cantidad no sea menor que la cantidad mínima requerida
    if (newQuantity < minQuantity) {
      // Opcionalmente mostrar una alerta o mensaje
      console.log(`No se puede reducir por debajo de la cantidad mínima requerida: ${minQuantity}`);
      return;
    }
    
    try {
      console.log(`Actualizando cantidad del item ${itemId} a ${newQuantity}`);
      
      const { error } = await supabase
        .from('cart_items')
        .update({ 
          quantity: newQuantity,
          updated_at: new Date().toISOString()
        })
        .eq('id', itemId);

      if (error) throw error;
      
      // Refrescar el carrito para obtener los datos actualizados
      await refreshCart();
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          console.log('Abriendo carrito, items:', cartItems);
          setIsOpen(true);
        }}
        className="relative p-2 text-gray-600 hover:text-blue-600 transition-colors duration-200"
        aria-label="Abrir carrito de compras"
      >
        <CartIcon className="h-6 w-6" />
        {cartItems.length > 0 && (
          <span className="absolute -top-1 -right-1 bg-blue-600 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center font-medium shadow-sm">
            {cartItems.length}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-hidden flex items-end sm:items-center justify-center sm:justify-end">
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity" 
               onClick={() => setIsOpen(false)} />
          
          <div className="relative bg-white w-full sm:w-96 md:w-[450px] h-[85vh] sm:h-[90vh] sm:mr-4 sm:my-4 rounded-t-xl sm:rounded-xl shadow-2xl transform transition-all overflow-hidden flex flex-col">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b bg-gradient-to-r from-blue-500 to-blue-600 text-white">
              <div className="flex items-center gap-2">
                <CartIcon className="h-5 w-5" />
                <h2 className="text-lg font-semibold">Carrito de compras</h2>
              </div>
              <div className="flex items-center gap-2">
                <button 
                  onClick={forceUpdatePrices} 
                  className="p-1.5 text-white hover:bg-white/20 rounded-full transition-colors"
                  title="Actualizar precios"
                >
                  <RefreshCw className="h-5 w-5" />
                </button>
                <button 
                  onClick={() => setIsOpen(false)} 
                  className="p-1.5 text-white hover:bg-white/20 rounded-full transition-colors"
                  aria-label="Cerrar carrito"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Content */}
            {loading ? (
              <div className="flex-1 flex items-center justify-center p-8">
                <div className="flex flex-col items-center gap-3">
                  <Loader2 className="h-10 w-10 text-blue-500 animate-spin" />
                  <p className="text-gray-500">Cargando tu carrito...</p>
                </div>
              </div>
            ) : cartItems.length === 0 ? (
              <div className="flex-1 flex flex-col items-center justify-center p-8 text-center">
                <CartIcon className="h-16 w-16 text-gray-300 mb-4" />
                <h3 className="text-xl font-medium text-gray-700 mb-2">Tu carrito está vacío</h3>
                <p className="text-gray-500 mb-6">¡Agrega algunos productos para comenzar tu compra!</p>
                <button 
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/productos');
                  }}
                  className="px-5 py-2 bg-blue-600 text-white rounded-full font-medium hover:bg-blue-700 transition-colors"
                >
                  Ver productos
                </button>
              </div>
            ) : (
              <>
                {/* Cart items */}
                <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
                  <div className="space-y-3">
                    {cartItems.map((item) => {
                      if (!item || !item.product) {
                        return null;
                      }
                      
                      const itemPrice = getProductPrice(item);
                      const itemTotal = itemPrice * item.quantity;
                      
                      return (
                        <div
                          key={item.id}
                          className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 transition-shadow hover:shadow-md"
                        >
                          <div className="flex gap-3">
                            <div className="relative w-20 h-20 bg-gray-100 rounded-md overflow-hidden flex-shrink-0">
                              <img
                                src={item.product.main_image}
                                alt={item.product.name}
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src = 'https://via.placeholder.com/150?text=No+Image';
                                }}
                              />
                            </div>
                            
                            <div className="flex-1 min-w-0">
                              <div className="flex justify-between">
                                <h3 className="font-medium text-gray-800 truncate">{item.product.name}</h3>
                                <button
                                  onClick={() => removeFromCart(item.id)}
                                  className="ml-2 flex-shrink-0 p-1 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50 transition-colors"
                                  aria-label="Eliminar producto"
                                >
                                  <Trash className="h-4 w-4" />
                                </button>
                              </div>
                              
                              <div className="mt-1 flex flex-wrap items-baseline gap-x-2">
                                <span className="font-medium text-blue-600">
                                  Q{itemPrice.toFixed(2)}
                                </span>
                                {item.price_type && (
                                  <span className="text-xs text-gray-500">
                                    {item.price_type === 'mayorista' ? 'Precio mayorista' : `Lista: ${item.price_type}`}
                                  </span>
                                )}
                              </div>
                              
                              <div className="mt-2 flex items-center justify-between">
                                <div className="flex items-center border border-gray-200 rounded-lg overflow-hidden">
                                  <button 
                                    onClick={() => {
                                      const minQuantity = getMinQuantity(item);
                                      updateItemQuantity(item.id, Math.max(minQuantity, item.quantity - 1), minQuantity);
                                    }}
                                    className={`px-2 py-1 ${item.quantity <= getMinQuantity(item) ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-gray-50 hover:bg-gray-100 text-gray-600'} transition-colors`}
                                    disabled={item.quantity <= getMinQuantity(item)}
                                    aria-label="Disminuir cantidad"
                                  >
                                    <Minus className="h-3.5 w-3.5" />
                                  </button>
                                  <span className="w-10 text-center text-sm py-1">{item.quantity}</span>
                                  <button 
                                    onClick={() => updateItemQuantity(item.id, item.quantity + 1, getMinQuantity(item))}
                                    className="px-2 py-1 bg-gray-50 hover:bg-gray-100 text-gray-600 transition-colors"
                                    aria-label="Aumentar cantidad"
                                  >
                                    <Plus className="h-3.5 w-3.5" />
                                  </button>
                                </div>
                                
                                <div className="text-sm text-right">
                                  <span className="font-medium text-gray-900">
                                    Q{itemTotal.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Footer */}
                <div className="border-t bg-white p-4 space-y-4">
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Subtotal</span>
                      <span className="font-medium text-gray-900">
                        Q{getCartTotal().toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between items-center text-sm text-gray-500">
                      <span>Envío</span>
                      <span>Calculado en el checkout</span>
                    </div>
                  </div>
                  
                  <div className="pt-2">
                    <button
                      onClick={handleCheckout}
                      disabled={isSubmitting}
                      className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors disabled:opacity-70 disabled:cursor-not-allowed flex items-center justify-center gap-2 shadow-sm"
                    >
                      {isSubmitting ? (
                        <>
                          <Loader2 className="w-5 h-5 animate-spin" />
                          Procesando...
                        </>
                      ) : (
                        'Proceder al Checkout'
                      )}
                    </button>
                    
                    <button
                      onClick={() => setIsOpen(false)}
                      className="w-full mt-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg font-medium hover:bg-gray-200 transition-colors"
                    >
                      Seguir comprando
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}