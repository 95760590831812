import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import CollectionSection from './CollectionSection';

interface Collection {
  id: string;
  name: string;
  description: string;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  order: number;
  is_active: boolean;
}

export default function AllCollections() {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('product_collections')
          .select('id, name, description, type, order, is_active')
          .eq('is_active', true)
          .not('type', 'in', '("featured","new")')
          .order('order', { ascending: true });

        if (error) throw error;
        
        setCollections(data || []);
      } catch (err) {
        console.error('Error fetching collections:', err);
        setError(err instanceof Error ? err.message : 'Error al cargar las colecciones');
      } finally {
        setLoading(false);
      }
    };

    // Configurar la suscripción en tiempo real
    const subscription = supabase
      .channel('product_collections_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'product_collections'
        }, 
        () => {
          fetchCollections();
        }
      )
      .subscribe();

    fetchCollections();

    // Limpiar la suscripción cuando el componente se desmonte
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="space-y-8">
        {[1, 2].map((i) => (
          <div key={i} className="animate-pulse">
            <div className="h-10 bg-gray-200 rounded w-1/3 mb-4"></div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[1, 2, 3].map((j) => (
                <div key={j} className="bg-gray-100 rounded-xl overflow-hidden">
                  <div className="h-64 bg-gray-200"></div>
                  <div className="p-4 space-y-3">
                    <div className="h-5 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg">
        Error: {error}
      </div>
    );
  }

  if (!collections.length) {
    return null;
  }

  return (
    <div className="space-y-8">
      {collections.map((collection) => (
        <CollectionSection 
          key={collection.id} 
          collectionType={collection.type}
          collectionId={collection.id}
        />
      ))}
    </div>
  );
} 