import { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Link } from 'react-router-dom';

interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  parent_id: string | null;
  order: number;
  subcategories?: Category[];
}

export default function CategoryMenu() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .order('order', { ascending: true });

      if (error) throw error;

      // Organize categories into hierarchy
      const mainCategories = data.filter(cat => !cat.parent_id);
      const subCategories = data.filter(cat => cat.parent_id);

      const categoriesWithSubs = mainCategories.map(cat => ({
        ...cat,
        subcategories: subCategories.filter(sub => sub.parent_id === cat.id)
      }));

      setCategories(categoriesWithSubs);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar las categorías');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
        <div className="space-y-3">
          {[1, 2, 3].map(n => (
            <div key={n} className="h-6 bg-gray-200 rounded w-full"></div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-sm">
        {error}
      </div>
    );
  }

  return (
    <nav className="bg-white rounded-lg shadow-sm p-4">
      <h2 className="font-semibold text-lg mb-4">Categorías</h2>
      <div className="space-y-4">
        {categories.map(category => (
          <div key={category.id}>
            <Link
              to={`/category/${category.slug}`}
              className="flex items-center justify-between text-gray-700 hover:text-blue-600 font-medium"
            >
              <span>{category.name}</span>
              <ChevronRight className="w-4 h-4" />
            </Link>
            {category.subcategories?.length ? (
              <div className="ml-4 mt-2 space-y-2">
                {category.subcategories.map(sub => (
                  <Link
                    key={sub.id}
                    to={`/category/${category.slug}/${sub.slug}`}
                    className="block text-sm text-gray-600 hover:text-blue-600"
                  >
                    {sub.name}
                  </Link>
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </nav>
  );
}