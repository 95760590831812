import { useState, useEffect, useRef } from 'react';
import { 
  Plus, 
  Trash2, 
  Edit, 
  Link as LinkIcon, 
  Image as ImageIcon,
  ArrowUp,
  ArrowDown,
  Eye,
  X
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import './SlidersTab.css';

interface SliderImage {
  id: string;
  image: string;
  link: string;
  order: number;
  created_at: string;
}

export default function SlidersTab() {
  const [sliderImages, setSliderImages] = useState<SliderImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [editingImage, setEditingImage] = useState<SliderImage | null>(null);
  const [newImageLink, setNewImageLink] = useState('');
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [editImageFile, setEditImageFile] = useState<File | null>(null);
  const [editImagePreview, setEditImagePreview] = useState<string | null>(null);

  // Cargar imágenes del slider
  useEffect(() => {
    fetchSliderImages();
  }, []);

  const fetchSliderImages = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('slider_images')
        .select('*')
        .order('"order"', { ascending: true });

      if (error) throw error;
      setSliderImages(data || []);
    } catch (error) {
      console.error('Error fetching slider images:', error);
      alert('Error al cargar las imágenes del slider');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setSelectedFile(file);
    
    // Crear URL para previsualización
    const objectUrl = URL.createObjectURL(file);
    setPreviewUrl(objectUrl);
    
    // Limpiar URL al desmontar
    return () => URL.revokeObjectURL(objectUrl);
  };

  const handleEditFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setEditImageFile(file);
    
    // Crear URL para previsualización
    const objectUrl = URL.createObjectURL(file);
    setEditImagePreview(objectUrl);
    
    // Limpiar URL al desmontar
    return () => URL.revokeObjectURL(objectUrl);
  };

  // Función para convertir una imagen a formato WebP
  const convertToWebP = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!event.target?.result) {
          reject(new Error('Error al leer el archivo'));
          return;
        }

        const img = new Image();
        img.onload = () => {
          // Dimensiones objetivo para el slider
          const targetWidth = 1920;  // Aumentado para mejor calidad
          const targetHeight = 640;  // Aumentado para mejor calidad
          
          // Calcular dimensiones manteniendo la proporción
          let newWidth = targetWidth;
          let newHeight = targetHeight;
          
          // Crear canvas con las dimensiones objetivo
          const canvas = document.createElement('canvas');
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext('2d');
          
          if (!ctx) {
            reject(new Error('No se pudo crear el contexto del canvas'));
            return;
          }
          
          // Rellenar el fondo con negro (para evitar bordes transparentes)
          ctx.fillStyle = '#000000';
          ctx.fillRect(0, 0, targetWidth, targetHeight);
          
          // Calcular dimensiones y posición para centrar la imagen
          const imgRatio = img.width / img.height;
          const targetRatio = targetWidth / targetHeight;
          
          let drawWidth, drawHeight, xOffset, yOffset;
          
          if (imgRatio > targetRatio) {
            // La imagen es más ancha proporcionalmente
            drawHeight = targetHeight;
            drawWidth = drawHeight * imgRatio;
            xOffset = (targetWidth - drawWidth) / 2;
            yOffset = 0;
          } else {
            // La imagen es más alta proporcionalmente
            drawWidth = targetWidth;
            drawHeight = drawWidth / imgRatio;
            xOffset = 0;
            yOffset = (targetHeight - drawHeight) / 2;
          }
          
          // Dibujar la imagen centrada en el canvas
          ctx.drawImage(img, xOffset, yOffset, drawWidth, drawHeight);
          
          // Convertir a WebP con calidad 0.95 (95%) para mejor calidad
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Error al convertir a WebP'));
            }
          }, 'image/webp', 0.95);
        };
        
        img.onerror = () => {
          reject(new Error('Error al cargar la imagen'));
        };
        
        img.src = event.target.result as string;
      };
      
      reader.onerror = () => {
        reject(new Error('Error al leer el archivo'));
      };
      
      reader.readAsDataURL(file);
    });
  };

  const uploadImage = async () => {
    if (!selectedFile || !newImageLink) {
      alert('Por favor selecciona una imagen y proporciona un enlace');
      return;
    }

    try {
      setUploading(true);
      
      // Convertir la imagen a formato WebP
      const webpBlob = await convertToWebP(selectedFile);
      
      // Generar un nombre único para el archivo con extensión .webp
      const fileName = `${uuidv4()}.webp`;
      const filePath = `slider/${fileName}`;
      
      // Subir la imagen convertida a Supabase Storage
      const { error: uploadError } = await supabase.storage
        .from('public')
        .upload(filePath, webpBlob, {
          contentType: 'image/webp',
          cacheControl: '3600',
          upsert: false
        });
      
      if (uploadError) throw uploadError;
      
      // Obtener la URL pública de la imagen
      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl(filePath);
      
      // Determinar el orden para la nueva imagen
      const newOrder = sliderImages.length > 0 
        ? Math.max(...sliderImages.map(img => img.order)) + 1 
        : 1;
      
      // Guardar la referencia en la base de datos
      const { error: dbError } = await supabase
        .from('slider_images')
        .insert([
          { 
            image: publicUrl, 
            link: newImageLink,
            order: newOrder
          }
        ]);
      
      if (dbError) throw dbError;
      
      // Actualizar la lista de imágenes
      fetchSliderImages();
      
      // Limpiar el formulario
      setSelectedFile(null);
      setPreviewUrl(null);
      setNewImageLink('');
      
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error al subir la imagen');
    } finally {
      setUploading(false);
    }
  };

  const deleteImage = async (id: string) => {
    if (!confirm('¿Estás seguro de que deseas eliminar esta imagen?')) return;
    
    try {
      setLoading(true);
      
      // Obtener la imagen para extraer la URL
      const { data: imageData } = await supabase
        .from('slider_images')
        .select('image')
        .eq('id', id)
        .single();
      
      if (imageData?.image) {
        // Extraer el path del storage de la URL
        const urlParts = imageData.image.split('/');
        const fileName = urlParts[urlParts.length - 1];
        const filePath = `slider/${fileName}`;
        
        // Eliminar la imagen del storage
        await supabase.storage
          .from('public')
          .remove([filePath]);
      }
      
      // Eliminar el registro de la base de datos
      const { error } = await supabase
        .from('slider_images')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      // Actualizar la lista de imágenes
      fetchSliderImages();
      
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Error al eliminar la imagen');
    } finally {
      setLoading(false);
    }
  };

  const updateImageOrder = async (id: string, direction: 'up' | 'down') => {
    try {
      setLoading(true);
      
      // Encontrar la imagen actual y su índice
      const currentIndex = sliderImages.findIndex(img => img.id === id);
      if (currentIndex === -1) return;
      
      const currentImage = sliderImages[currentIndex];
      
      // Determinar el índice de la imagen con la que intercambiar
      const targetIndex = direction === 'up' 
        ? currentIndex - 1 
        : currentIndex + 1;
      
      // Verificar que el índice objetivo es válido
      if (targetIndex < 0 || targetIndex >= sliderImages.length) return;
      
      const targetImage = sliderImages[targetIndex];
      
      // Intercambiar órdenes
      const { error: error1 } = await supabase
        .from('slider_images')
        .update({ order: targetImage.order })
        .eq('id', currentImage.id);
      
      if (error1) throw error1;
      
      const { error: error2 } = await supabase
        .from('slider_images')
        .update({ order: currentImage.order })
        .eq('id', targetImage.id);
      
      if (error2) throw error2;
      
      // Actualizar la lista de imágenes
      fetchSliderImages();
      
    } catch (error) {
      console.error('Error updating image order:', error);
      alert('Error al actualizar el orden de las imágenes');
    } finally {
      setLoading(false);
    }
  };

  const startEditing = (image: SliderImage) => {
    setEditingImage(image);
    setNewImageLink(image.link);
  };

  const saveEditing = async () => {
    if (!editingImage) return;
    
    try {
      setLoading(true);
      
      let updatedFields: { link: string; image?: string } = { 
        link: newImageLink 
      };
      
      // Si hay una nueva imagen, procesarla
      if (editImageFile) {
        // Convertir la imagen a formato WebP
        const webpBlob = await convertToWebP(editImageFile);
        
        // Generar un nombre único para el archivo con extensión .webp
        const fileName = `${uuidv4()}.webp`;
        const filePath = `slider/${fileName}`;
        
        // Subir la imagen convertida a Supabase Storage
        const { error: uploadError } = await supabase.storage
          .from('public')
          .upload(filePath, webpBlob, {
            contentType: 'image/webp',
            cacheControl: '3600',
            upsert: false
          });
        
        if (uploadError) throw uploadError;
        
        // Obtener la URL pública de la imagen
        const { data: { publicUrl } } = supabase.storage
          .from('public')
          .getPublicUrl(filePath);
          
        // Agregar la URL de la imagen a los campos a actualizar
        updatedFields.image = publicUrl;
      }
      
      // Actualizar en la base de datos
      const { error } = await supabase
        .from('slider_images')
        .update(updatedFields)
        .eq('id', editingImage.id);
      
      if (error) throw error;
      
      // Actualizar la lista de imágenes
      fetchSliderImages();
      
      // Limpiar el estado de edición
      setEditingImage(null);
      setNewImageLink('');
      setEditImageFile(null);
      setEditImagePreview(null);
      
    } catch (error) {
      console.error('Error updating image:', error);
      alert('Error al actualizar la imagen');
    } finally {
      setLoading(false);
    }
  };

  const cancelEditing = () => {
    setEditingImage(null);
    setNewImageLink('');
    setEditImageFile(null);
    setEditImagePreview(null);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm">
      <div className="p-6 border-b">
        <h2 className="text-lg font-semibold text-gray-900">Gestión de Imágenes del Slider</h2>
        <p className="text-gray-500 mt-1">
          Administra las imágenes que se muestran en el slider principal de la página de inicio.
        </p>
      </div>

      {/* Formulario para subir nuevas imágenes */}
      <div className="p-6 border-b">
        <h3 className="text-md font-medium text-gray-900 mb-4">Agregar nueva imagen</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Imagen
            </label>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                {previewUrl ? (
                  <div className="relative w-full h-full">
                    <img 
                      src={previewUrl} 
                      alt="Preview" 
                      className="absolute inset-0 w-full h-full object-contain p-2"
                    />
                    <button 
                      onClick={() => {
                        setPreviewUrl(null);
                        setSelectedFile(null);
                      }}
                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                      type="button"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <ImageIcon className="w-10 h-10 text-gray-400 mb-3" />
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Haz clic para subir</span> o arrastra y suelta
                    </p>
                    <p className="text-xs text-gray-500">PNG, JPG o WEBP (Recomendado: 1920x640px)</p>
                  </div>
                )}
                <input 
                  type="file" 
                  className="hidden" 
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </label>
            </div>
            <div className="mt-2">
              <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-blue-700">
                      Las imágenes se redimensionarán automáticamente a <strong>1920x640 píxeles</strong>. Para mejores resultados, sube imágenes con esta proporción (3:1).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Enlace (URL)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LinkIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={newImageLink}
                  onChange={(e) => setNewImageLink(e.target.value)}
                  placeholder="https://ejemplo.com/pagina"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                URL a la que se redirigirá al hacer clic en la imagen
              </p>
            </div>
            
            <button
              onClick={uploadImage}
              disabled={uploading || !selectedFile || !newImageLink}
              className="w-full mt-4 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {uploading ? 'Subiendo...' : 'Subir imagen'}
            </button>
          </div>
        </div>
      </div>

      {/* Lista de imágenes existentes */}
      <div className="p-6">
        <h3 className="text-md font-medium text-gray-900 mb-4">Imágenes actuales</h3>
        
        {loading ? (
          <div className="text-center py-8">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mb-2"></div>
            <p className="text-gray-500">Cargando imágenes...</p>
          </div>
        ) : sliderImages.length === 0 ? (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <ImageIcon className="mx-auto h-12 w-12 text-gray-400 mb-3" />
            <h3 className="text-sm font-medium text-gray-900">No hay imágenes</h3>
            <p className="mt-1 text-sm text-gray-500">
              Comienza subiendo tu primera imagen para el slider.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Imagen
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Enlace
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Orden
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sliderImages.map((image, index) => (
                  <tr key={image.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="h-16 w-24 flex-shrink-0">
                          <img 
                            className="h-16 w-24 object-cover rounded" 
                            src={image.image} 
                            alt={`Slider ${index + 1}`} 
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingImage && editingImage.id === image.id && (
                        <div className="edit-form">
                          <div>
                            <label htmlFor="edit-image-link">Enlace:</label>
                            <input
                              id="edit-image-link"
                              type="text"
                              value={newImageLink}
                              onChange={(e) => setNewImageLink(e.target.value)}
                              placeholder="https://ejemplo.com/pagina"
                            />
                          </div>
                          <div>
                            <label htmlFor="edit-image-upload">Cambiar Imagen (opcional):</label>
                            <input
                              id="edit-image-upload"
                              type="file"
                              accept="image/*"
                              onChange={handleEditFileChange}
                            />
                            {editImagePreview && (
                              <div className="preview-container">
                                <img src={editImagePreview} alt="Preview" className="preview-image" />
                              </div>
                            )}
                            <div className="mt-2">
                              <div className="bg-blue-50 border-l-4 border-blue-400 p-3">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <svg className="h-4 w-4 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                  <div className="ml-2">
                                    <p className="text-xs text-blue-700">
                                      La imagen se redimensionará a 1920x640px.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edit-actions">
                            <button onClick={saveEditing}>Guardar</button>
                            <button onClick={cancelEditing}>Cancelar</button>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex items-center space-x-2">
                        <span>{image.order}</span>
                        <div className="flex flex-col">
                          <button
                            onClick={() => updateImageOrder(image.id, 'up')}
                            disabled={index === 0}
                            className="text-gray-400 hover:text-gray-600 disabled:opacity-30 disabled:cursor-not-allowed"
                          >
                            <ArrowUp className="h-4 w-4" />
                          </button>
                          <button
                            onClick={() => updateImageOrder(image.id, 'down')}
                            disabled={index === sliderImages.length - 1}
                            className="text-gray-400 hover:text-gray-600 disabled:opacity-30 disabled:cursor-not-allowed"
                          >
                            <ArrowDown className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => startEditing(image)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <Edit className="h-5 w-5" />
                        </button>
                        <a
                          href={image.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-600 hover:text-gray-900"
                        >
                          <Eye className="h-5 w-5" />
                        </a>
                        <button
                          onClick={() => deleteImage(image.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
} 