import React, { useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { Printer, RefreshCw } from 'lucide-react';

type BarcodeFormat = 
  | 'CODE128' 
  | 'CODE39' 
  | 'CODE128A' 
  | 'CODE128B' 
  | 'CODE128C' 
  | 'EAN13' 
  | 'EAN8' 
  | 'EAN5' 
  | 'EAN2' 
  | 'UPC' 
  | 'UPCE' 
  | 'ITF14' 
  | 'ITF' 
  | 'MSI' 
  | 'MSI10' 
  | 'MSI11' 
  | 'MSI1010' 
  | 'MSI1110' 
  | 'pharmacode' 
  | 'codabar' 
  | 'GenericBarcode';

interface BarcodeGeneratorProps {
  value: string;
  displayValue?: boolean;
  width?: number;
  height?: number;
  format?: BarcodeFormat;
  fontSize?: number;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  background?: string;
  lineColor?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  onBarcodeGenerated?: (barcode: string) => void;
  canGenerate?: boolean;
  productName?: string;
  publicPrice?: number;
}

const BarcodeGenerator: React.FC<BarcodeGeneratorProps> = ({
  value,
  displayValue = true,
  width = 2,
  height = 100,
  format = 'CODE128',
  fontSize = 16,
  textAlign = 'center',
  textPosition = 'bottom',
  textMargin = 2,
  background = '#ffffff',
  lineColor = '#000000',
  margin = 10,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  onBarcodeGenerated,
  canGenerate = false,
  productName = '',
  publicPrice,
}) => {
  const barcodeRef = useRef<HTMLDivElement>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handlePrint = () => {
    if (!barcodeRef.current) return;

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      alert('Por favor, permite las ventanas emergentes para imprimir el código de barras.');
      return;
    }

    const barcodeHTML = barcodeRef.current.innerHTML;
    
    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Código de Barras</title>
          <style>
            @page {
              size: auto;
              margin: 0mm;
            }
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              font-family: system-ui, -apple-system, sans-serif;
            }
            .barcode-container {
              text-align: center;
              padding: 10mm;
            }
            .price {
              font-size: 16px;
              font-weight: bold;
              color: #000;
              margin-top: 2mm;
            }
            @media print {
              body {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              .no-print {
                display: none;
              }
            }
          </style>
        </head>
        <body>
          <div class="barcode-container">
            ${barcodeHTML}
            ${typeof publicPrice === 'number' ? `<div class="price">Q${publicPrice.toFixed(2)}</div>` : ''}
          </div>
          <script>
            window.onload = () => {
              setTimeout(() => {
                window.print();
                window.close();
              }, 250);
            };
          </script>
        </body>
      </html>
    `);
    
    printWindow.document.close();
  };

  const generateBarcode = () => {
    setIsGenerating(true);
    
    // Generar un código de barras único basado en la fecha actual y un número aleatorio
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    
    let generatedBarcode = '';
    
    // Generar código según el formato
    if (format === 'EAN13') {
      // Para EAN13 necesitamos 12 dígitos (el 13º es el dígito de verificación que se calcula automáticamente)
      generatedBarcode = `${timestamp.toString().slice(-8)}${random}`.slice(0, 12);
    } else if (format === 'UPC') {
      // Para UPC necesitamos 11 dígitos (el 12º es el dígito de verificación)
      generatedBarcode = `${timestamp.toString().slice(-7)}${random}`.slice(0, 11);
    } else if (format === 'EAN8') {
      // Para EAN8 necesitamos 7 dígitos (el 8º es el dígito de verificación)
      generatedBarcode = `${timestamp.toString().slice(-3)}${random}`.slice(0, 7);
    } else {
      // Para otros formatos, usamos una combinación de timestamp y random
      generatedBarcode = `${timestamp}${random}`;
    }
    
    // Llamar al callback con el código generado
    if (onBarcodeGenerated) {
      onBarcodeGenerated(generatedBarcode);
    }
    
    setIsGenerating(false);
  };

  return (
    <div className="flex flex-col items-center">
      <div ref={barcodeRef}>
        <Barcode
          value={value || 'Sin código'}
          displayValue={displayValue}
          width={width}
          height={height}
          format={format}
          fontSize={fontSize}
          textAlign={textAlign}
          textPosition={textPosition}
          textMargin={textMargin}
          background={background}
          lineColor={lineColor}
          margin={margin}
          marginTop={marginTop}
          marginBottom={marginBottom}
          marginLeft={marginLeft}
          marginRight={marginRight}
        />
      </div>
      {(productName || publicPrice) && (
        <div className="text-center mt-2 no-print">
          {productName && <div className="font-semibold">{productName}</div>}
        </div>
      )}
      <div className="flex gap-2 mt-4 no-print">
        {canGenerate && (
          <button
            onClick={generateBarcode}
            disabled={isGenerating}
            className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <RefreshCw className={`w-5 h-5 ${isGenerating ? 'animate-spin' : ''}`} />
            Generar Código
          </button>
        )}
        {value && (
          <button
            onClick={handlePrint}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Printer className="w-5 h-5" />
            Imprimir Código
          </button>
        )}
      </div>
    </div>
  );
};

export default BarcodeGenerator; 