import { useState, useEffect } from 'react';
import { 
  ArrowDown, 
  ArrowUp, 
  DollarSign, 
  Package, 
  ShoppingCart, 
  Users, 
  ChevronRight, 
  Calendar, 
  RefreshCw,
  AlertTriangle,
  TrendingUp,
  Clock,
  Filter,
  X
} from 'lucide-react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import { supabase } from '../../lib/supabase';

// Colores para gráficos
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

// Tipo para estadísticas
type Stat = {
  name: string;
  value: string;
  change: string;
  trend: 'up' | 'down';
  icon: React.ElementType;
};

// Tipo para datos de ventas
type SalesData = {
  name: string;
  ventas: number;
  pedidos: number;
};

// Tipo para pedidos recientes
type RecentOrder = {
  id: string;
  customer: string;
  amount: string;
  status: string;
  date: string;
};

// Tipo para datos de categorías
type CategoryData = {
  name: string;
  value: number;
};

// Tipo para datos de departamentos
type DepartmentData = {
  name: string;
  value: number;
  label: string;
};

// Tipo para datos de ventas del POS
type POSSaleData = {
  id: string;
  amount: string;
  time: string;
  items: number;
  paymentMethod: string;
};

export default function DashboardTab() {
  const [timeRange, setTimeRange] = useState<'week' | 'month' | 'year'>('month');
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<Stat[]>([]);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [recentOrders, setRecentOrders] = useState<RecentOrder[]>([]);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [departmentData, setDepartmentData] = useState<DepartmentData[]>([]);
  const [posSales, setPosSales] = useState<POSSaleData[]>([]);
  const [posTotalSales, setPosTotalSales] = useState<number>(0);
  
  // Función para formatear números como moneda
  const formatCurrency = (amount: number) => {
    return `Q ${amount.toLocaleString('es-GT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  // Función para formatear fechas
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-GT', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };
  
  // Función para obtener estadísticas
  const fetchStats = async () => {
    try {
      // Obtener ingresos totales
      const { data: totalRevenue, error: revenueError } = await supabase
        .from('orders')
        .select('total_amount')
        .eq('payment_status', 'paid');
      
      if (revenueError) throw revenueError;
      
      const revenue = totalRevenue.reduce((sum, order) => sum + parseFloat(order.total_amount), 0);
      
      // Obtener pedidos nuevos (últimos 30 días)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      const { data: newOrders, error: ordersError } = await supabase
        .from('orders')
        .select('id')
        .gte('created_at', thirtyDaysAgo.toISOString());
      
      if (ordersError) throw ordersError;
      
      // Obtener productos activos
      const { data: activeProducts, error: productsError } = await supabase
        .from('products')
        .select('id')
        .eq('status', 'active');
      
      if (productsError) throw productsError;
      
      // Obtener usuarios nuevos (últimos 30 días)
      const { data: newUsers, error: usersError } = await supabase
        .from('profiles')
        .select('id')
        .gte('created_at', thirtyDaysAgo.toISOString());
      
      if (usersError) throw usersError;
      
      // Calcular cambios (simulados por ahora)
      // En una implementación real, compararías con períodos anteriores
      setStats([
        {
          name: 'Ingresos Totales',
          value: formatCurrency(revenue),
          change: '+12.5%',
          trend: 'up',
          icon: DollarSign,
        },
        {
          name: 'Pedidos Nuevos',
          value: newOrders.length.toString(),
          change: '+8.2%',
          trend: 'up',
          icon: ShoppingCart,
        },
        {
          name: 'Productos Activos',
          value: activeProducts.length.toString(),
          change: '-2.4%',
          trend: 'down',
          icon: Package,
        },
        {
          name: 'Usuarios Nuevos',
          value: newUsers.length.toString(),
          change: '+18.7%',
          trend: 'up',
          icon: Users,
        },
      ]);
    } catch (error) {
      console.error('Error al obtener estadísticas:', error);
    }
  };
  
  // Función para obtener datos de ventas
  const fetchSalesData = async () => {
    try {
      let timeFilter;
      let groupFormat;
      
      // Configurar filtro de tiempo y formato de agrupación según el rango seleccionado
      if (timeRange === 'week') {
        timeFilter = new Date();
        timeFilter.setDate(timeFilter.getDate() - 7);
        groupFormat = 'day';
      } else if (timeRange === 'month') {
        timeFilter = new Date();
        timeFilter.setMonth(timeFilter.getMonth() - 1);
        groupFormat = 'day';
      } else { // year
        timeFilter = new Date();
        timeFilter.setFullYear(timeFilter.getFullYear() - 1);
        groupFormat = 'month';
      }
      
      // Obtener datos de ventas
      const { data: orders, error } = await supabase
        .from('orders')
        .select('id, total_amount, created_at')
        .gte('created_at', timeFilter.toISOString())
        .order('created_at', { ascending: true });
      
      if (error) throw error;
      
      // Procesar datos para el gráfico
      const salesByPeriod = new Map();
      
      orders.forEach(order => {
        const date = new Date(order.created_at);
        let periodKey;
        
        if (groupFormat === 'day') {
          periodKey = date.toLocaleDateString('es-GT', { day: '2-digit', month: 'short' });
        } else {
          periodKey = date.toLocaleDateString('es-GT', { month: 'short' });
        }
        
        if (!salesByPeriod.has(periodKey)) {
          salesByPeriod.set(periodKey, { ventas: 0, pedidos: 0 });
        }
        
        const current = salesByPeriod.get(periodKey);
        current.ventas += parseFloat(order.total_amount);
        current.pedidos += 1;
        salesByPeriod.set(periodKey, current);
      });
      
      // Convertir a array para el gráfico
      const formattedData = Array.from(salesByPeriod.entries()).map(([name, data]) => ({
        name,
        ventas: Math.round(data.ventas * 100) / 100,
        pedidos: data.pedidos
      }));
      
      setSalesData(formattedData);
    } catch (error) {
      console.error('Error al obtener datos de ventas:', error);
    }
  };
  
  // Función para obtener pedidos recientes
  const fetchRecentOrders = async () => {
    try {
      // Simplificamos la consulta para evitar errores de sintaxis
      const { data: orders, error } = await supabase
        .from('orders')
        .select('id, total_amount, status, created_at, user_id')
        .order('created_at', { ascending: false })
        .limit(4);
      
      if (error) throw error;
      
      // Si necesitamos los nombres de los usuarios, hacemos una consulta adicional
      const userIds = orders.map(order => order.user_id).filter(Boolean);
      let userMap: Record<string, string> = {};
      
      if (userIds.length > 0) {
        const { data: profiles } = await supabase
          .from('profiles')
          .select('id, name')
          .in('id', userIds);
          
        if (profiles) {
          userMap = profiles.reduce<Record<string, string>>((acc, profile) => {
            acc[profile.id] = profile.name;
            return acc;
          }, {});
        }
      }
      
      const formattedOrders = orders.map(order => ({
        id: order.id,
        customer: order.user_id && userMap[order.user_id as string] ? userMap[order.user_id as string] : 'Cliente',
        amount: formatCurrency(parseFloat(order.total_amount)),
        status: order.status,
        date: formatDate(order.created_at)
      }));
      
      setRecentOrders(formattedOrders);
    } catch (error) {
      console.error('Error al obtener pedidos recientes:', error);
    }
  };
  
  // Función para obtener datos de categorías
  const fetchCategoryData = async () => {
    try {
      // Obtener productos por categoría
      const { data: products, error } = await supabase
        .from('products')
        .select(`
          id,
          categories:category_id (
            name
          )
        `)
        .eq('status', 'active');
      
      if (error) throw error;
      
      // Contar productos por categoría
      const categoryCounts = new Map();
      
      products.forEach(product => {
        const categoryName = product.categories ? (product.categories as any).name || 'Sin categoría' : 'Sin categoría';
        
        if (!categoryCounts.has(categoryName)) {
          categoryCounts.set(categoryName, 0);
        }
        
        categoryCounts.set(categoryName, categoryCounts.get(categoryName) + 1);
      });
      
      // Convertir a array para el gráfico
      const formattedData = Array.from(categoryCounts.entries())
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5); // Tomar las 5 categorías principales
      
      setCategoryData(formattedData);
    } catch (error) {
      console.error('Error al obtener datos de categorías:', error);
    }
  };
  
  // Función para obtener datos de departamentos
  const fetchDepartmentData = async () => {
    try {
      // Obtener pedidos por departamento
      const { data: orders, error } = await supabase
        .from('orders')
        .select(`
          id,
          shipping_address
        `)
        .not('shipping_address', 'is', null);
      
      if (error) throw error;
      
      // Contar pedidos por departamento
      const departmentCounts = new Map();
      
      orders.forEach(order => {
        if (order.shipping_address && order.shipping_address.department) {
          const department = order.shipping_address.department;
          
          if (!departmentCounts.has(department)) {
            departmentCounts.set(department, 0);
          }
          
          departmentCounts.set(department, departmentCounts.get(department) + 1);
        }
      });
      
      // Convertir a array para el gráfico
      const formattedData = Array.from(departmentCounts.entries())
        .map(([name, value]) => ({ name, value, label: 'pedidos' }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 6); // Tomar los 6 departamentos principales
      
      setDepartmentData(formattedData);
    } catch (error) {
      console.error('Error al obtener datos de departamentos:', error);
    }
  };
  
  // Función para obtener ventas del día del POS
  const fetchPOSSales = async () => {
    try {
      // Simplificamos la consulta para evitar errores de sintaxis
      const { data: posOrders, error } = await supabase
        .from('orders')
        .select('id, total_amount, created_at, payment_method, items')
        .eq('source', 'pos')
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      
      // Filtramos las órdenes del día actual en JavaScript en lugar de en la consulta
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      const todayOrders = posOrders.filter(order => {
        const orderDate = new Date(order.created_at);
        return orderDate >= today && orderDate < tomorrow;
      });
      
      // Calcular el total de ventas del POS del día
      const totalSales = todayOrders.reduce((sum, order) => sum + parseFloat(order.total_amount), 0);
      setPosTotalSales(totalSales);
      
      // Formatear datos para la tabla
      const formattedSales = todayOrders.map(order => {
        // Contar items en la orden
        const itemCount = order.items ? 
          (typeof order.items === 'string' ? 
            JSON.parse(order.items).length : 
            Array.isArray(order.items) ? 
              order.items.length : 
              Object.keys(order.items).length) : 
          0;
        
        return {
          id: order.id,
          amount: formatCurrency(parseFloat(order.total_amount)),
          time: new Date(order.created_at).toLocaleTimeString('es-GT', { 
            hour: '2-digit', 
            minute: '2-digit' 
          }),
          items: itemCount,
          paymentMethod: order.payment_method === 'card' ? 'Tarjeta' : 
                         order.payment_method === 'cash' ? 'Efectivo' : 
                         order.payment_method === 'twoCards' ? 'Dos Tarjetas' : 
                         'Otro'
        };
      });
      
      setPosSales(formattedSales);
    } catch (error) {
      console.error('Error al obtener ventas del POS:', error);
    }
  };
  
  // Función para refrescar todos los datos
  const refreshData = () => {
    setIsLoading(true);
    
    Promise.all([
      fetchStats(),
      fetchSalesData(),
      fetchRecentOrders(),
      fetchCategoryData(),
      fetchDepartmentData(),
      fetchPOSSales()
    ])
      .catch(error => {
        console.error('Error al actualizar datos:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  // Cargar datos iniciales
  useEffect(() => {
    refreshData();
  }, [timeRange]);

  return (
    <div className="space-y-6">
      {/* Header con filtros */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 bg-white p-4 rounded-xl shadow-sm">
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <div className="flex items-center gap-3">
          <div className="flex items-center bg-gray-100 rounded-lg p-1">
            <button 
              onClick={() => setTimeRange('week')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeRange === 'week' ? 'bg-white shadow-sm text-blue-600' : 'text-gray-600'
              }`}
            >
              Semana
            </button>
            <button 
              onClick={() => setTimeRange('month')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeRange === 'month' ? 'bg-white shadow-sm text-blue-600' : 'text-gray-600'
              }`}
            >
              Mes
            </button>
            <button 
              onClick={() => setTimeRange('year')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeRange === 'year' ? 'bg-white shadow-sm text-blue-600' : 'text-gray-600'
              }`}
            >
              Año
            </button>
          </div>
          <button 
            onClick={refreshData}
            className="flex items-center gap-2 px-3 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
            disabled={isLoading}
          >
            <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
            <span className="text-sm font-medium">Actualizar</span>
          </button>
          <button className="flex items-center gap-2 px-3 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors">
            <Filter className="w-4 h-4" />
            <span className="text-sm font-medium">Filtros</span>
          </button>
        </div>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <div key={stat.name} className="bg-white rounded-xl shadow-sm p-6 transition-transform hover:scale-[1.02]">
            <div className="flex items-center justify-between">
              <div className="p-2 bg-blue-50 rounded-lg">
                <stat.icon className="w-6 h-6 text-blue-600" />
              </div>
              <span
                className={`flex items-center text-sm font-medium ${
                  stat.trend === 'up' ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {stat.trend === 'up' ? (
                  <ArrowUp className="w-4 h-4" />
                ) : (
                  <ArrowDown className="w-4 h-4" />
                )}
                {stat.change}
              </span>
            </div>
            <h3 className="mt-4 text-2xl font-bold text-gray-900">{stat.value}</h3>
            <p className="text-sm text-gray-500">{stat.name}</p>
          </div>
        ))}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Ventas Chart */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Ventas y Pedidos</h3>
            <div className="flex items-center gap-2">
              <span className="inline-block w-3 h-3 bg-blue-500 rounded-full"></span>
              <span className="text-xs text-gray-500">Ventas</span>
              <span className="inline-block w-3 h-3 bg-blue-200 rounded-full ml-2"></span>
              <span className="text-xs text-gray-500">Pedidos</span>
            </div>
          </div>
          <div className="h-80">
            {salesData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip formatter={(value, name) => {
                    if (name === 'ventas') return [`Q ${value}`, 'Ventas'];
                    return [value, 'Pedidos'];
                  }} />
                  <Line yAxisId="left" type="monotone" dataKey="ventas" stroke="#3b82f6" strokeWidth={2} />
                  <Line yAxisId="right" type="monotone" dataKey="pedidos" stroke="#93c5fd" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div className="h-full flex items-center justify-center">
                <p className="text-gray-500">No hay datos disponibles</p>
              </div>
            )}
          </div>
        </div>

        {/* Ventas por Departamento */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Ventas por Departamento</h3>
            <button className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1">
              Ver Todos <ChevronRight className="w-4 h-4" />
            </button>
          </div>
          {departmentData.length > 0 ? (
            <div className="grid grid-cols-2 gap-4">
              {departmentData.map((item, index) => (
                <div key={item.name} className="bg-gray-50 rounded-lg p-4 transition-colors hover:bg-blue-50">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-full flex items-center justify-center" style={{ backgroundColor: COLORS[index % COLORS.length] + '20' }}>
                      <span className="text-sm font-bold" style={{ color: COLORS[index % COLORS.length] }}>{item.name.substring(0, 2)}</span>
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{item.name}</p>
                      <p className="text-sm text-gray-500">
                        {item.value} {item.label}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-64 flex items-center justify-center">
              <p className="text-gray-500">No hay datos disponibles</p>
            </div>
          )}
        </div>
      </div>

      {/* Ventas del día del POS */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-gray-900">Ventas del día (POS)</h3>
          <div className="flex items-center gap-3">
            <div className="px-4 py-2 bg-blue-50 rounded-lg">
              <span className="text-sm font-medium text-blue-600">Total: {formatCurrency(posTotalSales)}</span>
            </div>
            <button className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1">
              Ver Todas <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
        
        {posSales.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <th className="px-4 py-2">ID</th>s
                  <th className="px-4 py-2">Hora</th>
                  <th className="px-4 py-2">Monto</th>
                  <th className="px-4 py-2">Items</th>
                  <th className="px-4 py-2">Método de Pago</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {posSales.map((sale) => (
                  <tr key={sale.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 text-sm font-medium text-blue-600">#{sale.id.substring(0, 8)}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{sale.time}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{sale.amount}</td>
                    <td className="px-4 py-3 text-sm text-gray-900">{sale.items}</td>
                    <td className="px-4 py-3 text-sm">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          sale.paymentMethod === 'Efectivo'
                            ? 'bg-green-100 text-green-800'
                            : sale.paymentMethod === 'Tarjeta'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-purple-100 text-purple-800'
                        }`}
                      >
                        {sale.paymentMethod}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="h-64 flex items-center justify-center">
            <p className="text-gray-500">No hay ventas del POS hoy</p>
          </div>
        )}
      </div>

      {/* Pedidos Recientes y Categorías */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Pedidos Recientes */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Pedidos Recientes</h3>
            <button className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1">
              Ver Todos <ChevronRight className="w-4 h-4" />
            </button>
          </div>
          {recentOrders.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-4 py-2">ID</th>
                    <th className="px-4 py-2">Cliente</th>
                    <th className="px-4 py-2">Monto</th>
                    <th className="px-4 py-2">Estado</th>
                    <th className="px-4 py-2">Fecha</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {recentOrders.map((order) => (
                    <tr key={order.id} className="hover:bg-gray-50">
                      <td className="px-4 py-3 text-sm font-medium text-blue-600">#{order.id.substring(0, 8)}</td>
                      <td className="px-4 py-3 text-sm text-gray-900">{order.customer}</td>
                      <td className="px-4 py-3 text-sm text-gray-900">{order.amount}</td>
                      <td className="px-4 py-3 text-sm">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            order.status === 'delivered'
                              ? 'bg-green-100 text-green-800'
                              : order.status === 'shipped'
                              ? 'bg-blue-100 text-blue-800'
                              : order.status === 'processing'
                              ? 'bg-yellow-100 text-yellow-800'
                              : order.status === 'cancelled'
                              ? 'bg-red-100 text-red-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {order.status === 'delivered'
                            ? 'Entregado'
                            : order.status === 'shipped'
                            ? 'Enviado'
                            : order.status === 'processing'
                            ? 'Procesando'
                            : order.status === 'cancelled'
                            ? 'Cancelado'
                            : 'Pendiente'}
                        </span>
                      </td>
                      <td className="px-4 py-3 text-sm text-gray-500">{order.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="h-64 flex items-center justify-center">
              <p className="text-gray-500">No hay pedidos recientes</p>
            </div>
          )}
        </div>

        {/* Categorías */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Productos por Categoría</h3>
            <button className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1">
              Ver Detalles <ChevronRight className="w-4 h-4" />
            </button>
          </div>
          {categoryData.length > 0 ? (
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={categoryData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {categoryData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => [`${value} productos`, 'Cantidad']} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="h-64 flex items-center justify-center">
              <p className="text-gray-500">No hay datos de categorías disponibles</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}