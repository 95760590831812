import { useState, useEffect, useRef } from 'react';
import {
  Search,
  Plus,
  Edit,
  Trash,
  Filter,
  Upload,
  X,
  Tag,
  ArrowLeft,
  RefreshCw,
  Power,
  Barcode as BarcodeIcon,
  AlertTriangle,
  SlidersHorizontal,
  Pencil,
  Image as ImageIcon
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import BarcodeGenerator from '../BarcodeGenerator';

interface Category {
  id: string;
  name: string;
}

interface PriceList {
  price_type: 'contado' | 'credito' | 'mayorista' | 'publico';
  min_quantity: number;
  price: number;
}

interface ProductImage {
  id?: string;
  image_url: string;
  order: number;
}

interface ProductSpecification {
  name: string;
  value: string;
  order: number;
}

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number; // Mantenido por compatibilidad, pero ya no se usa activamente. Los precios se manejan con la lista de precios.
  main_image: string;
  additional_images?: ProductImage[];
  specifications?: ProductSpecification[];
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  category_id: string;
  category?: Category;
  prices?: PriceList[];
  created_at: string;
  code?: string;
  barcode?: string;
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: string;
  slug?: string;
}

export default function ProductsTab() {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [priceList, setPriceList] = useState<PriceList[]>([]);
  const [additionalImages, setAdditionalImages] = useState<ProductImage[]>([]);
  const [specifications, setSpecifications] = useState<ProductSpecification[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Nuevos estados para búsqueda avanzada
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchField, setSearchField] = useState<'all' | 'name' | 'code' | 'barcode'>('all');
  const [filterCategory, setFilterCategory] = useState<string>('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({
    name: '',
    code: '',
    barcode: ''
  });

  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [selectedProductForBarcode, setSelectedProductForBarcode] = useState<Product | null>(null);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [searchTerm, searchField, filterCategory, advancedSearch]); // Actualizar cuando cambien los filtros

  const fetchProducts = async () => {
    console.log('Iniciando carga de productos...');
    try {
      setLoading(true);
      setError(null);

      // Construir la consulta base
      let query = supabase
        .from('products')
        .select(`
          id,
          name,
          main_image,
          stock,
          code,
          barcode,
          description,
          base_price,
          min_order,
          status,
          category_id,
          created_at,
          category:categories(id, name),
          prices:product_prices(id, price_type, min_quantity, price),
          additional_images:product_images(id, image_url, order)
        `)
        .order('created_at', { ascending: false }); // Ordenar por fecha de creación, más nuevos primero

      // Aplicar filtros si existen
      if (searchTerm) {
        if (searchField === 'all') {
          query = query.or(`name.ilike.%${searchTerm}%,code.ilike.%${searchTerm}%,barcode.ilike.%${searchTerm}%`);
        } else if (searchField === 'name') {
          query = query.ilike('name', `%${searchTerm}%`);
        } else if (searchField === 'code') {
          query = query.ilike('code', `%${searchTerm}%`);
        } else if (searchField === 'barcode') {
          query = query.ilike('barcode', `%${searchTerm}%`);
        }
      }

      // Filtrar por categoría si se ha seleccionado una
      if (filterCategory) {
        query = query.eq('category_id', filterCategory);
      }

      // Aplicar filtros avanzados si existen
      if (advancedSearch.name) {
        query = query.ilike('name', `%${advancedSearch.name}%`);
      }
      if (advancedSearch.code) {
        query = query.ilike('code', `%${advancedSearch.code}%`);
      }
      if (advancedSearch.barcode) {
        query = query.ilike('barcode', `%${advancedSearch.barcode}%`);
      }

      const { data, error } = await query;

      if (error) throw error;

      // Formatear los datos para que sean compatibles con el tipo Product
      const formattedProducts: Product[] = data.map(item => ({
        id: item.id,
        name: item.name,
        description: item.description || '',
        base_price: item.base_price || 0,
        main_image: item.main_image || '',
        stock: item.stock || 0,
        min_order: item.min_order || 1,
        status: item.status as 'active' | 'inactive' | 'out_of_stock',
        category_id: item.category_id,
        category: item.category && Array.isArray(item.category) && item.category.length > 0
          ? item.category[0]
          : item.category as unknown as Category,
        created_at: item.created_at,
        code: item.code || undefined,
        barcode: item.barcode || undefined,
        prices: item.prices || []
      }));

      setProducts(formattedProducts);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar los productos');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('id, name')
        .is('parent_id', null)
        .order('name');

      if (error) throw error;
      setCategories(data || []);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const handleImageUpload = async (file: File, isMainImage: boolean = true) => {
    try {
      setUploadingImage(true);
      setError(null);

      // Validar el tipo de archivo y tamaño
      if (!file.type.startsWith('image/')) {
        throw new Error('El archivo debe ser una imagen');
      }

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('La imagen no debe superar los 5MB');
      }

      console.log('Procesando imagen:', {
        tipo: file.type,
        tamaño: file.size,
        nombre: file.name
      });

      // Convertir a WebP si no es ya WebP
      let fileToUpload = file;
      if (!file.type.includes('webp')) {
        try {
          fileToUpload = await convertToWebP(file);
          console.log('Imagen convertida a WebP');
        } catch (conversionError) {
          console.warn('No se pudo convertir a WebP, se usará el formato original:', conversionError);
          // Continuar con el archivo original si la conversión falla
        }
      }

      // Subir el archivo (convertido o original)
      const imageUrl = await uploadImageToStorage(fileToUpload);

      if (imageUrl) {
        if (isMainImage) {
          setPreviewImage(imageUrl);
        } else {
          setAdditionalImages([
            ...additionalImages,
            { image_url: imageUrl, order: additionalImages.length }
          ]);
        }
      }

      return imageUrl;
    } catch (err) {
      console.error('Error al procesar la imagen:', err);
      const errorMessage = err instanceof Error
        ? err.message
        : 'Error al procesar la imagen';
      setError(errorMessage);
      alert(errorMessage);
      return null;
    } finally {
      setUploadingImage(false);
    }
  };

  // Función segura para convertir a WebP
  const convertToWebP = async (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      // Crear un elemento de imagen para cargar el archivo
      const img = new Image();

      // Crear un objeto URL para la imagen
      const objectUrl = URL.createObjectURL(file);

      // Configurar el manejo de eventos
      img.onload = () => {
        // Crear un canvas para dibujar la imagen
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        // Dibujar la imagen en el canvas
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          URL.revokeObjectURL(objectUrl);
          reject(new Error('No se pudo crear el contexto del canvas'));
          return;
        }

        ctx.drawImage(img, 0, 0);

        // Convertir el canvas a un blob WebP
        canvas.toBlob(
          (blob) => {
            // Liberar el objeto URL
            URL.revokeObjectURL(objectUrl);

            if (!blob) {
              reject(new Error('No se pudo convertir la imagen a WebP'));
              return;
            }

            // Crear un nuevo nombre de archivo con extensión .webp
            const fileNameWithoutExt = file.name.split('.').slice(0, -1).join('.');
            const newFileName = `${fileNameWithoutExt}.webp`;

            // Crear un nuevo archivo con el blob WebP
            const webpFile = new File([blob], newFileName, { type: 'image/webp' });
            resolve(webpFile);
          },
          'image/webp',
          0.85  // Calidad de la imagen (0-1)
        );
      };

      img.onerror = () => {
        // Liberar el objeto URL en caso de error
        URL.revokeObjectURL(objectUrl);
        reject(new Error('No se pudo cargar la imagen para la conversión'));
      };

      // Iniciar la carga de la imagen
      img.src = objectUrl;
    });
  };

  const uploadImageToStorage = async (file: File) => {
    // Generar un nombre de archivo único, siempre con extensión .webp si es posible
    const fileExt = file.type.includes('webp') ? 'webp' : (file.name.split('.').pop()?.toLowerCase() || 'jpg');
    const fileName = `products/${Date.now()}-${Math.random().toString(36).substring(7)}.${fileExt}`;

    try {
      console.log('Iniciando carga a Supabase Storage:', fileName);

      // Intentar la carga hasta 3 veces
      let attempt = 0;
      let error = null;
      let data = null;

      while (attempt < 3) {
        attempt++;
        console.log(`Intento ${attempt} de carga a Supabase`);

        try {
          const result = await supabase.storage
            .from('public')
            .upload(fileName, file, {
              cacheControl: '3600',
              upsert: false
            });

          if (result.error) {
            console.error(`Error en intento ${attempt}:`, result.error);
            error = result.error;
            // Esperar antes del siguiente intento
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
          } else {
            data = result.data;
            error = null;
            break; // Salir del bucle si la carga fue exitosa
          }
        } catch (e) {
          console.error(`Excepción en intento ${attempt}:`, e);
          error = e;
          // Esperar antes del siguiente intento
          await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
      }

      if (error) throw error;
      if (!data) throw new Error('No se pudo cargar la imagen después de varios intentos');

      console.log('Imagen cargada exitosamente, obteniendo URL pública');

      const { data: { publicUrl } } = supabase.storage
        .from('public')
        .getPublicUrl(fileName);

      console.log('URL pública obtenida:', publicUrl);

      return publicUrl;
    } catch (err) {
      console.error('Error al subir la imagen a Supabase:', err);

      // Mostrar mensaje de error más específico según el tipo de error
      if (err instanceof Error) {
        if (err.message.includes('storage') || err.message.includes('bucket')) {
          throw new Error('Error de acceso al almacenamiento. Verifica los permisos de Supabase.');
        } else if (err.message.includes('network') || err.message.includes('connection')) {
          throw new Error('Error de conexión. Verifica tu conexión a internet.');
        } else {
          throw new Error(`Error al subir la imagen: ${err.message}`);
        }
      }

      throw new Error('Error desconocido al subir la imagen');
    }
  };

  const handleAddSpecification = () => {
    setSpecifications([
      ...specifications,
      { name: '', value: '', order: specifications.length }
    ]);
  };

  const handleUpdateSpecification = (index: number, field: keyof ProductSpecification, value: string) => {
    const newSpecifications = [...specifications];
    newSpecifications[index] = { ...newSpecifications[index], [field]: value };
    setSpecifications(newSpecifications);
  };

  const handleRemoveSpecification = (index: number) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };

  const handleAddProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      // Obtener el código del producto del formulario
      let productCode = formData.get('code') as string || null;

      // Crear el producto
      const newProduct = {
        name: formData.get('name') as string,
        description: formData.get('description') as string,
        base_price: 0,
        main_image: previewImage,
        stock: parseInt(formData.get('stock') as string) || 0,
        min_order: parseInt(formData.get('min_order') as string) || 1,
        category_id: formData.get('category_id') as string,
        status: 'active' as const,
        code: productCode,
        barcode: formData.get('barcode') as string || null,
        meta_keywords: formData.get('meta_keywords') as string || null,
        slug: formData.get('slug') as string || null
      };

      if (!newProduct.main_image) {
        throw new Error('Debes subir una imagen principal para el producto');
      }

      const { data: product, error: productError } = await supabase
        .from('products')
        .insert([newProduct])
        .select()
        .single();

      if (productError) {
        if (productError.code === '23505') {
          if (productError.message.includes('barcode')) {
            throw new Error('El código de barras ya está en uso por otro producto.');
          } else if (productError.message.includes('code')) {
            throw new Error('El código de producto ya está en uso. Por favor, utiliza un código diferente.');
          }
        }
        throw productError;
      }

      // Insertar imágenes adicionales si existen
      if (additionalImages.length > 0) {
        const { error: imagesError } = await supabase
          .from('product_images')
          .insert(
            additionalImages.map(img => ({
              product_id: product.id,
              image_url: img.image_url,
              order: img.order
            }))
          );

        if (imagesError) throw imagesError;
      }

      // Insertar especificaciones si existen
      if (specifications.length > 0) {
        const { error: specsError } = await supabase
          .from('product_specifications')
          .insert(
            specifications.map(spec => ({
              product_id: product.id,
              name: spec.name,
              value: spec.value,
              order: spec.order
            }))
          );

        if (specsError) throw specsError;
      }

      // Insertar lista de precios si existe
      if (priceList.length > 0) {
        // Validar y corregir los valores de min_quantity antes de insertar
        const validatedPriceList = priceList.map(price => ({
          product_id: product.id,
          price_type: price.price_type,
          min_quantity: Math.max(1, price.min_quantity), // Asegurar que min_quantity sea al menos 1 para todos los tipos
          price: price.price
        }));

        const { error: pricesError } = await supabase
          .from('product_prices')
          .insert(validatedPriceList);

        if (pricesError) throw pricesError;
      }

      // Limpiar todos los estados después de crear el producto exitosamente
      setShowAddForm(false);
      setPreviewImage(null);
      setPriceList([]);
      setSpecifications([]);
      setAdditionalImages([]); // Limpiar las imágenes adicionales
      setSelectedCategory(''); // Limpiar la categoría seleccionada
      fetchProducts();
      
      // Mostrar mensaje de éxito
      setError(null);
      setSuccess(`Producto "${newProduct.name}" añadido correctamente`);
      
      // Ocultar el mensaje de éxito después de 5 segundos
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (err) {
      console.error('Error adding product:', err);
      if (err instanceof Error) {
        if (err.message.includes('barcode') && err.message.includes('unique')) {
          setError('Error: El código de barras ya existe. Por favor, ejecuta la migración SQL para permitir códigos de barras duplicados.');
        } else {
          setError(err.message);
        }
      } else {
        setError('Error al añadir el producto');
      }
    }
  };

  const handleAddPriceLevel = (price_type: 'contado' | 'credito' | 'mayorista' | 'publico') => {
    // Para todos los tipos de precio, establecer cantidad mínima en al menos 1
    // Para precio público, siempre es 1
    // Para otros tipos, establecemos un valor mínimo de 2 (ya que 1 sería el precio unitario)
    const min_quantity = price_type === 'publico' ? 1 : 2;
    setPriceList([...priceList, { price_type, min_quantity, price: 0 }]);
  };

  const handleUpdatePriceLevel = (index: number, field: keyof PriceList, value: any) => {
    const newPriceList = [...priceList];
    // Validar min_quantity según el tipo de precio
    if (field === 'min_quantity') {
      if (newPriceList[index].price_type === 'publico') {
        value = 1; // Siempre 1 para precio público
      } else {
        value = Math.max(1, value); // Al menos 1 para todos los tipos de precio
      }
    }
    newPriceList[index] = { ...newPriceList[index], [field]: value };
    setPriceList(newPriceList);
  };

  const handleRemovePriceLevel = (index: number) => {
    setPriceList(priceList.filter((_, i) => i !== index));
  };

  const handleDeleteProduct = async (productId: string) => {
    if (!confirm('¿Estás seguro de que quieres eliminar este producto?')) return;

    try {
      const { error } = await supabase
        .from('products')
        .delete()
        .eq('id', productId);

      if (error) throw error;

      fetchProducts();
    } catch (err) {
      console.error('Error deleting product:', err);
      setError(err instanceof Error ? err.message : 'Error al eliminar el producto');
    }
  };

  const handleToggleProductStatus = async (productId: string, currentStatus: 'active' | 'inactive' | 'out_of_stock') => {
    // Determinar el nuevo estado (si está activo, desactivarlo, y viceversa)
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    try {
      setLoading(true);

      const { error } = await supabase
        .from('products')
        .update({ status: newStatus })
        .eq('id', productId);

      if (error) throw error;

      // Actualizar la lista de productos
      fetchProducts();

      // Mostrar mensaje de éxito
      setError(null);
      setSuccess(`Producto ${newStatus === 'active' ? 'activado' : 'desactivado'} correctamente`);
      
      // Ocultar el mensaje de éxito después de 5 segundos
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (err) {
      console.error('Error toggling product status:', err);
      setError(err instanceof Error ? err.message : `Error al ${newStatus === 'active' ? 'activar' : 'desactivar'} el producto`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = async (product: Product) => {
    setSelectedProduct(product);
    setPreviewImage(product.main_image);
    setSelectedCategory(product.category_id);

    // Cargar imágenes adicionales
    const { data: images } = await supabase
      .from('product_images')
      .select('*')
      .eq('product_id', product.id)
      .order('order');

    setAdditionalImages(images || []);

    // Cargar especificaciones
    const { data: specs } = await supabase
      .from('product_specifications')
      .select('*')
      .eq('product_id', product.id)
      .order('order');

    setSpecifications(specs || []);

    // Cargar precios
    const { data: prices } = await supabase
      .from('product_prices')
      .select('*')
      .eq('product_id', product.id)
      .order('min_quantity');

    setPriceList(prices || []);

    setShowAddForm(true);
  };

  const handleEditProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      if (!selectedProduct) return;

      // Obtener el código del producto del formulario
      let productCode = formData.get('code') as string || null;

      // Preparar datos actualizados - Remover additional_images del objeto principal
      const updatedProduct = {
        name: formData.get('name') as string,
        description: formData.get('description') as string,
        base_price: 0,
        main_image: previewImage || selectedProduct.main_image,
        stock: parseInt(formData.get('stock') as string) || 0,
        min_order: parseInt(formData.get('min_order') as string) || 1,
        category_id: formData.get('category_id') as string,
        code: productCode,
        barcode: formData.get('barcode') as string || null,
        meta_keywords: formData.get('meta_keywords') as string || null,
        slug: formData.get('slug') as string || null,
        meta_title: formData.get('meta_title') as string || null,
        meta_description: formData.get('meta_description') as string || null
      };

      // Actualizar producto
      const { error: productError } = await supabase
        .from('products')
        .update(updatedProduct)
        .eq('id', selectedProduct.id);

      if (productError) {
        if (productError.code === '23505') {
          if (productError.message.includes('barcode')) {
            throw new Error('El código de barras ya está en uso por otro producto.');
          } else if (productError.message.includes('code')) {
            throw new Error('El código de producto ya está en uso. Por favor, utiliza un código diferente.');
          }
        }
        throw productError;
      }

      // Actualizar imágenes adicionales
      // Primero eliminar todas las imágenes existentes
      await supabase
        .from('product_images')
        .delete()
        .eq('product_id', selectedProduct.id);

      // Luego insertar las nuevas imágenes si hay alguna
      if (additionalImages.length > 0) {
        const { error: imagesError } = await supabase
          .from('product_images')
          .insert(
            additionalImages.map(img => ({
              product_id: selectedProduct.id,
              image_url: img.image_url,
              order: img.order
            }))
          );

        if (imagesError) {
          console.error('Error al actualizar imágenes adicionales:', imagesError);
          throw new Error('Error al actualizar las imágenes adicionales');
        }
      }

      // Actualizar especificaciones
      if (specifications.length > 0) {
        // Eliminar especificaciones existentes
        await supabase
          .from('product_specifications')
          .delete()
          .eq('product_id', selectedProduct.id);

        // Insertar nuevas especificaciones
        const { error: specsError } = await supabase
          .from('product_specifications')
          .insert(
            specifications.map(spec => ({
              product_id: selectedProduct.id,
              name: spec.name,
              value: spec.value,
              order: spec.order
            }))
          );

        if (specsError) throw specsError;
      }

      // Actualizar lista de precios
      if (priceList.length > 0) {
        // Eliminar precios existentes
        await supabase
          .from('product_prices')
          .delete()
          .eq('product_id', selectedProduct.id);

        // Validar y corregir los valores de min_quantity antes de insertar
        const validatedPriceList = priceList.map(price => ({
          product_id: selectedProduct.id,
          price_type: price.price_type,
          min_quantity: Math.max(1, price.min_quantity), // Asegurar que min_quantity sea al menos 1 para todos los tipos
          price: price.price
        }));

        // Insertar nuevos precios
        const { error: pricesError } = await supabase
          .from('product_prices')
          .insert(validatedPriceList);

        if (pricesError) throw pricesError;
      }

      setShowAddForm(false);
      setSelectedProduct(null);
      setPreviewImage(null);
      setPriceList([]);
      setSpecifications([]);
      fetchProducts();
      
      // Mostrar mensaje de éxito
      setError(null);
      setSuccess(`Producto "${updatedProduct.name}" actualizado correctamente`);
      
      // Ocultar el mensaje de éxito después de 5 segundos
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (err) {
      console.error('Error updating product:', err);
      if (err instanceof Error) {
        if (err.message.includes('barcode') && err.message.includes('unique')) {
          setError('Error: El código de barras ya existe. Por favor, ejecuta la migración SQL para permitir códigos de barras duplicados.');
        } else {
          setError(err.message);
        }
      } else {
        setError('Error al actualizar el producto');
      }
    }
  };

  const handleBarcodeClick = (product: Product) => {
    setSelectedProductForBarcode(product);
    setShowBarcodeModal(true);
  };

  const handleGenerateBarcode = (barcode: string) => {
    if (!selectedProductForBarcode) return;

    // Actualizar el código de barras del producto
    supabase
      .from('products')
      .update({ barcode })
      .eq('id', selectedProductForBarcode.id)
      .then(({ error }) => {
        if (error) {
          console.error('Error al actualizar el código de barras:', error);
          setError('Error al actualizar el código de barras');
        } else {
          // Actualizar el producto seleccionado con el nuevo código de barras
          setSelectedProductForBarcode({
            ...selectedProductForBarcode,
            barcode
          });

          // Actualizar la lista de productos
          fetchProducts();
        }
      });
  };

  const handleAdvancedSearchChange = (field: keyof typeof advancedSearch, value: string) => {
    setAdvancedSearch(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const clearFilters = () => {
    setSearchTerm('');
    setSearchField('all');
    setFilterCategory('');
    setAdvancedSearch({
      name: '',
      code: '',
      barcode: ''
    });
  };

  return (
    <div className="space-y-6 prevent-overflow">
      {!showAddForm ? (
        <>
          {/* Actions */}
          <div className="bg-white shadow rounded-lg p-3 sm:p-4 overflow-hidden">
            <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-between gap-2 mb-3">
              <div className="flex items-center gap-2">
                <h1 className="text-lg font-semibold text-gray-900">
                  Productos <span className="text-gray-500 font-normal">({products.length})</span>
                </h1>
              </div>
              <div className="flex gap-1 flex-row">
                <select
                  value={filterCategory}
                  onChange={(e) => setFilterCategory(e.target.value)}
                  className="px-2 py-1.5 text-xs border rounded-lg w-full xs:w-auto"
                >
                  <option value="">Categorías</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => setShowAddForm(true)}
                  className="flex items-center justify-center gap-1 px-2 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-xs font-medium"
                >
                  <Plus className="w-3.5 h-3.5" />
                  <span>Nuevo</span>
                </button>
              </div>
            </div>

            {/* Barra de búsqueda simplificada para móvil */}
            <div className="mb-4">
              <div className="flex gap-1 mb-2">
                <div className="relative flex-1">
                  <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-9 pr-3 py-1.5 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 text-xs sm:text-sm"
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                  {searchTerm && (
                    <button
                      onClick={() => setSearchTerm('')}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  )}
                </div>
                <select
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value as 'all' | 'name' | 'code' | 'barcode')}
                  className="px-2 py-1.5 border rounded-lg text-xs w-[80px] sm:w-auto"
                >
                  <option value="all">Todo</option>
                  <option value="name">Nombre</option>
                  <option value="code">Código</option>
                  <option value="barcode">Barcode</option>
                </select>
              </div>
              
              <div className="flex items-center justify-between text-xs">
                <button
                  onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  className="text-blue-600 hover:text-blue-800 flex items-center px-1.5 py-0.5 rounded hover:bg-blue-50"
                >
                  {showAdvancedSearch ? 'Ocultar filtros' : 'Filtros avanzados'}
                  <SlidersHorizontal className="ml-1 w-3 h-3" />
                </button>
                
                {(searchTerm || filterCategory || advancedSearch.name || advancedSearch.code || advancedSearch.barcode) && (
                  <button
                    onClick={clearFilters}
                    className="text-gray-600 hover:text-gray-800 flex items-center px-1.5 py-0.5 rounded hover:bg-gray-100"
                  >
                    Limpiar
                    <X className="ml-1 w-3 h-3" />
                  </button>
                )}
              </div>
            </div>
            
            {/* Panel de búsqueda avanzada */}
            {showAdvancedSearch && (
              <div className="mb-3 bg-gray-50 p-2 sm:p-3 rounded-lg">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                  <div>
                    <label className="block text-[10px] sm:text-xs text-gray-600 mb-1">Nombre</label>
                    <input
                      type="text"
                      value={advancedSearch.name}
                      onChange={(e) => handleAdvancedSearchChange('name', e.target.value)}
                      className="w-full px-2 py-1 text-xs border rounded-lg"
                      placeholder="Buscar por nombre"
                    />
                  </div>
                  <div>
                    <label className="block text-[10px] sm:text-xs text-gray-600 mb-1">Código</label>
                    <input
                      type="text"
                      value={advancedSearch.code}
                      onChange={(e) => handleAdvancedSearchChange('code', e.target.value)}
                      className="w-full px-2 py-1 text-xs border rounded-lg"
                      placeholder="Buscar por código"
                    />
                  </div>
                  <div>
                    <label className="block text-[10px] sm:text-xs text-gray-600 mb-1">Código de barras</label>
                    <input
                      type="text"
                      value={advancedSearch.barcode}
                      onChange={(e) => handleAdvancedSearchChange('barcode', e.target.value)}
                      className="w-full px-2 py-1 text-xs border rounded-lg"
                      placeholder="Buscar por código de barras"
                    />
                  </div>
                </div>
              </div>
            )}

            {error && (
              <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-50 border border-green-200 rounded-lg p-4 text-green-600 flex justify-between items-center">
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  {success}
                </div>
                <button 
                  onClick={() => setSuccess(null)}
                  className="text-green-600 hover:text-green-800"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            )}

            {/* Products List */}
            {!loading && products.length > 0 && (
              <div className="bg-white shadow rounded-lg overflow-hidden">
                {/* Vista móvil y tablet */}
                <div className="block lg:hidden">
                  <div className="divide-y divide-gray-200 prevent-overflow">
                    {products.map((product) => (
                      <div key={product.id} className="p-3 hover:bg-gray-50 border-b border-gray-100">
                        <div className="flex items-start gap-2">
                          <img
                            src={product.main_image}
                            alt={product.name}
                            className="h-14 w-14 rounded-lg object-cover flex-shrink-0"
                          />
                          <div className="flex-1 min-w-0">
                            <div className="flex justify-between gap-1">
                              <h3 className="text-xs font-medium text-gray-900 truncate max-w-[80%]">
                                {product.name}
                              </h3>
                              <span className={`inline-flex items-center px-1 py-0.5 rounded-full text-[10px] font-medium
                                ${product.status === 'active'
                                  ? 'bg-green-100 text-green-800'
                                  : product.status === 'out_of_stock'
                                    ? 'bg-red-100 text-red-800'
                                    : 'bg-gray-100 text-gray-800'
                                }`}
                              >
                                {product.status === 'active'
                                  ? 'Activo'
                                  : product.status === 'out_of_stock'
                                    ? 'Sin Stock'
                                    : 'Inactivo'}
                              </span>
                            </div>
                            
                            <div className="mt-1 flex flex-wrap gap-1 text-[10px]">
                              {product.prices && product.prices.length > 0 ? (
                                <>
                                  {product.prices.some(p => p.price_type === 'publico') && (
                                    <span className="inline-block bg-red-100 text-red-800 px-1 py-0.5 rounded-full whitespace-nowrap">
                                      Q{product.prices.find(p => p.price_type === 'publico')?.price.toFixed(2)}
                                    </span>
                                  )}
                                  {product.prices.some(p => p.price_type === 'contado') && (
                                    <span className="inline-block bg-green-100 text-green-800 px-1 py-0.5 rounded-full whitespace-nowrap">
                                      Q{product.prices.find(p => p.price_type === 'contado')?.price.toFixed(2)}
                                    </span>
                                  )}
                                  {product.prices.some(p => p.price_type === 'credito') && (
                                    <span className="inline-block bg-blue-100 text-blue-800 px-1 py-0.5 rounded-full whitespace-nowrap">
                                      Q{product.prices.find(p => p.price_type === 'credito')?.price.toFixed(2)}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span className="text-gray-500">Sin precios</span>
                              )}
                            </div>
                            
                            <div className="mt-1 text-[10px]">
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <div className="truncate">
                                    <span className="text-gray-500">Código:</span> {product.code || '-'}
                                  </div>
                                  <div className="truncate">
                                    <span className="text-gray-500">Stock:</span> <span className="font-medium">{product.stock}</span>
                                  </div>
                                </div>
                                <div className="truncate">
                                  <span className="text-gray-500">Categoría:</span> {product.category?.name || '-'}
                                </div>
                              </div>
                            </div>
                            
                            {/* Acciones del producto en móvil */}
                            <div className="mt-1.5 flex justify-end items-center">
                              <div className="flex space-x-3">
                                <button
                                  onClick={() => handleEditClick(product)}
                                  className="text-blue-600" 
                                  aria-label="Editar producto"
                                >
                                  <Edit className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => handleToggleProductStatus(product.id, product.status)}
                                  className={`${product.status === 'active' ? 'text-green-600' : 'text-gray-400'}`}
                                  aria-label={product.status === 'active' ? 'Desactivar producto' : 'Activar producto'}
                                >
                                  <Power className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => handleBarcodeClick(product)}
                                  className="text-purple-600"
                                  aria-label="Código de barras"
                                >
                                  <BarcodeIcon className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Vista desktop */}
                <div className="hidden lg:block overflow-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                          Producto
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
                          Códigos
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
                          Categoría
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                          Precios
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Estado
                        </th>
                        <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-28">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {products.map((product) => (
                        <tr key={product.id} className="hover:bg-gray-50">
                          <td className="px-4 py-3">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  src={product.main_image}
                                  alt={product.name}
                                  className="h-10 w-10 rounded-lg object-cover"
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {product.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                  Stock: {product.stock}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="text-sm text-gray-900">
                              <div>Código: {product.code || '-'}</div>
                              <div className="text-xs text-gray-500">Barcode: {product.barcode || '-'}</div>
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="text-sm text-gray-900">
                              {product.category?.name}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            {product.prices && product.prices.length > 0 ? (
                              <div className="flex flex-col gap-1">
                                {product.prices.some(p => p.price_type === 'publico') && (
                                  <div className="flex items-center">
                                    <span className="text-xs bg-red-100 text-red-800 px-2 py-0.5 rounded-full mr-1">
                                      Q{product.prices.find(p => p.price_type === 'publico')?.price.toFixed(2)}
                                    </span>
                                  </div>
                                )}
                                {product.prices.some(p => p.price_type === 'contado') && (
                                  <span className="text-xs bg-green-100 text-green-800 px-2 py-0.5 rounded-full">
                                    Q{product.prices.find(p => p.price_type === 'contado')?.price.toFixed(2)}
                                  </span>
                                )}
                                {product.prices.some(p => p.price_type === 'credito') && (
                                  <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full">
                                    Q{product.prices.find(p => p.price_type === 'credito')?.price.toFixed(2)}
                                  </span>
                                )}
                                {product.prices.some(p => p.price_type === 'mayorista') && (
                                  <span className="text-xs bg-purple-100 text-purple-800 px-2 py-0.5 rounded-full">
                                    Q{product.prices.find(p => p.price_type === 'mayorista')?.price.toFixed(2)}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <span className="text-xs text-gray-500">Sin precios</span>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <span className={`
                              inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                              ${product.status === 'active'
                                ? 'bg-green-100 text-green-800'
                                : product.status === 'out_of_stock'
                                  ? 'bg-red-100 text-red-800'
                                  : 'bg-gray-100 text-gray-800'
                              }
                            `}>
                              {
                                product.status === 'active'
                                  ? 'Activo'
                                  : product.status === 'out_of_stock'
                                    ? 'Sin Stock'
                                    : 'Inactivo'
                              }
                            </span>
                          </td>
                          <td className="px-4 py-3 text-right">
                            <div className="flex items-center justify-end space-x-2">
                              <button
                                onClick={() => handleEditClick(product)}
                                className="text-blue-600 hover:text-blue-800"
                                title="Editar producto"
                              >
                                <Edit className="w-5 h-5" />
                              </button>
                              <button
                                onClick={() => handleToggleProductStatus(product.id, product.status)}
                                className={`${product.status === 'active' ? 'text-green-600 hover:text-green-800' : 'text-gray-400 hover:bg-gray-50'}`}
                                title={product.status === 'active' ? 'Desactivar producto' : 'Activar producto'}
                              >
                                <Power className="w-5 h-5" />
                              </button>
                              <button
                                onClick={() => handleDeleteProduct(product.id)}
                                className="text-red-600 hover:text-red-800"
                                title="Eliminar producto"
                              >
                                <Trash className="w-5 h-5" />
                              </button>
                              <button
                                onClick={() => handleBarcodeClick(product)}
                                className="text-purple-600 hover:text-purple-800"
                                title={product.barcode ? "Ver e imprimir código de barras" : "Generar código de barras"}
                              >
                                <BarcodeIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* No products message */}
            {!loading && products.length === 0 && (
              <div className="bg-white shadow rounded-lg p-8 text-center">
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="bg-gray-100 p-4 rounded-full">
                    <Search className="w-8 h-8 text-gray-400" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-900">No se encontraron productos</h3>
                  <p className="text-gray-500 max-w-md">
                    {searchTerm || filterCategory ?
                      'No hay productos que coincidan con los criterios de búsqueda. Intenta con otros términos o limpia los filtros.' :
                      'Aún no hay productos en el sistema. Haz clic en "Nuevo Producto" para añadir uno.'}
                  </p>
                  {(searchTerm || filterCategory) && (
                    <button
                      onClick={() => {
                        setSearchTerm('');
                        setFilterCategory('');
                      }}
                      className="mt-2 flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      <RefreshCw className="w-5 h-5" />
                      Limpiar filtros
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Loading indicator */}
            {loading && (
              <div className="bg-white shadow rounded-lg p-8 text-center">
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                  <p className="text-gray-500">Cargando productos...</p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        /* Add/Edit Product Form */
        <div className="bg-white rounded-lg p-3 sm:p-6 shadow-md">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
            <h2 className="text-base sm:text-xl font-semibold truncate">
              {selectedProduct ? 'Editar Producto' : 'Añadir Nuevo Producto'}
            </h2>
            <button
              onClick={() => {
                setShowAddForm(false);
                setSelectedProduct(null);
                setPreviewImage(null);
                setPriceList([]);
                setSpecifications([]);
                setAdditionalImages([]); // Limpiar las imágenes adicionales
                setSelectedCategory(''); // Limpiar la categoría seleccionada
              }}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
            >
              <ArrowLeft className="w-5 h-5" />
              Volver
            </button>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600 mb-6">
              {error}
            </div>
          )}
          
          {success && (
            <div className="bg-green-50 border border-green-200 rounded-lg p-4 text-green-600 flex justify-between items-center mb-6">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                {success}
              </div>
              <button 
                onClick={() => setSuccess(null)}
                className="text-green-600 hover:text-green-800"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          )}

          <form onSubmit={selectedProduct ? handleEditProduct : handleAddProduct} className="space-y-4 sm:space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                  Nombre del Producto
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  defaultValue={selectedProduct?.name}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Código del Producto
                </label>
                <input
                  type="text"
                  name="code"
                  defaultValue={selectedProduct?.code || ''}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Código de Barras
                </label>
                <input
                  type="text"
                  name="barcode"
                  defaultValue={selectedProduct?.barcode || ''}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Categoría
                </label>
                <select
                  name="category_id"
                  required
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Selecciona una categoría</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Stock
                  </label>
                  <input
                    type="number"
                    name="stock"
                    required
                    min="0"
                    defaultValue={selectedProduct?.stock}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Pedido Mínimo
                  </label>
                  <input
                    type="number"
                    name="min_order"
                    required
                    min="1"
                    defaultValue={selectedProduct?.min_order}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Imágenes del Producto
                </label>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {/* Imagen Principal */}
                  <div className="col-span-1 sm:aspect-square">
                    <div className="relative h-full">
                      {previewImage ? (
                        <>
                          <img
                            src={previewImage}
                            alt="Imagen principal"
                            className="w-full h-full object-cover rounded-lg"
                          />
                          <div className="absolute top-2 right-2 flex gap-1">
                            <button
                              type="button"
                              onClick={() => setPreviewImage(null)}
                              className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                            >
                              <X className="w-3 h-3 sm:w-4 sm:h-4" />
                            </button>
                            <div className="px-1.5 py-0.5 bg-blue-500 text-white text-[10px] sm:text-xs rounded-full">
                              Principal
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => fileInputRef.current?.click()}
                          className="w-full h-full border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-blue-500"
                        >
                          <div className="text-center p-4">
                            <Upload className="mx-auto h-8 w-8 text-gray-400" />
                            <span className="mt-2 block text-sm font-medium text-gray-600">
                              Imagen Principal
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Imágenes Adicionales */}
                  {additionalImages.map((img, index) => (
                    <div key={index} className="relative aspect-square">
                      <img
                        src={img.image_url}
                        alt={`Imagen adicional ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => setAdditionalImages(additionalImages.filter((_, i) => i !== index))}
                        className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}

                  {/* Botón para añadir más imágenes */}
                  <div
                    onClick={() => {
                      const input = document.createElement('input');
                      input.type = 'file';
                      input.accept = 'image/*';
                      input.multiple = true; // Permitir selección múltiple
                      input.onchange = async (e) => {
                        const files = Array.from((e.target as HTMLInputElement).files || []);
                        for (const file of files) {
                          if (!previewImage) {
                            // Si no hay imagen principal, usar la primera como principal
                            await handleImageUpload(file, true);
                          } else {
                            // Las demás serán imágenes adicionales
                            await handleImageUpload(file, false);
                          }
                        }
                      };
                      input.click();
                    }}
                    className="aspect-square border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-blue-500"
                  >
                    <div className="text-center p-4">
                      <Plus className="mx-auto h-8 w-8 text-gray-400" />
                      <span className="mt-2 block text-sm font-medium text-gray-600">
                        Añadir imagen
                      </span>
                      <span className="mt-1 block text-xs text-gray-500">
                        {!previewImage ? "Se usará como principal" : "Imagen adicional"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Contador de imágenes */}
                <div className="mt-2 flex items-center gap-4">
                  <p className="text-sm text-gray-600">
                    {previewImage ? "Imagen principal cargada" : "No hay imagen principal"}
                  </p>
                  {additionalImages.length > 0 && (
                    <p className="text-sm text-gray-600">
                      • {additionalImages.length} imagen{additionalImages.length !== 1 ? 'es' : ''} adicional{additionalImages.length !== 1 ? 'es' : ''}
                    </p>
                  )}
                </div>

                {/* Input oculto para la imagen principal */}
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleImageUpload(file, true);
                  }}
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Descripción
                </label>
                <textarea
                  name="description"
                  required
                  rows={3}
                  defaultValue={selectedProduct?.description}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>

              <div className="col-span-2 mt-6 border-t pt-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Información SEO
                </h3>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Palabras Clave (SEO)
                    </label>
                    <input
                      type="text"
                      name="meta_keywords"
                      defaultValue={selectedProduct?.meta_keywords || ''}
                      placeholder="palabra1, palabra2, palabra3"
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Separadas por comas
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-2 space-y-4 mt-6">
                <div className="flex items-center justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Especificaciones del Producto
                  </label>
                  <button
                    type="button"
                    onClick={handleAddSpecification}
                    className="flex items-center gap-2 px-3 py-1 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200"
                  >
                    <Plus className="w-4 h-4" />
                    Añadir Especificación
                  </button>
                </div>

                {specifications.map((spec, index) => (
                  <div key={index} className="flex flex-col sm:flex-row items-start gap-2 bg-gray-50 p-2 sm:p-3 rounded-lg">
                    <div className="flex-1">
                      <label className="block text-xs text-gray-600 mb-1">
                        Nombre
                      </label>
                      <input
                        type="text"
                        value={spec.name}
                        onChange={(e) => handleUpdateSpecification(index, 'name', e.target.value)}
                        placeholder="Marca, Modelo..."
                        className="w-full px-2 py-1.5 text-xs border rounded-lg"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="block text-xs text-gray-600 mb-1">
                        Valor
                      </label>
                      <input
                        type="text"
                        value={spec.value}
                        onChange={(e) => handleUpdateSpecification(index, 'value', e.target.value)}
                        placeholder="Samsung, XS-200..."
                        className="w-full px-2 py-1.5 text-xs border rounded-lg"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveSpecification(index)}
                      className="p-1.5 text-red-600 hover:text-red-700 self-end"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>

              <div className="col-span-2 space-y-6 mt-6">
                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Lista de Precios por Cantidad
                  </label>
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-1">
                    <button
                      type="button"
                      onClick={() => handleAddPriceLevel('contado')}
                      className="flex items-center justify-center gap-0.5 px-1 py-1 text-xs bg-green-100 text-green-600 rounded hover:bg-green-200"
                    >
                      <Plus className="w-3 h-3" />
                      <span className="truncate">Contado</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAddPriceLevel('credito')}
                      className="flex items-center justify-center gap-0.5 px-1 py-1 text-xs bg-blue-100 text-blue-600 rounded hover:bg-blue-200"
                    >
                      <Plus className="w-3 h-3" />
                      <span className="truncate">Crédito</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAddPriceLevel('mayorista')}
                      className="flex items-center justify-center gap-0.5 px-1 py-1 text-xs bg-purple-100 text-purple-600 rounded hover:bg-purple-200"
                    >
                      <Plus className="w-3 h-3" />
                      <span className="truncate">Mayor</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAddPriceLevel('publico')}
                      className="flex items-center justify-center gap-0.5 px-1 py-1 text-xs bg-red-100 text-red-600 rounded hover:bg-red-200"
                    >
                      <Plus className="w-3 h-3" />
                      <span className="truncate">Público</span>
                    </button>
                  </div>
                </div>

                {priceList.map((price, index) => (
                  <div key={index} className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 bg-gray-50 p-2 sm:p-3 rounded-lg">
                    <div className="w-full sm:w-28 mb-1 sm:mb-0">
                      <span className={`
                        inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium
                        ${price.price_type === 'contado'
                          ? 'bg-green-100 text-green-800'
                          : price.price_type === 'credito'
                            ? 'bg-blue-100 text-blue-800'
                            : price.price_type === 'mayorista'
                              ? 'bg-purple-100 text-purple-800'
                              : 'bg-red-100 text-red-800'}
                      `}>
                        {price.price_type === 'contado' ? 'Contado' : price.price_type === 'credito' ? 'Crédito' : price.price_type === 'mayorista' ? 'Mayorista' : 'Público'}
                      </span>
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm text-gray-600 mb-1">
                        Cantidad Mínima
                      </label>
                      {price.price_type === 'publico' ? (
                        <div className="flex items-center">
                          <input
                            type="number"
                            value={1}
                            disabled
                            className="w-full px-3 py-2 border rounded-lg bg-gray-100 text-gray-700"
                          />
                          <span className="ml-2 text-xs text-gray-500 hidden sm:inline">Fijo para precio público</span>
                        </div>
                      ) : (
                        <input
                          type="number"
                          min="1"
                          value={price.min_quantity}
                          onChange={(e) => handleUpdatePriceLevel(index, 'min_quantity', parseInt(e.target.value))}
                          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                      )}
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm text-gray-600 mb-1">
                        Precio
                      </label>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5">
                          Q
                        </span>
                        <input
                          type="number"
                          min="0"
                          step="0.01"
                          value={price.price}
                          onChange={(e) => handleUpdatePriceLevel(index, 'price', parseFloat(e.target.value))}
                          className="w-full pl-8 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemovePriceLevel(index)}
                      className="p-2 text-red-600 hover:text-red-700 self-center sm:self-end"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end gap-2 sm:gap-3 mt-4 sm:mt-6">
              <button
                type="button"
                onClick={() => {
                  setShowAddForm(false);
                  setSelectedProduct(null);
                  setPreviewImage(null);
                  setPriceList([]);
                  setSpecifications([]);
                  setAdditionalImages([]); // Limpiar las imágenes adicionales
                  setSelectedCategory(''); // Limpiar la categoría seleccionada
                }}
                className="px-3 sm:px-4 py-2 border rounded-lg hover:bg-gray-50 text-sm"
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={uploadingImage || !previewImage}
                className="px-3 sm:px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed text-sm"
              >
                {uploadingImage ? 'Subiendo...' : selectedProduct ? 'Guardar' : 'Añadir'}
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Modal de Código de Barras */}
      {showBarcodeModal && selectedProductForBarcode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 max-w-lg w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-base sm:text-lg font-medium truncate pr-2">
                Código de Barras - {selectedProductForBarcode.name}
              </h3>
              <button
                onClick={() => setShowBarcodeModal(false)}
                className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="mb-4">
              <BarcodeGenerator
                value={selectedProductForBarcode.barcode || ''}
                format="CODE128"
                height={100}
                width={2}
                fontSize={16}
                displayValue={true}
                margin={10}
                background="#ffffff"
                lineColor="#000000"
                onBarcodeGenerated={handleGenerateBarcode}
                canGenerate={!selectedProductForBarcode.barcode}
                productName={selectedProductForBarcode.name}
                publicPrice={selectedProductForBarcode.prices?.find(p => p.price_type === 'publico')?.price}
              />
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowBarcodeModal(false)}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}