import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface CartItem {
  id: string;
  cart_id: string;
  product_id: string;
  quantity: number;
  price_type?: 'contado' | 'credito' | 'mayorista' | 'publico';
  product: {
    name: string;
    base_price: number;
    prices?: {
      price_type: 'contado' | 'credito' | 'mayorista' | 'publico';
      min_quantity: number;
      price: number;
    }[];
    main_image: string;
    min_order: number;
  };
}

interface CartContextType {
  cartItems: CartItem[];
  loading: boolean;
  error: string | null;
  refreshCart: () => Promise<void>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCartItems = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setCartItems([]);
        return;
      }

      // Obtener o crear el carrito del usuario
      let { data: cart } = await supabase
        .from('cart')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (!cart) {
        const { data: newCart, error: createError } = await supabase
          .from('cart')
          .insert([{ user_id: user.id }])
          .select('id')
          .single();

        if (createError) throw createError;
        cart = newCart;
      }

      // Obtener los items del carrito
      const { data, error } = await supabase
        .from('cart_items')
        .select(`
          id,
          cart_id,
          product_id,
          quantity,
          price_type,
          product:products(
            id,
            name,
            base_price,
            main_image,
            min_order,
            prices:product_prices(price_type, min_quantity, price)
          )
        `)
        .eq('cart_id', cart.id);

      if (error) throw error;
      
      // Agregar depuración para ver los datos
      console.log('Datos del carrito sin procesar:', data);
      
      // Verificar que cada item tenga la propiedad product
      const validItems = data?.filter(item => item && item.product && typeof item.product === 'object') || [];
      
      if (validItems.length !== data?.length) {
        console.warn('Algunos items del carrito no tienen datos de producto válidos', 
          data?.filter(item => !item || !item.product || typeof item.product !== 'object'));
      }
      
      console.log('Datos del carrito filtrados:', validItems);
      
      // Usar type assertion para evitar errores de tipo
      setCartItems(validItems as unknown as CartItem[]);
    } catch (err) {
      console.error('Error fetching cart:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar el carrito');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartItems();

    // Suscribirse a cambios en cart_items
    const subscription = supabase
      .channel('cart_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'cart_items'
        }, 
        () => {
          fetchCartItems();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, loading, error, refreshCart: fetchCartItems }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCartContext() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartProvider');
  }
  return context;
} 