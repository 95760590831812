import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  originalPrice?: number;
  main_image: string;
  stock: number;
  discount?: number;
  rating?: number;
  sales?: number;
  price?: number;
  code?: string;
}

interface Collection {
  id: string;
  name: string;
  description: string;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  products: Product[];
}

export function useCollection(type: Collection['type']) {
  const [collection, setCollection] = useState<Collection | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true);
        
        // Primero obtenemos la colección para cualquier tipo
        const { data: collections, error: collectionError } = await supabase
          .from('product_collections')
          .select(`
            id,
            name,
            description,
            type,
            image
          `)
          .eq('is_active', true)
          .order('order', { ascending: true });

        if (collectionError) {
          console.error('Error fetching collections:', collectionError);
          throw collectionError;
        }

        // Filtrar manualmente la colección por tipo
        const collectionData = collections?.find(c => c.type === type);

        if (!collectionData && type !== 'new') {
          console.log(`No collection found for type: ${type}`);
          setCollection(null);
          return;
        }

        let products: Product[] = [];

        if (type === 'new') {
          // Para productos nuevos, obtenemos directamente los últimos productos agregados
          const { data: newProducts, error: productsError } = await supabase
            .from('products')
            .select(`
              id,
              name,
              description,
              base_price,
              main_image,
              stock,
              code
            `)
            .eq('status', 'active')
            .order('created_at', { ascending: false })
            .limit(10);

          if (productsError) {
            console.error('Error fetching new products:', productsError);
            throw productsError;
          }

          // Transformar los productos
          products = newProducts.map(product => ({
            id: product.id,
            name: product.name,
            description: product.description,
            base_price: product.base_price,
            main_image: product.main_image,
            stock: product.stock,
            originalPrice: product.base_price * 1.4,
            discount: Math.floor(Math.random() * 30) + 30,
            rating: Number((Math.random() * (5 - 4) + 4).toFixed(1)),
            sales: Math.floor(Math.random() * 5000) + 500,
            price: product.base_price,
            code: product.code,
          }));
        } else if (collectionData) {
          // Para otros tipos, primero obtenemos los IDs de los productos en la colección
          const { data: collectionProductIds, error: idsError } = await supabase
            .from('collection_products')
            .select('product_id')
            .eq('collection_id', collectionData.id);

          if (idsError) {
            console.error('Error fetching collection product IDs:', idsError);
            throw idsError;
          }

          if (collectionProductIds && collectionProductIds.length > 0) {
            // Extraer los IDs de productos
            const productIds = collectionProductIds.map(item => item.product_id);
            
            // Obtener los detalles de los productos
            const { data: productDetails, error: detailsError } = await supabase
              .from('products')
              .select(`
                id,
                name,
                description,
                base_price,
                main_image,
                stock,
                code
              `)
              .in('id', productIds)
              .eq('status', 'active');

            if (detailsError) {
              console.error('Error fetching product details:', detailsError);
              throw detailsError;
            }

            // Transformar los productos
            products = productDetails.map(product => ({
              id: product.id,
              name: product.name,
              description: product.description,
              base_price: product.base_price,
              main_image: product.main_image,
              stock: product.stock,
              originalPrice: product.base_price * 1.4,
              discount: Math.floor(Math.random() * 30) + 30,
              rating: Number((Math.random() * (5 - 4) + 4).toFixed(1)),
              sales: Math.floor(Math.random() * 5000) + 500,
              price: product.base_price,
              code: product.code,
            }));
          }
        }

        // Crear el objeto de colección
        setCollection({
          id: collectionData?.id || `${type}-default`,
          name: collectionData?.name || (type === 'new' ? 'Productos Nuevos' : 
                                        type === 'featured' ? 'Productos Destacados' : 
                                        type === 'sale' ? 'Ofertas Especiales' : 
                                        'Colección por Categoría'),
          description: collectionData?.description || 'Descubre nuestra selección especial de productos',
          type: type,
          products: products,
        });
      } catch (err) {
        console.error('Error in useCollection hook:', err);
        setError(err instanceof Error ? err.message : 'Error al cargar la colección');
      } finally {
        setLoading(false);
      }
    };

    // Configurar la suscripción en tiempo real para collection_products
    const collectionProductsSubscription = supabase
      .channel('collection_products_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'collection_products'
        }, 
        () => {
          fetchCollection();
        }
      )
      .subscribe();

    // Configurar la suscripción en tiempo real para product_collections
    const productCollectionsSubscription = supabase
      .channel('product_collections_changes')
      .on('postgres_changes', 
        {
          event: '*',
          schema: 'public',
          table: 'product_collections'
        }, 
        () => {
          fetchCollection();
        }
      )
      .subscribe();

    fetchCollection();

    // Limpiar las suscripciones cuando el componente se desmonte
    return () => {
      collectionProductsSubscription.unsubscribe();
      productCollectionsSubscription.unsubscribe();
    };
  }, [type]);

  return { collection, loading, error };
} 