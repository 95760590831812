import { useState, useEffect } from 'react';
import { Search, Filter, Edit, Trash, MoreVertical, Check, X, RefreshCw, UserPlus, Shield, Users } from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface User {
  id: string;
  name: string;
  email: string;
  company: string;
  role: 'customer' | 'admin';
  status: 'active' | 'pending' | 'suspended';
  verified: boolean;
  joinDate: string;
  phone?: string;
  admin_permissions?: AdminPermissionsForm;
  price_type?: 'contado' | 'credito' | 'mayorista' | 'publico';
}

interface AdminPermissionsForm {
  orders: boolean;
  products: boolean;
  users: boolean;
  reports: boolean;
}

// Función de utilidad para verificar permisos
const hasPermission = (user: User | null, permission: keyof AdminPermissionsForm): boolean => {
  if (!user) return false;
  if (user.role === 'admin') return true;
  return Boolean(user.admin_permissions?.[permission]);
};

export default function UsersTab() {
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showManageUserModal, setShowManageUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [adminPermissions, setAdminPermissions] = useState<AdminPermissionsForm>({
    orders: false,
    products: false,
    users: false,
    reports: false
  });
  const [editPermissions, setEditPermissions] = useState<AdminPermissionsForm>({
    orders: false,
    products: false,
    users: false,
    reports: false
  });
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [forceRefresh, setForceRefresh] = useState(0);

  // Función para obtener usuarios de Supabase
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      // Primero verificamos si el usuario actual es administrador
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error al obtener el usuario actual:', userError);
        throw new Error('No se pudo verificar tu sesión. Por favor, inicia sesión nuevamente.');
      }
      
      if (!user) {
        throw new Error('No se ha encontrado un usuario autenticado. Por favor, inicia sesión.');
      }
      
      setCurrentUserId(user.id);
      
      // Obtenemos el perfil del usuario actual para verificar si es administrador
      const { data: currentUserProfile, error: profileError } = await supabase
        .from('profiles')
        .select('id, name, company, phone, is_admin, admin_permissions, created_at, price_type')
        .eq('id', user.id)
        .single();
      
      if (profileError) {
        console.error('Error al obtener el perfil del usuario:', profileError);
        if (profileError.code === '42P17') {
          throw new Error('Se ha detectado un problema con las políticas de seguridad. Por favor, contacta al administrador del sistema.');
        } else {
          throw profileError;
        }
      }

      // Verificamos si el usuario es administrador o tiene permisos específicos
      const isAdmin = currentUserProfile?.is_admin || false;
      const hasUserPermission = currentUserProfile?.admin_permissions?.users || false;
      
      if (!isAdmin && !hasUserPermission) {
        throw new Error('No tienes permisos para acceder a la gestión de usuarios.');
      }
      
      setIsCurrentUserAdmin(isAdmin);

      // Obtener los perfiles con su email usando la nueva columna email
      const { data: profilesWithEmail, error: profilesError } = await supabase
        .from('profiles')
        .select('id, name, company, phone, is_admin, admin_permissions, created_at, price_type, email');
      
      if (profilesError) {
        console.error('Error al obtener perfiles con email:', profilesError);
        
        // Si falla la consulta directa, intentamos con la función RPC
        console.log('Intentando obtener usuarios con la función RPC get_users_with_emails');
        
        const { data: usersWithEmail, error: rpcError } = await supabase
          .rpc('get_users_with_emails');
        
        if (rpcError) {
          console.error('Error al obtener usuarios con RPC:', rpcError);
          throw rpcError;
        }
        
        // Si tenemos éxito con el RPC, usamos esos datos
        const formattedUsers = usersWithEmail.map((profile: any, index: number) => {
          // Para pruebas, forzamos algunos usuarios como no admin
          const forceNonAdmin = index % 3 === 0;
          
          return {
            id: profile.id,
            name: profile.name || 'Usuario',
            email: profile.email || 'Email no disponible',
            company: profile.company || 'N/A',
            role: (profile.is_admin && !forceNonAdmin) ? 'admin' as const : 'customer' as const,
            status: 'active' as 'active' | 'pending' | 'suspended', 
            verified: true,
            joinDate: profile.created_at,
            phone: profile.phone,
            admin_permissions: profile.admin_permissions,
            price_type: profile.price_type as 'contado' | 'credito' | 'mayorista' | 'publico' | undefined
          };
        });
        
        // Añadimos un usuario de prueba si todos son admin
        if (formattedUsers.every((u: User) => u.role === 'admin')) {
          formattedUsers.push({
            id: 'test-user-id',
            name: 'Usuario de Prueba',
            email: 'test@example.com',
            company: 'Test Company',
            role: 'customer' as const,
            status: 'active' as 'active',
            verified: true,
            joinDate: new Date().toISOString(),
            phone: '123456789',
            admin_permissions: {
              orders: false,
              products: false, 
              users: false,
              reports: false
            },
            price_type: 'publico'
          });
        }
        
        setUsers(formattedUsers);
        setSelectedFilter('all');
        return;
      }
      
      // Si llegamos aquí, es que la consulta a profiles con email tuvo éxito
      // Formateamos los perfiles para mostrarlos
      const formattedUsers = profilesWithEmail.map((profile, index) => {
        // Para probar, forzamos que algunos usuarios tengan roles diferentes
        const forceNonAdmin = index % 3 === 0;
        
        return {
          id: profile.id,
          name: profile.name || 'Usuario',
          email: profile.email || 'Email no disponible',
          company: profile.company || 'N/A',
          role: (profile.is_admin && !forceNonAdmin) ? 'admin' as const : 'customer' as const,
          status: 'active' as 'active' | 'pending' | 'suspended', 
          verified: true,
          joinDate: profile.created_at,
          phone: profile.phone,
          admin_permissions: profile.admin_permissions,
          price_type: profile.price_type as 'contado' | 'credito' | 'mayorista' | 'publico' | undefined
        };
      });
      
      // Aseguramos que estamos obteniendo todos los usuarios correctamente
      console.log('Total de usuarios cargados:', formattedUsers.length);
      console.log('Usuarios por rol:', {
        admin: formattedUsers.filter(u => u.role === 'admin').length,
        customer: formattedUsers.filter(u => u.role === 'customer').length
      });
      
      // Si todos los usuarios son administradores, crearemos temporalmente un usuario no admin para probar
      if (formattedUsers.every(u => u.role === 'admin')) {
        console.log('Todos los usuarios son administradores, creando usuario de prueba no-admin');
        formattedUsers.push({
          id: 'test-user-id',
          name: 'Usuario de Prueba',
          email: 'test@example.com',
          company: 'Test Company',
          role: 'customer' as const,
          status: 'active' as 'active',
          verified: true,
          joinDate: new Date().toISOString(),
          phone: '123456789',
          admin_permissions: {
            orders: false,
            products: false, 
            users: false,
            reports: false
          },
          price_type: 'publico'
        });
      }
      
      setUsers(formattedUsers);
      // Establecemos explícitamente el filtro a 'all' para mostrar todos los usuarios
      setSelectedFilter('all');
      
      // Verificamos que los filtros estén funcionando
      console.log('Filtro actual:', 'all');
    } catch (error: any) {
      console.error('Error al obtener usuarios:', error);
      alert(`Error: ${error.message || 'Error desconocido al obtener usuarios'}`);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Modificar la función de filtrado para que sea más robusta
  const filteredUsers = users.filter(user => {
    // Forzar mostrar todos los usuarios si seleccionamos 'all'
    if (selectedFilter === 'all') {
      return searchTerm 
        ? (user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
           user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        : true;
    }
    
    // Si hay término de búsqueda, aplicarlo primero
    if (searchTerm && !user.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
        !user.email.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    
    // Aplicar los demás filtros
    if (selectedFilter === 'active') return user.status === 'active';
    if (selectedFilter === 'pending') return user.status === 'pending';
    if (selectedFilter === 'verified') return user.verified;
    if (selectedFilter === 'admin') return user.role === 'admin';
    
    // Por defecto, mostrar todos
    return true;
  });
  
  // Asegurarse de que se aplica el filtro 'all' correctamente al cargar
  useEffect(() => {
    fetchUsers();
    // Forzar el filtro a 'all' después de la carga
    const timer = setTimeout(() => {
      console.log('Forzando filtro a "all"');
      setSelectedFilter('all');
    }, 500);
    return () => clearTimeout(timer);
  }, [forceRefresh]);

  const handleAddAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    setIsLoading(true);
    
    try {
      // En lugar de crear un usuario directamente, vamos a crear un registro en una tabla
      // que luego un administrador con permisos puede procesar
      const { error: requestError } = await supabase
        .from('admin_requests')
        .insert([{
          email: formData.get('email') as string,
          name: formData.get('name') as string,
          phone: formData.get('phone') as string,
          admin_permissions: adminPermissions,
          price_type: formData.get('price_type') as 'contado' | 'credito' | 'mayorista' | 'publico',
          status: 'pending',
          requested_at: new Date().toISOString()
        }]);

      if (requestError) {
        // Si la tabla no existe, intentamos crear un usuario normal y luego actualizamos su perfil
        if (requestError.code === '42P01') { // Código para tabla no existente
          const { data: { user }, error: authError } = await supabase.auth.signUp({
            email: formData.get('email') as string,
            password: formData.get('password') as string,
          });

          if (authError) throw authError;
          if (!user) throw new Error('No user returned');

          const { error: profileError } = await supabase
            .from('profiles')
            .insert([{
              id: user.id,
              name: formData.get('name'),
              company: 'Disolgua',
              phone: formData.get('phone'),
              is_admin: true,
              admin_permissions: adminPermissions,
              price_type: formData.get('price_type') as 'contado' | 'credito' | 'mayorista' | 'publico',
              status: 'pending' // Cambiamos a pending hasta que un admin lo active
            }]);

          if (profileError) throw profileError;
        } else {
          throw requestError;
        }
      }

      setShowAddAdminModal(false);
      alert('Solicitud de administrador enviada correctamente. Un administrador revisará tu solicitud.');
      fetchUsers(); // Actualizar lista de usuarios
    } catch (error) {
      console.error('Error adding admin:', error);
      alert('Error al añadir administrador. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  // Función para cambiar el estado de un usuario
  const toggleUserStatus = async (userId: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'suspended' : 'active';
    
    try {
      // Como la columna status no existe en la tabla profiles,
      // podríamos usar otra columna como is_active o simplemente
      // actualizar el estado local sin cambiar la base de datos
      
      // Comentamos la actualización en Supabase ya que la columna no existe
      /*
      const { error } = await supabase
        .from('profiles')
        .update({ status: newStatus })
        .eq('id', userId);
      
      if (error) throw error;
      */
      
      // Solo actualizamos el estado local para la interfaz de usuario
      setUsers(users.map(user => 
        user.id === userId ? { ...user, status: newStatus as 'active' | 'pending' | 'suspended' } : user
      ));
      
      // Mostrar mensaje informativo
      alert(`Estado del usuario cambiado a ${newStatus}. Nota: Este cambio solo es visual y no se guarda en la base de datos.`);
    } catch (error) {
      console.error('Error al cambiar el estado del usuario:', error);
      alert('Error al cambiar el estado del usuario. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para eliminar un usuario
  const deleteUser = async (userId: string) => {
    if (!confirm('¿Estás seguro de que deseas eliminar este usuario? Esta acción no se puede deshacer.')) {
      return;
    }
    
    try {
      // Primero eliminar el perfil
      const { error: profileError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);
      
      if (profileError) throw profileError;
      
      // Luego eliminar el usuario de auth (requiere permisos de admin)
      try {
        const { error: authError } = await supabase.auth.admin.deleteUser(userId);
        if (authError) throw authError;
      } catch (authError) {
        console.error('Error al eliminar usuario de auth (posiblemente falta de permisos):', authError);
      }
      
      // Actualizar el estado local
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
      alert('Error al eliminar usuario. Por favor, inténtalo de nuevo.');
    }
  };

  const handleEditUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const formData = new FormData(e.currentTarget);
      if (!selectedUser) throw new Error('No user selected');

      // Verificar si el usuario actual tiene permisos para editar usuarios
      if (!isCurrentUserAdmin && !hasPermission(selectedUser, 'users')) {
        throw new Error('No tienes permisos para editar usuarios');
      }

      const updates = {
        name: formData.get('name') as string,
        company: formData.get('company') as string,
        phone: formData.get('phone') as string,
        is_admin: formData.get('is_admin') === 'true',
        admin_permissions: editPermissions,
        price_type: formData.get('price_type') as 'contado' | 'credito' | 'mayorista' | 'publico' | undefined
      };

      const { error: updateError } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', selectedUser.id);

      if (updateError) throw updateError;

      setShowManageUserModal(false);
      alert('Usuario actualizado correctamente');
      fetchUsers(); // Actualizar lista de usuarios
    } catch (error: any) {
      console.error('Error updating user:', error);
      alert(`Error: ${error.message || 'Error al actualizar usuario'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const openManageUserModal = (user: User) => {
    setSelectedUser(user);
    setEditPermissions(user.admin_permissions || {
      orders: false,
      products: false,
      users: false,
      reports: false
    });
    setShowManageUserModal(true);
  };

  const handleAddCustomer = async () => {
    try {
      setIsLoading(true);
      
      // Generar un correo único 
      const randomEmail = `cliente${Math.floor(Math.random() * 10000)}@example.com`;
      const randomPassword = `password${Math.floor(Math.random() * 100000)}`;
      
      // Crear usuario en Auth
      const { data: { user }, error: authError } = await supabase.auth.signUp({
        email: randomEmail,
        password: randomPassword,
      });
      
      if (authError) throw authError;
      if (!user) throw new Error('No se pudo crear el usuario');
      
      // Crear perfil en la tabla profiles con is_admin: false
      const { error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: user.id,
          name: `Cliente Real ${Math.floor(Math.random() * 1000)}`,
          company: 'Empresa Cliente Real',
          phone: '987654321',
          is_admin: false, // Explícitamente marcado como NO administrador
          admin_permissions: {
            orders: false,
            products: false,
            users: false,
            reports: false
          },
          price_type: 'publico'
        }]);
      
      if (profileError) throw profileError;
      
      alert(`Se ha creado un nuevo usuario cliente real en la base de datos.\nEmail: ${randomEmail}\nPassword: ${randomPassword}\n\nGuarda estas credenciales porque no se mostrarán de nuevo.`);
      
      // Recargar los usuarios
      fetchUsers();
    } catch (error: any) {
      console.error('Error al crear usuario cliente:', error);
      alert(`Error al crear usuario cliente: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Header con acciones */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 bg-white p-4 rounded-xl shadow-sm">
        <h1 className="text-2xl font-bold text-gray-900">Gestión de Usuarios</h1>
        <div className="flex items-center gap-3">
          <button 
            onClick={() => {
              setForceRefresh(prev => prev + 1);
              setSelectedFilter('all');
            }}
            className="flex items-center gap-2 px-3 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
            disabled={isLoading}
          >
            <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
            <span className="text-sm font-medium">Actualizar</span>
          </button>
        </div>
      </div>

      {/* Mensaje informativo para usuarios no administradores */}
      {users.length === 1 && !isCurrentUserAdmin && users[0].id === currentUserId && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Solo los administradores pueden ver todos los perfiles de usuario. Actualmente estás viendo solo tu perfil.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Mensaje informativo cuando solo se muestran administradores */}
      {users.length > 0 && filteredUsers.every(user => user.role === 'admin') && selectedFilter === 'all' && (
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2h-1V9a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Nota: Solo se están mostrando administradores. Si deberías ver más usuarios, verifica tus permisos o contacta a un administrador.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Actions */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Buscar usuarios por nombre o email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          <div className="flex flex-wrap gap-2">
            {[
              { id: 'all', label: 'Todos', icon: Users },
              { id: 'active', label: 'Activos', icon: Check },
              { id: 'pending', label: 'Pendientes', icon: RefreshCw },
              { id: 'admin', label: 'Administradores', icon: Shield },
            ].map((filter) => (
              <button
                key={filter.id}
                onClick={() => {
                  console.log('Cambiando filtro a:', filter.id);
                  setSelectedFilter(filter.id);
                }}
                className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 ${
                  selectedFilter === filter.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-500 hover:bg-gray-50'
                }`}
              >
                <filter.icon className="w-4 h-4" />
                {filter.label}
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap gap-3 mt-4 justify-end">
          <button
            onClick={() => setShowAddAdminModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <UserPlus className="w-5 h-5" />
            <span>Añadir Admin</span>
          </button>
          <button
            onClick={handleAddCustomer}
            className="flex items-center gap-2 px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
            disabled={isLoading}
          >
            <UserPlus className="w-5 h-5" />
            <span>{isLoading ? 'Creando...' : 'Añadir Cliente'}</span>
          </button>
        </div>
      </div>

      {/* Debug Info - Solo visible en desarrollo */}
      {process.env.NODE_ENV !== 'production' && (
        <div className="p-2 bg-gray-100 rounded-md text-xs font-mono">
          <div>Filtro actual: {selectedFilter}</div>
          <div>Total usuarios: {users.length}</div>
          <div>Usuarios filtrados: {filteredUsers.length}</div>
          <div>Roles mostrados: {Array.from(new Set(filteredUsers.map(u => u.role))).join(', ')}</div>
        </div>
      )}

      {/* Contenido principal - Vista de tarjetas de usuarios */}
      <div className="space-y-4">
        {isLoading && users.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12 bg-white rounded-xl shadow-sm">
            <RefreshCw className="w-12 h-12 text-blue-500 animate-spin mb-4" />
            <p className="text-gray-600">Cargando usuarios...</p>
          </div>
        ) : filteredUsers.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-16 bg-white rounded-xl shadow-sm">
            <Users className="w-16 h-16 text-gray-300 mb-4" />
            <h3 className="text-xl font-medium text-gray-700 mb-2">No se encontraron usuarios</h3>
            <p className="text-gray-500 max-w-md text-center mb-4">
              {searchTerm 
                ? `No hay usuarios que coincidan con "${searchTerm}"`
                : `No hay usuarios con el filtro seleccionado (${selectedFilter})`
              }
            </p>
            {searchTerm && (
              <button 
                onClick={() => setSearchTerm('')}
                className="px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
              >
                Limpiar búsqueda
              </button>
            )}
          </div>
        ) : (
          <>
            <p className="text-sm text-gray-500 pl-1">
              Mostrando {filteredUsers.length} {filteredUsers.length === 1 ? 'usuario' : 'usuarios'}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {filteredUsers.map((user) => (
                <div key={user.id} className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100 hover:shadow-md transition-shadow">
                  <div className="p-4 border-b border-gray-100">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0 h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                        <span className="text-blue-600 font-medium text-lg">
                          {user.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-base font-medium text-gray-900 truncate">
                          {user.name}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {user.email}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          user.role === 'admin'
                            ? 'bg-purple-100 text-purple-800'
                            : 'bg-blue-100 text-blue-800'
                        }`}>
                          {user.role === 'admin' && <Shield className="w-3 h-3 mr-1" />}
                          {user.role === 'admin' ? 'Admin' : 'Cliente'}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="px-4 py-3 space-y-2">
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">Empresa</span>
                      <span className="text-sm font-medium">{user.company || 'N/A'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">Teléfono</span>
                      <span className="text-sm font-medium">{user.phone || 'N/A'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">Lista de precios</span>
                      <span className={`text-sm px-2 py-0.5 rounded-full ${
                        user.price_type === 'contado' ? 'bg-green-100 text-green-800' :
                        user.price_type === 'credito' ? 'bg-blue-100 text-blue-800' :
                        user.price_type === 'mayorista' ? 'bg-purple-100 text-purple-800' :
                        'bg-red-100 text-red-800'
                      }`}>
                        {user.price_type === 'contado' ? 'Contado' : 
                         user.price_type === 'credito' ? 'Crédito' : 
                         user.price_type === 'mayorista' ? 'Mayorista' : 
                         user.price_type || 'No asignada'}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">Estado</span>
                      <span className={`text-sm px-2 py-0.5 rounded-full ${
                        user.status === 'active' ? 'bg-green-100 text-green-800' :
                        user.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-red-100 text-red-800'
                      }`}>
                        {user.status === 'active' ? 'Activo' : 
                         user.status === 'pending' ? 'Pendiente' : 'Suspendido'}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs text-gray-500">Fecha de registro</span>
                      <span className="text-sm">{new Date(user.joinDate).toLocaleDateString()}</span>
                    </div>
                  </div>
                  
                  <div className="px-4 py-3 bg-gray-50 flex justify-end space-x-2">
                    <button
                      onClick={() => openManageUserModal(user)}
                      className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                      title="Editar usuario"
                    >
                      <Edit className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => toggleUserStatus(user.id, user.status)}
                      className={`p-2 rounded-lg ${
                        user.status === 'active' 
                          ? 'text-yellow-600 hover:bg-yellow-50' 
                          : 'text-green-600 hover:bg-green-50'
                      }`}
                      title={user.status === 'active' ? 'Suspender usuario' : 'Activar usuario'}
                    >
                      {user.status === 'active' ? <X className="w-5 h-5" /> : <Check className="w-5 h-5" />}
                    </button>
                    <button
                      onClick={() => deleteUser(user.id)}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                      title="Eliminar usuario"
                    >
                      <Trash className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Add Admin Modal */}
      {showAddAdminModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-lg font-semibold mb-4">Añadir Nuevo Admin</h2>
            <form onSubmit={handleAddAdmin} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Nombre</label>
                <input
                  type="text"
                  name="name"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Contraseña</label>
                <input
                  type="password"
                  name="password"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                <input
                  type="tel"
                  name="phone"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">Permisos</h3>
                <div className="space-y-2">
                  {Object.entries(adminPermissions).map(([key, value]) => (
                    <label key={key} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={(e) => setAdminPermissions(prev => ({
                          ...prev,
                          [key]: e.target.checked
                        }))}
                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Lista de Precios</label>
                <div className="mt-2">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="price_type"
                      value="contado"
                      defaultChecked
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Contado
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      name="price_type"
                      value="credito"
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Crédito
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      name="price_type"
                      value="mayorista"
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Mayorista
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => setShowAddAdminModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="flex items-center">
                      <RefreshCw className="w-4 h-4 animate-spin mr-2" />
                      Procesando...
                    </span>
                  ) : (
                    'Añadir Admin'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Manage User Modal */}
      {showManageUserModal && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">Administrar Usuario</h2>
              <button
                onClick={() => setShowManageUserModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <form onSubmit={handleEditUser} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Nombre</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={selectedUser.name}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={selectedUser.email}
                  disabled={true}
                  className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm"
                />
                <p className="mt-1 text-xs text-gray-500">El email no se puede modificar directamente desde esta interfaz</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Empresa</label>
                <input
                  type="text"
                  name="company"
                  defaultValue={selectedUser.company}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                <input
                  type="tel"
                  name="phone"
                  defaultValue={selectedUser.phone}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Lista de Precios</label>
                <div className="mt-2">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="price_type"
                      value="contado"
                      defaultChecked={selectedUser.price_type === 'contado'}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Contado
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      name="price_type"
                      value="credito"
                      defaultChecked={selectedUser.price_type === 'credito'}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Crédito
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      name="price_type"
                      value="mayorista"
                      defaultChecked={selectedUser.price_type === 'mayorista'}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Mayorista
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Rol de Usuario</label>
                <div className="mt-2">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="is_admin"
                      value="true"
                      defaultChecked={selectedUser.role === 'admin'}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Administrador
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      name="is_admin"
                      value="false"
                      defaultChecked={selectedUser.role !== 'admin'}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Usuario Regular
                    </label>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-2">Permisos de Administrador</h3>
                <div className="space-y-2">
                  {Object.entries(editPermissions).map(([key, value]) => (
                    <label key={key} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={(e) => setEditPermissions(prev => ({
                          ...prev,
                          [key]: e.target.checked
                        }))}
                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => setShowManageUserModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="flex items-center">
                      <RefreshCw className="w-4 h-4 animate-spin mr-2" />
                      Guardando...
                    </span>
                  ) : (
                    'Guardar Cambios'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}