import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter, Mail, Phone, MapPin, Send, ArrowRight, Linkedin, Youtube } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';   
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Componente para el carrusel de marcas
const BrandCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  // Lista de marcas con las que trabaja Disolgua
  const brands = [
    { name: "Fiesta Toys", logo: "/assets/brands/FiestaToys.png" },
    { name: "Exprecol", logo: "/assets/brands/exprecol.png" },
  ];

  // Número de marcas a mostrar a la vez según el tamaño de la pantalla
  const itemsToShow = {
    sm: 1,
    md: 2,
    lg: 2
  };

  // Efecto para la rotación automática - desactivado cuando hay pocas marcas
  useEffect(() => {
    // Solo activar rotación automática si hay más marcas que las que se muestran
    if (brands.length <= itemsToShow.lg) return;
    
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % (Math.max(1, brands.length - itemsToShow.lg + 1))
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [brands.length]);

  const handlePrev = () => {
    if (brands.length <= itemsToShow.lg) return; // No hacer nada si todas las marcas ya están visibles
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? Math.max(0, brands.length - itemsToShow.lg) : prevIndex - 1
    );
  };

  const handleNext = () => {
    if (brands.length <= itemsToShow.lg) return; // No hacer nada si todas las marcas ya están visibles
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % (Math.max(1, brands.length - itemsToShow.lg + 1))
    );
  };

  // Si hay pocas marcas, centrarlas
  const shouldShowControls = brands.length > itemsToShow.lg;

  return (
    <div className="py-12 bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <h3 className="text-2xl font-bold text-center text-gray-900 mb-8">Nuestras Marcas</h3>
        
        <div className="relative">
          {/* Botón Anterior - solo mostrar si hay suficientes marcas */}
          {shouldShowControls && (
            <button 
              onClick={handlePrev}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white text-blue-600 p-2 rounded-full shadow-md transition-all duration-300"
              aria-label="Marca anterior"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
          )}
          
          {/* Carrusel */}
          <div className="overflow-hidden px-10">
            <div 
              className={`flex transition-transform duration-500 ease-in-out ${!shouldShowControls ? 'justify-center' : ''}`}
              style={shouldShowControls ? { transform: `translateX(-${currentIndex * (100 / itemsToShow.lg)}%)` } : {}}
            >
              {brands.map((brand, index) => (
                <div 
                  key={index} 
                  className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-8"
                >
                  <div className="p-6 flex items-center justify-center h-48">
                    <img 
                      src={brand.logo} 
                      alt={`Logo de ${brand.name}`} 
                      className="max-h-40 max-w-full object-contain transition-all duration-300"
                    />
                  </div>
                  <p className="text-center mt-2 text-gray-600 font-medium text-lg">{brand.name}</p>
                </div>
              ))}
            </div>
          </div>
          
          {/* Botón Siguiente - solo mostrar si hay suficientes marcas */}
          {shouldShowControls && (
            <button 
              onClick={handleNext}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white text-blue-600 p-2 rounded-full shadow-md transition-all duration-300"
              aria-label="Marca siguiente"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          )}
        </div>
        
        {/* Indicadores - solo mostrar si hay suficientes marcas */}
        {shouldShowControls && (
          <div className="flex justify-center mt-8">
            {Array.from({ length: Math.max(1, brands.length - itemsToShow.lg + 1) }).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2.5 h-2.5 mx-1 rounded-full transition-all duration-300 ${
                  currentIndex === index 
                    ? 'bg-blue-600 scale-125' 
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                aria-label={`Ir a grupo de marcas ${index + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function Footer() {
  const [email, setEmail] = useState('');
  
  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    // Aquí iría la lógica para suscribir al newsletter
    alert(`Gracias por suscribirte con: ${email}`);
    setEmail('');
  };

  return (
    <>
      <BrandCarousel />
      <footer className="bg-gradient-to-b from-white to-gray-50 border-t">
        <div className="max-w-7xl mx-auto px-4 py-16">
          {/* Newsletter Subscription */}
          <div className="mb-16 p-8 bg-blue-50 rounded-xl shadow-sm">
            <div className="max-w-3xl mx-auto text-center">
              <h3 className="text-2xl font-bold text-gray-900 mb-3">Suscríbete a nuestro newsletter</h3>
              <p className="text-gray-600 mb-6">Recibe ofertas exclusivas, novedades y consejos directamente en tu correo.</p>
              <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Tu correo electrónico"
                  className="flex-1 px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <button 
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg transition-colors duration-300 flex items-center justify-center gap-2"
                >
                  Suscribirse
                  <Send className="w-4 h-4" />
                </button>
              </form>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {/* Logo y Descripción */}
            <div className="space-y-5">
              <Link to="/" className="block">
                <img 
                  src="/assets/DISOLGUA.png"
                  alt="Disolgua"
                  className="h-14 w-auto"
                />
              </Link>
              <span className="text-blue-600 font-bold text-sm block mb-2">
              IMPORTACION, DISTRIBUCION Y VENTA DE MERCADERIA EN GENERAL.
              </span>
              <p className="text-gray-600 ">
                Tu distribuidor de confianza en Guatemala. Ofrecemos productos de calidad al por mayor con los mejores precios del mercado.
              </p>
              
              <div className="flex items-center gap-4">
                <a href="https://www.facebook.com/disolgua" className="text-gray-400 hover:text-blue-600 transition-colors duration-300 transform hover:scale-110">
                  <Facebook className="w-5 h-5" />
                </a>
              <a href="https://wa.me/50247391242" className="text-gray-400 hover:text-blue-600 transition-colors duration-300 transform hover:scale-110">
                <span>
                  <Phone className="w-5 h-5" />
                </span>
              </a>
              </div>
            </div>

            {/* Enlaces Rápidos */}
            <div>
              <h3 className="font-bold text-gray-900 mb-5 text-lg">Enlaces Rápidos</h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/catalog" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Catálogo</span>
                  </Link>
                </li>
                <li>
                  <Link to="/categories" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Categorías</span>
                  </Link>
                </li>
                
            
                <li>
                  <Link to="/register" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Crear Cuenta</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Sobre Nosotros</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* Información Legal */}
            <div>
              <h3 className="font-bold text-gray-900 mb-5 text-lg">Información Legal</h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/terms" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Términos y Condiciones</span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="text-gray-600 hover:text-blue-600 transition-colors flex items-center gap-2 group">
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <span>Política de Privacidad</span>
                  </Link>
                </li>              
               
              </ul>
            </div>

            {/* Contacto */}
            <div>
              <h3 className="font-bold text-gray-900 mb-5 text-lg">Contacto</h3>
              <ul className="space-y-4">
                <li className="flex items-center gap-3 text-gray-600 hover:text-blue-600 transition-colors">
                  <div className="bg-blue-50 p-2 rounded-full">
                    <Phone className="w-5 h-5 text-blue-600" />
                  </div>
                  <span>(502) 2238-0714</span>
                </li>
                <li className="flex items-center gap-3 text-gray-600 hover:text-blue-600 transition-colors">
                  <div className="bg-blue-50 p-2 rounded-full">
                    <FontAwesomeIcon icon={faWhatsapp} className="w-5 h-5 text-blue-600" />
                  </div>            
                  <span>(502) 4739-1242</span>
                </li>                                       
                <li className="flex items-center gap-3 text-gray-600 hover:text-blue-600 transition-colors">
                  <div className="bg-blue-50 p-2 rounded-full">
                    <Mail className="w-5 h-5 text-blue-600" />
                  </div>
                  <span>info@disolgua.com</span>
                </li>
                <li className="flex items-center gap-3 text-gray-600 hover:text-blue-600 transition-colors">
                  <div className="bg-blue-50 p-2 rounded-full">
                    <Mail className="w-5 h-5 text-blue-600" />
                  </div>
                  <span>ventas@disolgua.com</span>
                </li>
                <li className="flex items-start gap-3 text-gray-600">
                  <div className="bg-blue-50 p-2 rounded-full mt-1">
                    <MapPin className="w-5 h-5 text-blue-600" />
                  </div>
                  <span>19 calle 0-47, Zona 1, Guatemala, Guatemala</span>
                </li>
                <div className="mt-5 w-full aspect-video rounded-lg overflow-hidden shadow-sm">
                  <iframe
                    title="Ubicación de Disolgua"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.4339747763984!2d-90.5247976605387!3d14.63128967638358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a2216a6a3a47%3A0xea5d34810cb184e4!2sDisolgua!5e0!3m2!1ses!2sgt!4v1740079890742!5m2!1ses!2sgt"
                    style={{ border: 0 }}
                    className="w-full h-full"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                  />
                </div>
              </ul>
            </div>
          </div>

          <div className="border-t mt-16 pt-8">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <p className="text-gray-500 text-sm">
                © {new Date().getFullYear()} Distribuidora del Sol Guatemala S.A. Todos los derechos reservados.
              </p>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}